import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ChevronDown } from 'react-feather';
import Sidebar from "./Sidebar";
import Header from './Header';

const Help = (props) => {
    return (
        <>
            <Sidebar props={props} />
            <div className={`view-wrapper is-sidebar-v1 ${props.sidebar ? 'is-fold' : ''}`}>
                <Header navigate={props.history} />
                <div className="dashboard-container" style={{ marginTop: "30px" }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p>How does it work?</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>
                                Sign up for an account for free. You can sign up HERE as a User/Fan, or HERE if you want to be an Artist/Producer and create/share original content.<br /><br />
                                If you signed on as User/Fan, you may explore the Main Page HERE where free content are posted by Artist/Producer in our community. If you see something that you like, you may tip the Artist/Producer. If you would like to see more of their content, you may subscribe to their channel to see the Exclusive content.

                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <p>How do I earn money as an Artist/Producer?</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>
                                For viewing your work, Users/Fans pays you directly either through tips or subscribing to your channel.<br /><br />
                                All your earnings are totaled and are distributed to you on 1st and 15th of every month.<br /><br />
                                We will retain 15% of your earnings as the cost to using our platform.<br /><br />
                                You can utilize everything we have to promote your brand and maximize your earnings. There are not tiers, no hidden costs. For every dollar you earn, we keep 15 cents. That’s it.

                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <p>Who is welcomed on this Platform?</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>
                                Anyone who has original content of any genre is welcome to use this platform. We won’t censor you and your creation. If it’s legal, does not promote violence, self-harm or suicide and your fans want to see it, you can post it on this platform.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </>
    );
}
export default Help;