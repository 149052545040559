import React from "react";
import { Link } from "react-router-dom";

import InstagramIcon from "../../assets/images/social/instagram.svg";
import TiktokIcon from "../../assets/images/social/tiktok.svg";
import TwitterIcon from "../../assets/images/social/twitter.svg";
import LogoFooter from "../../assets/images/logo-footer.svg";

const Footer = (props) => (
  <footer>
    <div className="tb-footer tb-footer--top bg-dark">
      <div className="container">
        <div className="tb-footer__container">
          <div className="tb-footer__space">
            <div className="tb-footer__header">SOCIAL</div>
            <div className="tb-footer__socials">
              <a href="https://www.instagram.com">
                <img
                  src={InstagramIcon}
                  alt="Instagram"
                  className="tb-footer__social"
                />
              </a>
              <a href="https://www.tiktok.com">
                <img
                  src={TiktokIcon}
                  alt="Tik-Tok"
                  className="tb-footer__social"
                />
              </a>
              <a href="https://www.twitter.com">
                <img
                  src={TwitterIcon}
                  alt="Twitter"
                  className="tb-footer__social"
                />
              </a>
            </div>
          </div>
          <div>
            <a href="/">
              <img src={LogoFooter} alt="Tribe of Babes" />
            </a>
          </div>
        </div>
        <div className="tb-footer__container footer__container--bottom">
          <div className="tb-footer__space">
            <div className="tb-footer__header">CONTACT</div>
            <div className="tb-footer__text">
              Cinema Studio, LLC | support@tribeofbabes.com
              <br />
              5505 E Santa Ana Canyon Road # 17722
              <br />
              Anaheim, CA 92817 US
            </div>
          </div>
          <div>
            <div className="tb-footer__header">RESOURCES</div>
            <div className="tb-footer__text">
              <p>
                if you encounter any issue about payment or cancellation of
                recurring services
                <br />
                Consumer Support :{" "}
                <a href="mailto:support@ccbill.com">support@ccbill.com</a> |
                888.596.92.79 |{" "}
                <a
                  href="https://support.ccbill.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.ccbill.com
                </a>
              </p>
              <p>
                Cancellation Policy: If you would like to cancel your purchase.
                <br />
                Please contact CCBILL CONSUMER SUPPORT DEPARTMENT at{" "}
                <a
                  href="https://support.ccbill.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.ccbill.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="tb-footer tb-footer--bottom">
      <div className="container tb-footer--middle">
        18 U.S.C. 2257 and 2257A Record-Keeping Requirements Compliance
        Statement
        <br />
        In compliance with U.S.C. Title 18, Sections 2257 and 2257A and related
        regulations, all performers, models, actors, actresses and other persons
        who appear in any visual depiction of actual or simulated sexually
        explicit conduct appearing on or otherwise contained on this website are
        or were 18 years of age or older at the time of the creation of such
        depictions. Records required to be maintained pursuant to U.S.C. Title
        18, Sections 2257 and 2257A are located at and maintained by: Please
        direct questions pertaining to content on this website to
        https://support.ccbill.com
      </div>
      <div className="container tb-footer--bottom">
        <div className="tb-footer__container">
          <div className="tb-footer__copyright">
            Copyright © Tribe of Babes 2022 All Rights Reserved.
          </div>
          <ul className="tb-footer__links">
            <li>
              <Link to="/privacy_policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms_and_condition">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/contact_us">Contact Us</Link>
            </li>
            <li>
              <Link to="/Appeals_form">Appeals Form</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
