import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import toast from "react-hot-toast";

const queryString = require("query-string");

const Payments = (props) => {
  React.useEffect(() => {
    if (props.token) {
      const parsed = queryString.parse(props.location.search);
      var data = new FormData();
      data.append("amount", parsed.initialPrice);
      data.append("responseDigest", parsed.formDigest);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/add/amount",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          toast.success(response.data.response.message);
          props.history.push("/wallet");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [props.token]);
  return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    token: state.StreamApp.token,
  };
};

export default connect(mapStateToProps)(Payments);
