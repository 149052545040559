import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const SignupLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="tb-signup-layout">
        <div className="container">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default SignupLayout;
