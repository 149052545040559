import React from "react";
import { Link } from "react-router-dom";

import * as yup from "yup";
import { Formik, Form } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import ContactUsLayout from "./Layout/ContactUsLayout";
import BigInput from "../Elements/BigInput";
import BigTextarea from "../Elements/BigTextarea";
import Button from "../Elements/Button";

const signUp = yup.object().shape({
  email: yup.string().email().required("email required!"),
  name: yup.string().required(),
  messages: yup.string().required(),
});

const ContacUs = () => {
  const ctnForm = async (values, setSubmitting) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("message", values.messages);

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/contact-us",
      data: data,
    };

    const response = await axios(config);
    toast.success(response.data.response.message);
    setSubmitting(false);
  };

  return (
    <ContactUsLayout>
      <h1>Contact Us</h1>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          ctnForm(values, setSubmitting);
        }}
        initialValues={{ email: "", name: "", messages: "" }}
        validationSchema={signUp}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form className="tb-contact-us-layout__form">
            <div className="tb-signup-layout__form-row">
              <BigInput
                name="name"
                placeholder="Name"
                autocomplete="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name}
                description={errors.name && touched.name ? errors.name : ""}
              />
            </div>

            <div className="tb-signup-layout__form-row">
              <BigInput
                name="email"
                placeholder="Email"
                autocomplete="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                description={errors.email && touched.email ? errors.email : ""}
              />
            </div>

            <div className="tb-signup-layout__form-row">
              <BigTextarea
                name="messages"
                placeholder="Enter your message"
                autocomplete="messages"
                value={values.messages}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.messages && touched.messages}
                description={
                  errors.messages && touched.messages ? errors.messages : ""
                }
                style={{ height: "200px", resize: "none" }}
              />
            </div>

            <div className="tb-signin-layout__button">
              <Button disabled={isSubmitting} color="secondary">
                {isSubmitting ? (
                  <CircularProgress size="1rem" style={{ color: "#fff" }} />
                ) : (
                  "Send Message"
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <div className="tb-contact-us-layout__links">
        <Link to="/forgot_password">Forgot username or password?</Link>
        <Link to="/signup">Create Account</Link>
      </div>
    </ContactUsLayout>
  );
};
export default ContacUs;
