import React from "react";
import { X, Bookmark, Bell, Flag, Play } from "react-feather";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import Slider from "react-slick";
import moment from "moment";
import Live from "../images/stream-join.jpg";
import Record from "../images/stream-record.jpg";

const tip = yup.object().shape({
  tip: yup.number().required(),
});
const Posts = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const tipModal = () => {
    setOpen(!open);
  };
  const tipHandler = (values) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      toast.success(`${values.tip} Tokens Tipped`);
    }, 2000);
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const postHandler = React.useCallback((post) => {
    if (post.postType === "Live") {
      props.navigate.push(`/stream/${post.urlCode}`);
    } else {
      props.navigate.push(`/post/${post.urlCode}`);
    }
  }, []);

  const posts = [
    ...props.posts.data
      .filter((filter) => filter.postType !== "Archived")
      .sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      }),
    ...props.posts.otherData
      .filter((filter) => filter.postType !== "Archived")
      .sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      }),
  ];

  return (
    <div className="customGrid">
      {posts.map((i, k) => {
        return (
          <div
            className="gridColumn"
            key={k}
            onClick={() => postHandler(i)}
            style={{ cursor: "pointer" }}
          >
            <div className={`card post-feed-item`}>
              <div className="card-image">
                <div className="post-image">
                  {i.postType !== "Live" && i.postType !== "CloudRecording" ? (
                    <Slider {...settings}>
                      {i.postMedias.map((media, mediaKey) => {
                        return (
                          <React.Fragment key={mediaKey}>
                            {media.type === "image" ? (
                              <div className="post-media">
                                <img src={media.url} />
                              </div>
                            ) : (
                              <div className="post-media">
                                <video muted style={{ width: "300px" }}>
                                  <source src={`${media.url}#t=15`} />
                                </video>
                                <Play
                                  style={{
                                    color: "#ca1444",
                                    position: "absolute",
                                  }}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Slider>
                  ) : i.postType === "Live" ? (
                    <img src={Live} />
                  ) : i.postType === "CloudRecording" ? (
                    <img src={Record} />
                  ) : null}
                </div>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <div className="post-avatar is-smaller">
                      <img className="avatar" src={i.user_profile} />
                    </div>
                  </div>
                  <div className="media-content is-smaller">
                    <span>{i.nickName}</span>
                    <span>{moment(i.created_at).fromNow()}</span>
                    <span style={{ marginTop: "5px" }}>{i.detail}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div
        className={`modal is-xsmall has-light-bg share-modal ${
          open ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <h3>Tip</h3>
                <div className="close-wrap">
                  <span className="close-modal" onClick={() => tipModal()}>
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ tip: "" }}
                  validationSchema={tip}
                  onSubmit={(values, { resetForm }) => {
                    tipHandler(values);
                    resetForm();
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <Form>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="number"
                            placeholder="10"
                            name="tip"
                            id="tip"
                            value={values.tip}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.tip && touched.tip && errors.tip ? (
                            <div className="dangerText">{errors.tip}</div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        className="button is-solid primary-button raised is-rounded is-fullwidth"
                        disabled={loading}
                        type="submit"
                      >
                        {loading ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Posts;
