import React from "react";
import { User, Lock } from "react-feather";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, Form } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import SigninLayout from "./Layout/SigninLayout";
import BigInput from "../Elements/BigInput";
import Button from "../Elements/Button";

const loginIn = yup.object().shape({
  email: yup.string().email().required("email required!"),
  password: yup.string().required("password required!"),
});
const SignIn = (props) => {
  const [loading, setLoading] = React.useState(false);

  const userProfile = async (token) => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
        props.history.push("/home");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const signInHandler = async (values) => {
    setLoading(true);
    try {
      var data = JSON.stringify({
        email: values.email,
        password: values.password,
      });

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/signin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      };

      const Response = await axios(config);
      setLoading(false);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem(
        "createdAt",
        Response.data.response.data.auth.createdAt
      );
      props.tokenHandler(Response.data.response.data.auth.jwt);
      await userProfile(Response.data.response.data.auth.jwt);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };

  return (
    <SigninLayout>
      <h1>Account Login</h1>

      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginIn}
        onSubmit={(values) => {
          signInHandler(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="tb-signin-layout__form">
            <div className="tb-signin-layout__form-row">
              <BigInput
                name="email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                description={errors.email && touched.email ? errors.email : ""}
              />
            </div>

            <div className="tb-signin-layout__form-row">
              <BigInput
                name="password"
                type="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                description={
                  errors.password && touched.password ? errors.password : ""
                }
              />
            </div>

            <div className="tb-signin-layout__button">
              <Button disabled={loading} color="secondary">
                {loading ? (
                  <CircularProgress size="1rem" style={{ color: "#fff" }} />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <div className="tb-signin-layout__links">
        <Link to="/signup">Don’t have an account? Sign Up</Link>
        <Link to="/forgot_password">Forgot Password</Link>
      </div>
    </SigninLayout>
  );
};
const mapStateToProps = (state) => {
  return {
    dark: state.StreamApp.dark,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userHandler: (value) => dispatch({ type: "USER", value: value }),
    tokenHandler: (value) => dispatch({ type: "TOKEN", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
