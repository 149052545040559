import "./App.css";
import "./Custom.css";
import SignIn from "./Components/SignIn";
import ForgotPassword from "./Components/ForgotPassword";
import SignUp from "./Components/SignUp";
import Home from "./Components/Home";
import Profile from "./Components/Profile";
import Dashboard from "./Components/Dashboard";
import Settings from "./Components/Settings";
import Transanctions from "./Components/Transanctions";
import Subscribers from "./Components/Subscribers";
import Streams from "./Components/Streams";
import Wallet from "./Components/Wallet";
import Followings from "./Components/Followings";
import Followers from "./Components/Followers";
import Subscriptions from "./Components/Subscriptions";
import PostSingle from "./Components/PostSingle";
import PostSingleEdit from "./Components/PostSingleEdit";
import { Switch, Route, useHistory } from "react-router-dom";
import ProtectedRoute from "./Routes";
import UserProfile from "./Components/UserProfile";
import Chat from "./Components/Chat";
import Search from "./Components/Search";
import LiveStream from "./Components/LiveStream";
import Help from "./Components/Help";
import WatchStreams from "./Components/WatchStream";
import Landing from "./Components/Landing";
import TermsAndCondition from "./Components/TermsAndCondition";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Welcome from "./Components/Suggestion";
import ContacUs from "./Components/ContactUs";
import Consent from "./Components/Consent";
import AppealsForm from "./Components/AppealsForm";
import Payments from "./Components/Payments";
import React from "react";
import ReactGA from "react-ga";
const TRACKING_ID = "G-P7SMVF0TPE";

ReactGA.initialize(TRACKING_ID);

const Routes = () => {
  const history = useHistory();

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const unlisten = history.listen((location) => {
      window.scrollTo(0, 0);
      ReactGA.pageview(location.pathname);
    });

    return () => unlisten();
  }, [history]);

  return (
    <Switch>
      <Route exact path="/" render={(props) => <Landing {...props} />} />
      <Route exact path="/login" render={(props) => <SignIn {...props} />} />
      <Route exact path="/signup" render={(props) => <SignUp {...props} />} />
      <Route
        exact
        path="/forgot_password"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route exact path="/terms_and_condition" component={TermsAndCondition} />
      <Route exact path="/privacy_policy" component={PrivacyPolicy} />
      <Route exact path="/contact_us" component={ContacUs} />
      <ProtectedRoute exact path="/home" component={Home} />
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path="/dashboard" component={Dashboard} />
      <ProtectedRoute exact path="/settings" component={Settings} />
      <ProtectedRoute exact path="/transanctions" component={Transanctions} />
      <ProtectedRoute exact path="/followers" component={Followers} />
      <ProtectedRoute exact path="/followings" component={Followings} />
      <ProtectedRoute exact path="/Subscribers" component={Subscribers} />
      <ProtectedRoute exact path="/subscriptions" component={Subscriptions} />
      <ProtectedRoute exact path="/streams" component={Streams} />
      <ProtectedRoute exact path="/wallet" component={Wallet} />
      <ProtectedRoute exact path="/post/:id" component={PostSingle} />
      <ProtectedRoute exact path="/post/:id/edit" component={PostSingleEdit} />
      <ProtectedRoute exact path="/user/:id" component={UserProfile} />
      <ProtectedRoute exact path="/chat" component={Chat} />
      <ProtectedRoute exact path="/search" component={Search} />
      <ProtectedRoute exact path="/live" component={LiveStream} />
      <ProtectedRoute exact path="/help" component={Help} />
      <ProtectedRoute exact path="/stream/:id" component={WatchStreams} />
      <ProtectedRoute exact path="/welcome" component={Welcome} />
      <ProtectedRoute exact path="/consent" component={Consent} />
      <ProtectedRoute exact path="/payments" component={Payments} />
      <Route exact path="/appeals_form" component={AppealsForm} />
    </Switch>
  );
};

export default Routes;
