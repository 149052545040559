import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { X } from "react-feather";
import toast from "react-hot-toast";

const Sugguestion = (props) => {
  const [categories, setCategories] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [reportOpen, setReportOpen] = React.useState(false);

  const getCategories = React.useCallback(() => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/categories",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config).then(function (response) {
      const data = response.data.response.data.filter(
        (f) => f.name !== "Uncategorized"
      );
      setCategories(data);
    });
  }, [setCategories]);

  React.useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getCategories();
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  const selectedCategory = React.useCallback(
    (cat, color) => {
      const data = { cat, color: color };
      setSelected((selected) => [...selected, data]);
    },
    [setSelected, selected]
  );

  const catRender = React.useMemo(() => {
    return categories
      .filter((filter) => !selected.find(({ cat }) => filter.id === cat.id))
      .map((cat, index) => {
        let color = random_bg_color();
        return (
          <div key={index} className="column is-4">
            <div
              style={{
                backgroundColor: color,
                height: "200px",
                padding: "20px",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={() => selectedCategory(cat, color)}
            >
              <b>{cat.name}</b>
            </div>
          </div>
        );
      });
  }, [categories, selected]);

  const selectedRender = React.useMemo(() => {
    return selected.map((selected, index) => {
      return (
        <div
          key={index}
          style={{ backgroundColor: selected.color }}
          onClick={() => removeSelected(selected.cat.name)}
        >
          {selected.cat.name}
        </div>
      );
    });
  }, [selected]);

  const removeSelected = React.useCallback(
    (name) => {
      const removed = selected.filter((category) => category.cat.name !== name);
      setSelected(removed);
    },
    [selected, setSelected]
  );

  function random_bg_color() {
    var x = Math.floor(Math.random() * 256);
    var y = Math.floor(Math.random() * 256);
    var z = Math.floor(Math.random() * 256);
    var bgColor = "rgb(" + x + "," + y + "," + z + ")";

    return bgColor;
  }

  const getCreator = React.useCallback(() => {
    const selectedData = selected.map((i) => i.cat.id);
    var data = JSON.stringify({
      categoryId: selectedData,
      limit: 2,
    });

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/top-free-creator",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setUsers(response.data.response.data);
        setReportOpen(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [selected, props.token]);

  const subscribeHandler = React.useCallback((id) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("subscribeUserId", id);

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/subscribe/user",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success(response.data.response.message);
        props.history.push("/home");
      })
      .catch(function (error) {
        console.log(error);
      });
  });

  return (
    <div>
      <div className="suggestionHeader">
        {selectedRender}
        <b
          onClick={() => props.history.push("/home")}
          style={{ cursor: "pointer" }}
        >
          Skip
        </b>
      </div>
      <div className="columns is-multiline suggestionBody">
        <div className="column is-6">
          <div className="SuggestionMain">
            <div style={{ textAlign: "center" }}>
              <h1 style={{ color: "#fff" }}>What're you into?</h1>
              <h2 style={{ color: "#fff" }}>
                Tell us what you like and we'll the good stuff.
              </h2>
            </div>
            <div className="btns">
              <div>
                <button
                  role="button"
                  onClick={() => props.history.push("/search")}
                >
                  Search Creator
                </button>
              </div>
              <div>
                <button
                  role="btn"
                  onClick={() => getCreator()}
                  disabled={!selected.length}
                >
                  Submit Preference
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="columns is-multiline">{catRender}</div>
        </div>
      </div>
      <div
        className={`modal is-xsmall has-light-bg suggestion-modal ${
          reportOpen ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <div className="close-wrap">
                  <span
                    className="close-modal"
                    onClick={() => {
                      setReportOpen(false);
                    }}
                  >
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                {users.length ? (
                  <div className="columns is-multiline">
                    {users.map((i, k) => {
                      return (
                        <div className="column is-4" key={k}>
                          <div className="card">
                            <div className="img-container">
                              <img className="avatar" src={i.user.imageUrl} />
                            </div>
                            <div className="friend-info">
                              <h3>{i.user.nickName}</h3>
                            </div>

                            <div
                              style={{ textAlign: "center", marginTop: "10px" }}
                            >
                              <button
                                className="button is-solid primary-button raised is-rounded"
                                onClick={() => subscribeHandler(i.user.id)}
                              >
                                Subscribe
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <h1>
                    Please select different category and try again. No Creators
                    Found.
                  </h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};

export default connect(mapStateToProps)(Sugguestion);
