import React from "react";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import TopBanner from "./Landing/TopBanner";
import CreateAccountBanner from "./Landing/CreateAccountBanner";
import ForCreators from "./Landing/ForCreators";

const Landing = (props) => {
  return (
    <>
      <Header />

      <main>
        <TopBanner />

        <div>
          <ForCreators />
        </div>

        <div>
          <CreateAccountBanner />
        </div>
      </main>

      <Footer />
    </>
  );
};
export default Landing;
