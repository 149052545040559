import React from "react";
import Logo from "../images/Logo.png";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import SigninLayout from "./Layout/SigninLayout";
import BigInput from "../Elements/BigInput";
import Button from "../Elements/Button";

const stepOne = yup.object().shape({
  email: yup.string().email().required("email required!"),
});
const steptwo = yup.object().shape({
  otp: yup.string().required("OTP required!"),
});
const stepthree = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("password required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
const ForgotPassword = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [email, setEmail] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const ForgotStepOne = async (values) => {
    setEmail(values.email);
    setLoading(true);
    try {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("email", values.email);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/forgot-password",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      toast.success(response.data.response.message);
      setStep(2);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  const ForgotStepTwo = async (values) => {
    setLoading(true);
    setOtp(values.otp);
    try {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("email", email);
      data.append("otp", values.otp);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/verify/otp",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      toast.success(response.data.response.message);
      setLoading(false);
      setStep(3);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  const ForgotStepThree = async (values) => {
    setLoading(true);
    try {
      var data = JSON.stringify({
        email: email,
        otp: otp,
        password: values.password,
      });

      var config = {
        method: "put",
        url: "https://admin-staging.tribeofbabes.com/api/v1/password/reset",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      toast.success(response.data.response.message);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  return (
    <SigninLayout>
      {step === 1 ? (
        <div>
          <h1>Password Reset</h1>

          <Formik
            initialValues={{ email: "" }}
            validationSchema={stepOne}
            onSubmit={(values, { resetForm }) => {
              ForgotStepOne(values);
              resetForm();
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form className="tb-signin-layout__form">
                <div className="tb-signin-layout__form-row">
                  <BigInput
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    description={
                      errors.email && touched.email ? errors.email : ""
                    }
                  />
                </div>

                <div className="tb-signin-layout__button">
                  <Button disabled={loading} color="secondary">
                    {loading ? (
                      <CircularProgress size="1rem" style={{ color: "#fff" }} />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : step === 2 ? (
        <div>
          <h1>Password Reset</h1>

          <Formik
            initialValues={{ otp: "" }}
            validationSchema={steptwo}
            onSubmit={(values, { resetForm }) => {
              ForgotStepTwo(values);
              resetForm();
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form className="tb-signin-layout__form">
                <div className="tb-signin-layout__form-row">
                  <BigInput
                    name="otp"
                    placeholder="OTP code"
                    autocomplete="one-time-code"
                    value={values.otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.otp && touched.otp}
                    description={errors.otp && touched.otp ? errors.otp : ""}
                  />
                </div>

                <div className="tb-signin-layout__button">
                  <Button disabled={loading} color="secondary">
                    {loading ? (
                      <CircularProgress size="1rem" style={{ color: "#fff" }} />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div>
          <h1>Password Reset</h1>

          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={stepthree}
            onSubmit={(values, { resetForm }) => {
              ForgotStepThree(values);
              resetForm();
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form className="tb-signin-layout__form">
                <div className="tb-signin-layout__form-row">
                  <BigInput
                    type="password"
                    name="password"
                    placeholder="Password"
                    autocomplete="new-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password && touched.password}
                    description={
                      errors.password && touched.password ? errors.password : ""
                    }
                  />
                </div>

                <div className="tb-signin-layout__form-row">
                  <BigInput
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    autocomplete="new-password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.confirmPassword && touched.confirmPassword}
                    description={
                      errors.confirmPassword && touched.confirmPassword
                        ? errors.confirmPassword
                        : ""
                    }
                  />
                </div>

                <div className="tb-signin-layout__button">
                  <Button disabled={loading} color="secondary">
                    {loading ? (
                      <CircularProgress size="1rem" style={{ color: "#fff" }} />
                    ) : (
                      "Reset password"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      <div className="tb-signin-layout__links">
        <Link to="/signin">Have an account? Sign In</Link>
        <Link to="/signup">Don’t have an account? Sign Up</Link>
      </div>
    </SigninLayout>
  );
};
export default ForgotPassword;
