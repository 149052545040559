import React from "react";
import { X } from "react-feather";
import { ReactComponent as Change } from "../images/change-profile.svg";
import { ReactComponent as Upload } from "../images/upload-profile.svg";
import { ReactComponent as AddUpload } from "../images/add-profile.svg";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
const Crop = (props) => {
  const [image, setImage] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [option, setOption] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const handleVideoChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onload = function (upload) {
        setPreview(upload.target.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    }
  };
  const imageHandler = async () => {
    setLoading(true);
    try {
      var data = new FormData();
      props.action === "Banner"
        ? data.append("bannerImage", image)
        : data.append("image", image);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile/update",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      toast.success(response.data.response.message);
      props.updateHandler(true);
      setLoading(false);
      props.setProfileModal(false);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
      props.setProfileModal(false);
    }
  };
  return (
    <>
      <React.Fragment>
        <div
          id="change-profile-pic-modal"
          className="modal change-profile-pic-modal is-medium has-light-bg is-active"
        >
          <div className="modal-background" />
          <div className="modal-content">
            <div className="card">
              <div className="card-heading">
                <h3>
                  {props.action === "Banner"
                    ? "Update Banner Picture"
                    : "Update Profile Picture"}
                </h3>
                {/* Close X button */}
                <div className="close-wrap">
                  <span
                    className="close-modal"
                    onClick={() => props.setProfileModal(false)}
                  >
                    <X />
                  </span>
                </div>
              </div>
              {option === "" ? (
                <div className="card-body">
                  {/* Placeholder */}
                  <div className="selection-placeholder">
                    <div className="columns">
                      <div className="column is-12">
                        {/* Selection box */}
                        <div
                          className="selection-box modal-trigger"
                          data-modal="upload-crop-profile-modal"
                          onClick={() => setOption("computer")}
                        >
                          <div className="box-content">
                            <Change
                              style={{ maxHeight: "120px", width: "100%" }}
                            />
                            <div className="box-text">
                              <span>Upload</span>
                              <span>From your computer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {option === "computer" ? (
                <div className="card-body">
                  {!preview ? (
                    <>
                      <label className="profile-uploader-box">
                        <span className="inner-content">
                          <AddUpload />
                          <span>
                            Click here to <br />
                            {props.action === "Banner"
                              ? "upload a banner picture"
                              : "upload a profile picture"}
                            <br></br>
                            {props.action === "Banner"
                              ? "(Optimal image is 1000px x 330px)"
                              : "(Optimal image is 300px x 300px)"}
                          </span>
                        </span>
                        <input
                          type="file"
                          id="upload-profile-picture"
                          accept="image/*"
                          onChange={(e) => handleVideoChange(e)}
                        />
                      </label>
                      <div className="upload-demo-wrap is-hidden">
                        <div id="upload-profile"></div>
                        <div className="upload-help">
                          <a
                            id="profile-upload-reset"
                            className="profile-reset is-hidden"
                          >
                            Reset Picture
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className={
                        props.action !== "Banner"
                          ? "custom-profile-upload"
                          : "custom-profile-upload100"
                      }
                    >
                      <img src={preview} />
                    </div>
                  )}

                  <button
                    id="submit-profile-picture"
                    className={`button is-solid accent-button is-fullwidth raised ${
                      !preview ? "is-disabled" : ""
                    }`}
                    style={{ marginTop: "10px" }}
                    onClick={() => imageHandler()}
                  >
                    {loading ? (
                      <CircularProgress size="1rem" style={{ color: "#fff" }} />
                    ) : (
                      "Use Picture"
                    )}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateHandler: (value) => dispatch({ type: "UPDATE", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Crop);
