import React from "react";
import {
  Flag,
  Heart,
  Link2,
  MessageCircle,
  MoreVertical,
  Trash2,
  X,
  DollarSign,
  Play,
} from "react-feather";
import { ReactComponent as Bubble } from "../images/bubble.svg";
import moment from "moment";
import Slider from "react-slick";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import * as yup from "yup";
import { Formik, Form } from "formik";
import ReactPlayer from "react-player";

const update = yup.object().shape({
  desc: yup.string(),
  postType: yup.string().required(),
});

const tip = yup.object().shape({
  tip: yup.number().required(),
});

const report = yup.object().shape({
  postType: yup.string().required(),
  report: yup.string().required(),
});
const ProfilePosts = (props) => {
  const [comment, setComment] = React.useState(-1);
  const [action, setAction] = React.useState(-1);
  const [commentText, setCommentText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [reply, setReply] = React.useState(false);
  const [replyOpen, setReplyOpen] = React.useState(-1);
  const [updatePost, setUpdatePost] = React.useState(false);
  const [likeLoader, setLikeLoader] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [postId, setPostId] = React.useState("");
  const [postType, setPostType] = React.useState("");
  const [reportOpen, setReportOpen] = React.useState(false);

  const tipModal = (i) => {
    setPostId(i.id);
    setOpen(!open);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const commentHandler = async (i) => {
    setLoading(true);
    try {
      var data = new FormData();
      data.append("postId", i.id);
      data.append("comment", commentText);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/comments",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      toast.success("Comment Added");
      setLoading(false);
      setCommentText("");
      props.setLatest(true);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  const replyToggle = (commentKey) => {
    if (replyOpen === commentKey) {
      setReplyOpen(-1);
    } else {
      setReplyOpen(commentKey);
    }
  };
  const replyHandler = async (comment, i) => {
    try {
      var data = new FormData();
      data.append("postId", i.id);
      data.append("comment", reply);
      data.append("commentId", comment.id);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/sub-comments",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      toast.success("Reply Added");
      setReply("");
      props.setLatest(true);
    } catch (e) {
      toast.error(e.response.data.response.message);
    }
  };
  const deleteReply = (subComment) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/sub-comments/${subComment.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        toast.success("Reply Deleted");
        props.setLatest(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const deleteComment = (comment) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/comments/${comment.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        toast.success("Comment Deleted");
        props.setLatest(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const postDelete = async (i) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${i.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    props.setLatest(true);
    setAction(-1);
    toast.success(response.data.response.message);
  };

  const likeHandler = (i) => {
    setLikeLoader(i.id);
    var data = new FormData();
    data.append("postId", i.id);
    data.append("type", "post");

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/likes/posts",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function () {
        props.setLatest(true);
        setLikeLoader(-1);
      })
      .catch(function (error) {
        console.log(error);
        setLikeLoader(-1);
      });
  };
  const postUpdateHandler = async (values) => {
    setLoading(true);
    var data = new FormData();
    values.desc && data.append("detail", values.desc);
    data.append("postType", values.postType);

    var config = {
      method: "post",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${postId}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    const response = await axios(config);
    props.setLatest(true);
    setUpdatePost(false);
    toast.success("Post Updated");
    setLoading(false);
    setPostId("");
    setPostType("");
  };
  const tipHandler = (values) => {
    setLoading(true);
    var data = new FormData();
    data.append("postId", postId);
    data.append("amount", values.tip);
    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/tip/post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success(response.data.response.message);
        setLoading(false);
        setOpen(!open);
        props.updateHandler(true);
      })
      .catch(function (error) {
        toast.error(error.response.data.response.message);
        setLoading(false);
        setOpen(!open);
      });
  };

  const reportHandler = React.useCallback(
    (values, setSubmitting, resetForm) => {
      var data = new FormData();
      data.append("reportType", values.report);
      data.append("remarks", values.postType);

      var config = {
        method: "post",
        url: `https://admin-staging.tribeofbabes.com/api/v1/post_report/${postId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      axios(config).then(function (response) {
        toast.success(response.data.response.message);
        setSubmitting(false);
        resetForm();
        setReportOpen(false);
      });
    },
    [postId]
  );

  return (
    <React.Fragment>
      {props.posts
        .sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        .map((i, k) => {
          console.log({ i, k, comment });
          return (
            <div className="card is-post is-simple" key={k}>
              {/* Main wrap */}
              <div
                className={`content-wrap ${comment === k ? "is-hidden" : ""}`}
              >
                {/* Header */}
                <div className="card-heading">
                  {/* User image */}
                  <div className="user-block">
                    <div className="image">
                      <img src={i.user_profile} />
                    </div>
                    <div className="user-info">
                      <a>{i.nickName}</a>
                      <span className="time">
                        {moment(i.created_at).fromNow()}
                      </span>
                    </div>
                  </div>
                  {/* /partials/pages/feed/dropdowns/feed-post-dropdown.html */}
                  <div
                    className={`dropdown is-spaced is-right is-neutral dropdown-trigger ${
                      action === k ? "is-active" : ""
                    }`}
                  >
                    <div>
                      <div
                        className="button"
                        onClick={() => setAction(action === k ? -1 : k)}
                      >
                        <MoreVertical />
                      </div>
                    </div>
                    <div className="dropdown-menu" role="menu">
                      <div className="dropdown-content">
                        {i.username === props.user?.userName ? (
                          <a className="dropdown-item">
                            <div className="media">
                              <Trash2 />
                              <div
                                className="media-content"
                                onClick={() => postDelete(i)}
                              >
                                <h3>Delete</h3>
                                <small>Delete this Post</small>
                              </div>
                            </div>
                          </a>
                        ) : null}
                        <hr className="dropdown-divider" />
                        {i.username !== props.user?.userName ? (
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setReportOpen(true);
                              setPostId(i.id);
                            }}
                          >
                            <div className="media">
                              <Flag />
                              <div className="media-content">
                                <h3>Flag</h3>
                                <small>In case of inappropriate content.</small>
                              </div>
                            </div>
                          </a>
                        ) : null}
                        {i.username === props.user?.userName &&
                        i.postType !== "CloudRecording" ? (
                          <a className="dropdown-item">
                            <div className="media">
                              <Flag />
                              <a
                                className="media-content"
                                href={`/post/${i.urlCode}/edit`}
                                // onClick={() => {
                                //   setUpdatePost(true);
                                //   setPostType(i.postType);
                                //   setPostId(i.id);
                                // }}
                              >
                                <h3>Update Post</h3>
                                <small>Update this Post.</small>
                              </a>
                            </div>
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Header */}
                {/* Post body */}
                <div className="card-body">
                  {/* Post body text */}
                  <div className="post-text">
                    <p>{i.detail}</p>
                    <p />
                  </div>
                  <div className="post-image">
                    {i.postType !== "CloudRecording" ? (
                      <Slider {...settings}>
                        {i.postMedias.map((media, mediaKey) => {
                          return (
                            <React.Fragment key={mediaKey}>
                              {media.type === "image" ? (
                                <div className="post-media">
                                  <img src={media.url} />
                                </div>
                              ) : (
                                <div className="post-media">
                                  {media.featuredThumbnail !== "" ? (
                                    <video
                                      controls
                                      poster={media.featuredThumbnail}
                                    >
                                      <source src={media.url} />
                                    </video>
                                  ) : (
                                    <video controls>
                                      <source src={media.url} />
                                    </video>
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Slider>
                    ) : (
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        controls
                        url={`https://tribeofbabes-live-stream.s3.us-east-2.amazonaws.com/${i.liveStreamerLive}`}
                      />
                    )}
                  </div>
                  {/* Post actions */}
                </div>
                {/* /Post body */}
                {/* Post footer */}
                <div className="card-footer customPostFooter">
                  {/* Followers */}
                  {i.likes.length ? (
                    <div style={{ display: "flex" }}>
                      <div className="likers-group">
                        {i.likes.slice(0, 2).map((likeImg, likeId) => {
                          return <img key={likeId} src={likeImg.userProfile} />;
                        })}
                      </div>
                      <div className="likers-text">
                        <p>
                          {i.likes
                            .slice(i.likes.length - 4, i.likes.length - 1)
                            .reverse()
                            .map((like, likeKey) => {
                              return (
                                <a key={likeKey}>
                                  {like.nickName}{" "}
                                  {likeKey < 3 - 1 ? ", " : "..."}
                                </a>
                              );
                            })}
                        </p>
                        <p>liked this</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p>0 Likes</p>
                    </div>
                  )}

                  <div className="post-actions">
                    {/* /partials/pages/feed/buttons/feed-post-actions.html */}
                    <div style={{ display: "flex" }}>
                      <div className="like-wrapper">
                        {likeLoader !== i.id ? (
                          <a
                            className="like-button"
                            onClick={() => likeHandler(i)}
                          >
                            {i.likes.find(
                              (like) => like.userId === i.userId
                            ) ? (
                              <Heart
                                style={{ stroke: "#ca1444" }}
                                className="mdi mdi-heart not-liked bouncy likedPost"
                              />
                            ) : (
                              <Heart
                                style={{ stroke: "#ca1444" }}
                                className="mdi mdi-heart not-liked"
                              />
                            )}
                            <span className="like-overlay" />
                          </a>
                        ) : (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#ca1444" }}
                          />
                        )}
                      </div>
                      <div className="fab-wrapper is-share">
                        <CopyToClipboard
                          text={`https://community.tribeofbabes.com/post/${i.urlCode}`}
                          onCopy={() => toast.success("Link Copied")}
                        >
                          <a className="small-fab share-fab modal-trigger">
                            <Link2 />
                          </a>
                        </CopyToClipboard>
                      </div>
                      <div
                        className="fab-wrapper is-comment"
                        onClick={() => setComment(k)}
                      >
                        <a className="small-fab">
                          <MessageCircle />
                        </a>
                      </div>
                      {i.userId !== props.user.id ? (
                        <div
                          className="fab-wrapper is-comment"
                          onClick={() => tipModal(i)}
                        >
                          <a className="small-fab">
                            <DollarSign />
                          </a>
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="social-count"
                      style={{ marginTop: "10px", justifyContent: "end" }}
                    >
                      <div className="likes-count">
                        <Heart />
                        <span>{i.likes.length}</span>
                      </div>
                      <div className="comments-count">
                        <MessageCircle />
                        <span>{i.comments.length}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Post footer */}
              </div>
              {/* /Main wrap */}
              {/* Post #6 comments */}
              <div
                className={`comments-wrap ${comment === k ? "" : "is-hidden"}`}
              >
                {/* Header */}
                <div className="comments-heading">
                  <h4>
                    Comments
                    <small>({i.comments.length})</small>
                  </h4>
                  <div
                    className="close-comments"
                    onClick={() => setComment(-1)}
                  >
                    <X />
                  </div>
                </div>
                {/* /Header */}
                {/* Comments body */}
                <div className="comments-body has-slimscroll">
                  {!i.comments.length ? (
                    <div className="comments-placeholder">
                      <Bubble style={{ maxWidth: "80px" }} />
                      <h3>Nothing in here yet</h3>
                      <p>Be the first to post a comment.</p>
                    </div>
                  ) : (
                    <React.Fragment>
                      {i.comments.map((comment, commentKey) => {
                        return (
                          <div className="media is-comment" key={commentKey}>
                            <div className="media-left">
                              <div className="image">
                                <img src={comment.userProfile} />
                              </div>
                            </div>
                            <div className="media-content">
                              <a>
                                {comment.userFirstName} {comment.userLastName}
                              </a>
                              <span className="time">
                                {moment(comment.created_at).fromNow()}
                              </span>
                              <p>{comment.comment}</p>
                              <div className="controls controls-d-block">
                                <div className="controls-actions-custom">
                                  <div className="reply">
                                    <a onClick={() => replyToggle(commentKey)}>
                                      {replyOpen === commentKey
                                        ? "Close"
                                        : "Reply"}
                                    </a>
                                  </div>
                                  <div className="reply">
                                    <a onClick={() => deleteComment(comment)}>
                                      Delete
                                    </a>
                                  </div>
                                </div>

                                {replyOpen === commentKey ? (
                                  <div className="media post-comment has-emojis">
                                    {/* Textarea */}
                                    <div className="media-content">
                                      <div className="field">
                                        <p className="control">
                                          <textarea
                                            className="textarea comment-textarea"
                                            rows={5}
                                            placeholder="Write a reply..."
                                            onChange={(e) =>
                                              setReply(e.target.value)
                                            }
                                          />
                                        </p>
                                      </div>
                                      {/* Additional actions */}
                                      <div className="actions">
                                        <div className="toolbar">
                                          <a
                                            className="button is-solid primary-button raised"
                                            onClick={() =>
                                              replyHandler(comment, i)
                                            }
                                            style={{
                                              color: "#fff",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            {loading ? (
                                              <CircularProgress
                                                size="1rem"
                                                style={{ color: "#fff" }}
                                              />
                                            ) : (
                                              "Post Reply"
                                            )}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {comment.subComments.map(
                                (subComment, subCommentkey) => {
                                  return (
                                    <div
                                      className="media is-comment"
                                      key={subCommentkey}
                                    >
                                      <div className="media-left">
                                        <div className="image">
                                          <img src={subComment.userProfile} />
                                        </div>
                                      </div>
                                      <div className="media-content">
                                        <a>{subComment.nickName}</a>
                                        <span className="time">
                                          {moment(
                                            subComment.created_at
                                          ).fromNow()}
                                        </span>
                                        <p>{subComment.comment}</p>

                                        <div className="controls">
                                          <div className="reply">
                                            <a
                                              onClick={() =>
                                                deleteReply(subComment)
                                              }
                                            >
                                              Delete
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className="media-right">
                              <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                                <div>
                                  <div className="button">
                                    <i data-feather="more-vertical"></i>
                                  </div>
                                </div>
                                <div className="dropdown-menu" role="menu">
                                  <div className="dropdown-content">
                                    <a className="dropdown-item">
                                      <div className="media">
                                        <i data-feather="x"></i>
                                        <div className="media-content">
                                          <h3>Hide</h3>
                                          <small>Hide this comment.</small>
                                        </div>
                                      </div>
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    <a href="#" className="dropdown-item">
                                      <div className="media">
                                        <i data-feather="flag"></i>
                                        <div className="media-content">
                                          <h3>Report</h3>
                                          <small>Report this comment.</small>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )}
                </div>
                {/* /Comments body */}
                {/* Comments footer */}
                <div className="card-footer">
                  <div className="media post-comment has-emojis">
                    {/* Textarea */}
                    <div className="media-content">
                      <div className="field">
                        <p className="control">
                          <textarea
                            className="textarea comment-textarea"
                            rows={5}
                            placeholder="Write a comment..."
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                          />
                        </p>
                      </div>
                      {/* Additional actions */}
                      <div className="actions">
                        <div className="toolbar">
                          <a
                            className="button is-solid primary-button raised"
                            onClick={() => commentHandler(i)}
                          >
                            {loading ? (
                              <CircularProgress
                                size="1rem"
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              "Post Comment"
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Comments footer */}
              </div>
              {/* /Post #6 comments */}
            </div>
          );
        })}

      <div
        id="share-modal"
        className={`modal share-modal is-xsmall has-light-bg ${
          updatePost ? "is-active" : ""
        }`}
      >
        <div className="modal-background" />
        <div className="modal-content">
          <div className="card">
            <div className="card-heading">
              <label>Update Post</label>
              {/* Close X button */}
              <div className="close-wrap">
                <span
                  className="close-modal"
                  onClick={() => setUpdatePost(false)}
                >
                  <X />
                </span>
              </div>
            </div>
            <div className="card-body">
              <Formik
                onSubmit={(values, { resetForm }) => {
                  postUpdateHandler(values);
                  resetForm();
                }}
                initialValues={{
                  desc: "",
                  postType: "",
                }}
                validationSchema={update}
              >
                {({ values, errors, touched, handleChange, handleBlur }) => (
                  <Form>
                    <React.Fragment>
                      <div className="field field-group">
                        <label>Post Type</label>
                        <select
                          name="postType"
                          className="input is-fade"
                          value={values.postType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ display: "block" }}
                        >
                          <option value="" label="post type" />
                          <option value="Archived" label="Archived" />
                          <option value="Published" label="Published" />
                          <option value="Teaser" label="Teaser" />
                        </select>
                        <label
                          style={{
                            marginBottom: "10px",
                            display: "block",
                            marginTop: "10px",
                          }}
                        >
                          Description
                        </label>
                        <div className="control">
                          <input
                            type="text"
                            className="input is-fade"
                            placeholder="Description"
                            name="desc"
                            id="desc"
                            value={values.desc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      {errors.desc && touched.desc && errors.desc ? (
                        <div className="dangerText">{errors.desc}</div>
                      ) : null}
                    </React.Fragment>
                    <div className="button-wrap">
                      <button
                        type="button"
                        className="button is-solid dark-grey-button close-modal"
                        onClick={() => setUpdatePost(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="button is-solid primary-button close-modal"
                      >
                        {loading ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal is-xsmall has-light-bg share-modal ${
          open ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <h3>Tip</h3>
                <div className="close-wrap">
                  <span className="close-modal" onClick={() => setOpen(false)}>
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ tip: "" }}
                  validationSchema={tip}
                  onSubmit={(values, { resetForm }) => {
                    tipHandler(values);
                    resetForm();
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <Form>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="number"
                            placeholder="10"
                            name="tip"
                            id="tip"
                            value={values.tip}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.tip && touched.tip && errors.tip ? (
                            <div className="dangerText">{errors.tip}</div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        className="button is-solid primary-button raised is-rounded is-fullwidth"
                        disabled={loading}
                        type="submit"
                      >
                        {loading ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal is-xsmall has-light-bg share-modal ${
          reportOpen ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <h3>Report</h3>
                <div className="close-wrap">
                  <span
                    className="close-modal"
                    onClick={() => {
                      setReportOpen(false);
                    }}
                  >
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                {" "}
                <Formik
                  initialValues={{
                    postType: "",
                    report: "",
                  }}
                  validationSchema={report}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    reportHandler(values, setSubmitting, resetForm);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <Form>
                      <div className="field">
                        <div className="control">
                          <select
                            className="input"
                            type="text"
                            placeholder="report"
                            name="postType"
                            id="postType"
                            value={values.postType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option className="d-none">Report Type</option>
                            <option value="Hate speech or symbols">
                              Hate speech or symbols
                            </option>
                            <option value="Violence or dangerous organizations">
                              Violence or dangerous organizations
                            </option>
                            <option value="Sale of illegal or regulated goods">
                              Sale of illegal or regulated goods
                            </option>
                            <option value="Bullying or harassment">
                              Bullying or harassment
                            </option>
                            <option value="Intellectual property violation">
                              Intellectual property violation
                            </option>
                            <option value="Suicide, self-injury or eating disorders">
                              suicide, self-injury or eating disorders
                            </option>
                            <option value="Scam or fraud">Scam or fraud</option>
                          </select>
                          {errors.postType &&
                          touched.postType &&
                          errors.postType ? (
                            <div className="dangerText">{errors.tip}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="message"
                            name="report"
                            id="report"
                            value={values.report}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.report && touched.report && errors.report ? (
                            <div className="dangerText">{errors.report}</div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        className="button is-solid primary-button raised is-rounded is-fullwidth"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.StreamApp.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateHandler: (value) => dispatch({ type: "UPDATE", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePosts);
