import { SIDEBAR, USER, TOKEN, UPDATE, DARK } from '../../actions/app/appActions';

const initialState = {
 sidebar: false,
 user: undefined,
 token: '',
 update: false,
 dark: false,
};
const app = (state = initialState, action) => {
 switch (action.type) {
  case SIDEBAR:
   return {
    ...state,
    sidebar: action.value,
   };
  case USER:
   return {
    ...state,
    user: action.value,
   };
  case TOKEN:
   return {
    ...state,
    token: action.value,
   };
  case UPDATE:
   return {
    ...state,
    update: action.value,
   };
  case DARK:
   return {
    ...state,
    dark: action.value,
   };
  default:
   return state;
 }
};
export default app;
