import React from "react";
import {
  Calendar,
  DollarSign,
  Facebook,
  Globe,
  Instagram,
  Mail,
  MapPin,
  Menu,
  Percent,
  Twitter,
  User,
  Youtube,
} from "react-feather";
import { ReactComponent as UserGuide } from "../images/1.svg";
import { ReactComponent as Bank } from "../images/Bank.svg";
import { ReactComponent as Noti } from "../images/Noti.svg";
import { ReactComponent as Sub } from "../images/Subs.svg";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import "flatpickr/dist/themes/dark.css";
import { ReactComponent as Pin } from "../images/Pin.svg";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const general = yup.object().shape({
  firstName: yup.string().required("first name required!"),
  lastName: yup.string().required("last name required!"),
  nickName: yup.string().required("nick name required!"),
  email: yup.string().required("email name required!"),
  bio: yup.string().required("bio name required!"),
  backupEmail: yup.string().required("backup email required!").nullable(),
  phoneNumber: yup.string().required("phone number required!").nullable(),
  address: yup.string().required("address required!").nullable(),
  city: yup.string().required("city required!").nullable(),
  state: yup.string().required("state required!").nullable(),
  zipCode: yup.string().required("zip code required!").nullable(),
  country: yup.string().required("country required!").nullable(),
  dateOfBirth: yup.string().required("date of birth required!").nullable(),
  facebook: yup.string().url().nullable(),
  instagram: yup.string().url().nullable(),
  twitter: yup.string().url().nullable(),
  pinterest: yup.string().url().nullable(),
  youtube: yup.string().url().nullable(),
});
const bank = yup.object().shape({
  bankName: yup.string().required("bank name required!"),
  bankNumber: yup.string().required("bank number required"),
  bankNumber: yup.string().required("bank number required"),
  bankCountryName: yup.string().required("bank country name required"),
  bankRoutingNumber: yup.string().required("bank routing number required"),
  w9Form: yup
    .mixed()
    .required("W9 Form is Required")
    .test("FILE_SIZE", "Uploaded file is too big.", (value) =>
      value ? !value || (value && value.size <= 52428800) : ""
    )
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) =>
      value ? value.type === "application/pdf" : ""
    ),
});

const notification = yup.object().shape({
  backupEmail: yup.bool(),
  comment: yup.bool(),
  like: yup.bool(),
  follow: yup.bool(),
  liveStream: yup.bool(),
});

const subs = yup.object().shape({
  price: yup.number().required(),
  discount: yup.number(),
});

const borderless = yup.object().shape({
  id: yup.string().required(),
});

const Settings = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [, setBankDetails] = React.useState(0);
  const [token, setToken] = React.useState("");
  const [bank, setbank] = React.useState();

  const generalHandler = async (values) => {
    setLoading(true);
    try {
      var data = new FormData();
      data.append("firstName", values.firstName);
      data.append("lastName", values.lastName);
      data.append("backupEmail", values.backupEmail);
      data.append("phoneNumber", values.phoneNumber);
      data.append("dateOfBirth", values.dateOfBirth);
      data.append("city", values.city);
      data.append("country", values.country);
      data.append("zipcode", values.zipCode);
      data.append("state", values.state);
      data.append("address", values.address);
      data.append("facebookUrl", values.facebook);
      data.append("instagramUrl", values.instagram);
      data.append("twitterUrl", values.twitter);
      data.append("youtubeUrl", values.youtube);
      data.append("pinterestUrl", values.pinterest);
      data.append("nickName", values.nickName);
      data.append("bio", values.bio);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile/update",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      await userProfile();
      toast.success(response.data.response.message);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  const userProfile = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const subscriptionHandler = async (values) => {
    setLoading(true);
    try {
      var data = new FormData();
      data.append("price", values.price);
      data.append("discountPercentage", values.discount);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/subscription",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      await axios(config);
      getSubs();
      toast.success("Subscription Updated");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const getSubs = () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/subscription/${props.user.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPrice(response.data.response.data.price);
        setDiscount(response.data.response.data.discountPercentage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const notificationHandler = (values) => {
    setLoading(true);
    var data = JSON.stringify({
      isNotificationEnableBackupEmail: values.backupEmail,
      isNotificationEnableComment: values.comment,
      isNotificationEnableLike: values.like,
      isNotificationEnableFollow: values.follow,
      isNotificationEnableLiveStream: values.liveStream,
    });

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/notification/setting",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function () {
        setLoading(false);
        userProfile();
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error.response.data.response.message);
      });
  };
  const bankInfo = async () => {
    setLoading(true);
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setBankDetails(response.data.response.data);
        setLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.response.message);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (props.token) {
      getSubs();
      bankInfo();
    }
  }, [props.token]);

  const getToken = React.useCallback(() => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/generate-jwt",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setToken(response.data.response.data.jwt);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props]);

  React.useEffect(() => {
    if (props.token) {
      getToken();
    }
  }, [props]);

  React.useEffect(() => {
    if (bank) {
      console.log(bank);
      var data = new FormData();
      data.append("contactId", bank.externalContactId);
      data.append("status", bank.status);
      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };
      axios(config).then(() => {
        toast.success("Bank Details Updated");
      });
    }
  }, [bank]);

  const handleIdhandler = React.useCallback(
    (values, setSubmitting) => {
      var data = new FormData();
      data.append("contactId", values.id);
      data.append("status", "success");
      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };
      axios(config).then(() => {
        toast.success("Bank Details Updated");
        setSubmitting(false);
      });
    },
    [props]
  );

  React.useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data.externalContactId) {
        setbank(e.data);
      }
    });

    return () => {
      window.removeEventListener("message", console.log("working"));
    };
  }, []);

  return (
    <>
      <Sidebar props={props} />
      {props.user ? (
        <div
          className={`view-wrapper is-sidebar-v1 ${
            props.sidebar ? "is-fold" : ""
          }`}
        >
          <Header navigate={props.history} />
          <div
            id="settings"
            className="container sidebar-boxed"
            data-open-sidebar
            data-page-title="Settings"
          >
            <div className="settings-wrapper is-full">
              {/* /partials/settings/sections/general-settings.html */}
              <div id="general-settings" className="settings-section is-active">
                <div className="settings-panel">
                  <div className="title-wrap">
                    <a className="mobile-sidebar-trigger">
                      <Menu />
                    </a>
                    <h2>General Settings</h2>
                  </div>
                  <div className="settings-form-wrapper">
                    <Formik
                      onSubmit={(values) => {
                        generalHandler(values);
                      }}
                      initialValues={{
                        firstName: props.user?.firstName
                          ? props.user?.firstName
                          : "",
                        lastName: props.user?.lastName
                          ? props.user?.lastName
                          : "",
                        email: props.user?.email ? props.user?.email : "",
                        backupEmail: props.user?.backupEmail
                          ? props.user?.backupEmail
                          : "",
                        phoneNumber: props.user?.phoneNumber
                          ? props.user?.phoneNumber
                          : "",
                        address: props.user?.address ? props.user?.address : "",
                        city: props.user?.city ? props.user?.city : "",
                        state: props.user?.state ? props.user?.state : "",
                        zipCode: props.user?.zipcode ? props.user?.zipcode : "",
                        country: props.user?.country ? props.user?.country : "",
                        dateOfBirth: props.user?.dateOfBirth
                          ? props.user?.dateOfBirth
                          : "",
                        facebook: props.user?.facebookUrl
                          ? props.user?.facebookUrl
                          : "",
                        instagram: props.user?.instagramUrl
                          ? props.user?.instagramUrl
                          : "",
                        twitter: props.user?.twitterUrl
                          ? props.user?.twitterUrl
                          : "",
                        pinterest: props.user?.pinterestUrl
                          ? props.user?.pinterestUrl
                          : "",
                        youtube: props.user?.youtubeUrl
                          ? props.user?.youtubeUrl
                          : "",
                        nickName: props.user?.nickName
                          ? props.user?.nickName
                          : "",
                        bio: props.user?.bio ? props.user?.bio : "",
                      }}
                      validationSchema={general}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="settings-form">
                            <div className="columns is-multiline">
                              <div className="column is-6">
                                {/* first name */}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>First Name*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="first name"
                                        name="firstName"
                                        id="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <User />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.firstName &&
                                  touched.firstName &&
                                  errors.firstName ? (
                                    <div className="dangerText">
                                      {errors.firstName}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                                {/* email*/}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>Email*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="email"
                                        name="email"
                                        id="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled
                                      />
                                      <div className="form-icon">
                                        <Mail />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.email &&
                                  touched.email &&
                                  errors.email ? (
                                    <div className="dangerText">
                                      {errors.email}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>

                              <div className="column is-6">
                                {/* last name */}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>Last Name*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="last name"
                                        name="lastName"
                                        id="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <User />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.lastName &&
                                  touched.lastName &&
                                  errors.lastName ? (
                                    <div className="dangerText">
                                      {errors.lastName}
                                    </div>
                                  ) : null}
                                </React.Fragment>

                                {/*backup email*/}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>Backup Email*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="backup email"
                                        name="backupEmail"
                                        id="backupEmail"
                                        value={values.backupEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Mail />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.backupEmail &&
                                  touched.backupEmail &&
                                  errors.backupEmail ? (
                                    <div className="dangerText">
                                      {errors.backupEmail}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>

                              <div className="column is-12">
                                {/* Nick Name */}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>Nick Name*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        disabled
                                        className="input is-fade"
                                        placeholder="nick name"
                                        name="nickName"
                                        id="nickName"
                                        value={values.nickName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <User />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.nickName &&
                                  touched.nickName &&
                                  errors.nickName ? (
                                    <div className="dangerText">
                                      {errors.nickName}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>

                              <div className="column is-12">
                                {/* phone number */}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>Phone Number*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="phone number"
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Mail />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.phoneNumber &&
                                  touched.phoneNumber &&
                                  errors.phoneNumber ? (
                                    <div className="dangerText">
                                      {errors.phoneNumber}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>

                              <div className="column is-12">
                                {/* bio */}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>Bio*</label>
                                    <textarea
                                      type="text"
                                      className="input is-fade"
                                      placeholder="Bio"
                                      name="bio"
                                      id="bio"
                                      value={values.bio}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {errors.phoneNumber &&
                                  touched.phoneNumber &&
                                  errors.phoneNumber ? (
                                    <div className="dangerText">
                                      {errors.phoneNumber}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>

                              <div className="column is-12">
                                {/*address*/}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>Address*</label>
                                    <div className="control">
                                      <textarea
                                        type="text"
                                        className="textarea is-fade"
                                        rows={1}
                                        placeholder="address"
                                        name="address"
                                        id="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                  {errors.address &&
                                  touched.address &&
                                  errors.address ? (
                                    <div className="dangerText">
                                      {errors.address}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>

                              <div className="column is-6">
                                {/*city*/}
                                <React.Fragment>
                                  <div className="field field-group">
                                    <label>City*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="city"
                                        name="city"
                                        id="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <MapPin />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.city &&
                                  touched.city &&
                                  errors.city ? (
                                    <div className="dangerText">
                                      {errors.city}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-6">
                                {/*country*/}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>Country*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="country"
                                        name="country"
                                        id="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Globe />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.country &&
                                  touched.country &&
                                  errors.country ? (
                                    <div className="dangerText">
                                      {errors.country}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-6">
                                {/*state*/}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>State*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="state"
                                        name="state"
                                        id="state"
                                        value={values.state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <MapPin />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.state &&
                                  touched.state &&
                                  errors.state ? (
                                    <div className="dangerText">
                                      {errors.state}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-6">
                                {/*zip code*/}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>zip code*</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="zip code"
                                        name="zipCode"
                                        id="zipCode"
                                        value={values.zipCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <MapPin />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.zipCode &&
                                  touched.zipCode &&
                                  errors.zipCode ? (
                                    <div className="dangerText">
                                      {errors.zipCode}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-12">
                                {/*date of birth*/}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>Date of Birth*</label>
                                    <div className="control has-icon">
                                      <Flatpickr
                                        className="input is-fade"
                                        name="dateOfBirth"
                                        id="dateOfBirth"
                                        value={values.dateOfBirth}
                                        placeholder="date of birth"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "dateOfBirth",
                                            e[0].toISOString()
                                          );
                                        }}
                                      />
                                      <div className="form-icon">
                                        <Calendar />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.dateOfBirth &&
                                  touched.dateOfBirth &&
                                  errors.dateOfBirth ? (
                                    <div className="dangerText">
                                      {errors.dateOfBirth}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-6">
                                {/*facebook*/}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>Facebook</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="facebook"
                                        name="facebook"
                                        id="facebook"
                                        value={values.facebook}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Facebook />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.facebook &&
                                  touched.facebook &&
                                  errors.facebook ? (
                                    <div className="dangerText">
                                      {errors.facebook}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-6">
                                {/*instagram*/}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>instagram</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="instagram"
                                        name="instagram"
                                        id="instagram"
                                        value={values.instagram}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Instagram />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.instagram &&
                                  touched.instagram &&
                                  errors.instagram ? (
                                    <div className="dangerText">
                                      {errors.instagram}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-6">
                                {/*twitter*/}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>twitter</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="twitter"
                                        name="twitter"
                                        id="twitter"
                                        value={values.twitter}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Twitter />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.twitter &&
                                  touched.twitter &&
                                  errors.twitter ? (
                                    <div className="dangerText">
                                      {errors.twitter}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              {/* pinterest */}
                              <div className="column is-6">
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>pinterest</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="pinterest"
                                        name="pinterest"
                                        id="pinterest"
                                        value={values.pinterest}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Pin />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.pinterest &&
                                  touched.pinterest &&
                                  errors.pinterest ? (
                                    <div className="dangerText">
                                      {errors.pinterest}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-6">
                                {/* youtube */}
                                <React.Fragment>
                                  <div className="field field-group is-autocomplete">
                                    <label>youtube</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="youtube"
                                        name="youtube"
                                        id="youtube"
                                        value={values.youtube}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Youtube />
                                      </div>
                                    </div>
                                  </div>
                                  {errors.youtube &&
                                  touched.youtube &&
                                  errors.youtube ? (
                                    <div className="dangerText">
                                      {errors.youtube}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                              <div className="column is-12">
                                <div className="buttons">
                                  <button
                                    className="button is-solid accent-button form-button"
                                    disabled={loading}
                                    type="submit"
                                  >
                                    {loading ? (
                                      <CircularProgress
                                        size="1rem"
                                        style={{ color: "#fff" }}
                                      />
                                    ) : (
                                      "Save Changes"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>

                    <div className="illustration">
                      <UserGuide />
                      <p>
                        If you'd like to learn more about general settings, you
                        can read about it in the{" "}
                        <Link to="/help">user guide</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Bank */}
              {props.user?.creatorType === "Model" ? (
                <div
                  id="general-settings"
                  className="settings-section is-active"
                  style={{ marginTop: "20px" }}
                >
                  <div className="settings-panel payouts-panel">
                    <div className="title-wrap">
                      <h2 className="payouts-panel-heading">
                        Connect you Account for Payouts
                      </h2>
                    </div>
                    <div className="settings-form-wrapper accounts-payouts">
                      <div>
                        <iframe
                          title="widget"
                          width="100%"
                          height="670px"
                          src={`https://secure.getborderless.com/widgets/add-external-contact?token=${token}`}
                        />
                      </div>

                      <div className="illustration">
                        <Bank
                          style={{ maxWidth: "260px", maxHeight: "150px" }}
                        />
                        <p>Borderless Handle: {bank?.externalContactId}</p>
                        <Formik
                          initialValues={{
                            id: "",
                          }}
                          validationSchema={borderless}
                          onSubmit={(values, { setSubmitting }) => {
                            handleIdhandler(values, setSubmitting);
                          }}
                          enableReinitialize
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                          }) => (
                            <Form>
                              <React.Fragment>
                                <div className="field field-group is-autocomplete">
                                  <label>handle ID</label>
                                  <div className="control">
                                    <input
                                      type="text"
                                      className="input is-fade"
                                      placeholder="^aaa21"
                                      name="id"
                                      id="id"
                                      value={values.id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                {errors.id && touched.id && errors.id ? (
                                  <div className="dangerText">{errors.id}</div>
                                ) : null}
                                <p>
                                  Note: Please use the form to create new
                                  borderless account for payouts. If you already
                                  have a borderless account please add the
                                  borderless handle in the text box i.e "^aaa21"
                                  the format must include "^" character. For
                                  example, a valid external contact would be
                                  “^aaa21”. If you are unsure about your
                                  borderless handle, please contact borderless
                                  payments to get your valid handle ID.
                                </p>
                              </React.Fragment>
                              <button
                                className="button is-solid accent-button form-button"
                                disabled={loading}
                                type="submit"
                              >
                                {isSubmitting ? (
                                  <CircularProgress
                                    size="1rem"
                                    style={{ color: "#fff" }}
                                  />
                                ) : (
                                  "Save Changes"
                                )}
                              </button>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Notification */}
              <div
                id="general-settings"
                className="settings-section is-active"
                style={{ marginTop: "20px" }}
              >
                <div className="settings-panel">
                  <div className="title-wrap">
                    <h2>Notification Settings</h2>
                  </div>
                  <div className="settings-form-wrapper">
                    <Formik
                      initialValues={{
                        backupEmail:
                          props.user?.isNotificationEnableBackupEmail === 1
                            ? true
                            : false,
                        comment:
                          props.user?.isNotificationEnableComment === 1
                            ? true
                            : false,
                        like:
                          props.user?.isNotificationEnableLike === 1
                            ? true
                            : false,
                        follow:
                          props.user?.isNotificationEnableFollow === 1
                            ? true
                            : false,
                        liveStream:
                          props.user?.isNotificationEnableLiveStream === 1
                            ? true
                            : false,
                      }}
                      validationSchema={notification}
                      onSubmit={(values) => {
                        notificationHandler(values);
                      }}
                      enableReinitialize
                    >
                      {({ values, handleChange, handleBlur }) => (
                        <Form>
                          <div className="settings-form">
                            <div className="columns is-multiline">
                              <div className="column is-6">
                                {/*backup email*/}
                                <div className="switch-block">
                                  <label className="f-switch">
                                    <input
                                      type="checkbox"
                                      className="is-switch"
                                      name="backupEmail"
                                      id="backupEmail"
                                      checked={values.backupEmail}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i></i>
                                  </label>
                                  <div className="meta">
                                    <p>Send Notifications to Backup Email</p>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6">
                                {/*Comment*/}
                                <div className="switch-block">
                                  <label className="f-switch">
                                    <input
                                      type="checkbox"
                                      className="is-switch"
                                      name="comment"
                                      id="comment"
                                      checked={values.comment}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i></i>
                                  </label>
                                  <div className="meta">
                                    <p>Disable Comment Notifications</p>
                                  </div>
                                </div>
                                {/*Field*/}
                              </div>
                              <div className="column is-6">
                                {/*Field*/}
                                <div className="switch-block">
                                  <label className="f-switch">
                                    <input
                                      type="checkbox"
                                      className="is-switch"
                                      name="like"
                                      id="like"
                                      checked={values.like}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i></i>
                                  </label>
                                  <div className="meta">
                                    <p>Disable Like Notifications</p>
                                  </div>
                                </div>
                                {/*Field*/}
                              </div>
                              <div className="column is-6">
                                {/*Field*/}
                                <div className="switch-block">
                                  <label className="f-switch">
                                    <input
                                      type="checkbox"
                                      className="is-switch"
                                      name="follow"
                                      id="follow"
                                      checked={values.follow}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i></i>
                                  </label>
                                  <div className="meta">
                                    <p>Disable Follow Notifications</p>
                                  </div>
                                </div>
                                {/*Field*/}
                              </div>
                              <div className="column is-6">
                                {/*Field*/}
                                <div className="switch-block">
                                  <label className="f-switch">
                                    <input
                                      type="checkbox"
                                      className="is-switch"
                                      name="liveStream"
                                      id="liveStream"
                                      checked={values.liveStream}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i></i>
                                  </label>
                                  <div className="meta">
                                    <p>Disable Live Stream Notifications</p>
                                  </div>
                                </div>
                                {/*Field*/}
                              </div>
                              <div className="column is-12">
                                <div className="buttons">
                                  <button
                                    className="button is-solid accent-button form-button"
                                    disabled={loading}
                                    type="submit"
                                  >
                                    {loading ? (
                                      <CircularProgress
                                        size="1rem"
                                        style={{ color: "#fff" }}
                                      />
                                    ) : (
                                      "Save Changes"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>

                    <div className="illustration">
                      <Noti style={{ maxWidth: "260px", maxHeight: "150px" }} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Subscription */}
              {props.user?.creatorType === "Model" ? (
                <div
                  id="general-settings"
                  className="settings-section is-active"
                  style={{ marginTop: "20px" }}
                >
                  <div className="settings-panel">
                    <div className="title-wrap">
                      <h2>Subscription Creation</h2>
                    </div>
                    <div className="settings-form-wrapper">
                      <Formik
                        onSubmit={(values, { resetForm }) => {
                          subscriptionHandler(values);
                        }}
                        initialValues={{ price: price, discount: discount }}
                        enableReinitialize
                        validationSchema={subs}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                        }) => (
                          <Form>
                            <div className="settings-form">
                              <div className="columns is-multiline">
                                <div className="column is-6">
                                  {/*Field*/}
                                  <React.Fragment>
                                    <div className="field field-group">
                                      <label>Price</label>
                                      <div className="control has-icon">
                                        <input
                                          type="text"
                                          className="input is-fade"
                                          placeholder="Price"
                                          name="price"
                                          id="price"
                                          value={values.price}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <div className="form-icon">
                                          <DollarSign />
                                        </div>
                                      </div>
                                    </div>
                                    {errors.price &&
                                    touched.price &&
                                    errors.price ? (
                                      <div className="dangerText">
                                        {errors.price}
                                      </div>
                                    ) : null}
                                  </React.Fragment>

                                  {/*Field*/}
                                </div>
                                <div className="column is-6">
                                  {/*Field*/}
                                  <div className="field field-group">
                                    <label>Discount Percentage</label>
                                    <div className="control has-icon">
                                      <input
                                        type="text"
                                        className="input is-fade"
                                        placeholder="Discount Percentage"
                                        name="discount"
                                        id="discount"
                                        value={values.discount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="form-icon">
                                        <Percent />
                                      </div>
                                    </div>
                                  </div>
                                  {/*Field*/}
                                </div>
                                <div className="column is-12">
                                  <div className="buttons">
                                    <button
                                      className="button is-solid accent-button form-button"
                                      disabled={loading}
                                      type="submit"
                                    >
                                      {loading ? (
                                        <CircularProgress
                                          size="1rem"
                                          style={{ color: "#fff" }}
                                        />
                                      ) : (
                                        "Save Changes"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>

                      <div className="illustration">
                        <Sub
                          style={{ maxWidth: "260px", maxHeight: "150px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <CircularProgress size="1rem" />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    userHandler: (value) => dispatch({ type: "USER", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
