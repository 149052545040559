import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { connect } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "./Footer";

const Dashboard = (props) => {
  const [analytics, setAnalytics] = React.useState({});
  const [comments, setComments] = React.useState(undefined);
  const [post, setPost] = React.useState(undefined);
  const [subs, setSubs] = React.useState(undefined);
  const [earnings, setEarnings] = React.useState(undefined);
  const [earningsMonthly, setEarningsMonthly] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  function getFullName(params) {
    return `${params.row.user.nickName}`;
  }
  function getTime(params) {
    return `${moment(params.row.completedDatetime).format("YY-MM-DD, h:mm a")}`;
  }

  const columns = [
    { field: "id", headerName: "TID", width: 100 },
    { field: "amount", headerName: "Earning", width: 100 },
    { field: "commissionAmount", headerName: "Fee", width: 100 },
    { field: "transactionType", headerName: "Type", width: 100 },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      editable: true,
      valueGetter: getFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "completedDatetime",
      headerName: "Timestamp",
      width: 160,
      editable: true,
      valueGetter: getTime,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
  ];

  const analyticsGet = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/analytics",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    return response.data.response.data;
  };

  const commentsGet = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/comments/latest",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    return response.data.response.data;
  };

  const getPost = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/posts/latest",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    return response.data.response.data;
  };

  const getSubs = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/subscribers/users",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    return response.data.response.data;
  };

  const getEarnings = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/earnings?transactionType=Tip",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    return response.data.response.data;
  };

  const getEarningsMonthly = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/earnings",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    return response.data.response.data;
  };

  React.useEffect(() => {
    if (props.token) {
      (async () => {
        const comments = await commentsGet();
        const analytics = await analyticsGet();
        const post = await getPost();
        const sub = await getSubs();
        const earnings = await getEarnings();
        const earningsMonthly = await getEarningsMonthly();
        setAnalytics(analytics);
        setEarningsMonthly(earningsMonthly);
        setComments(comments);
        setPost(post);
        setSubs(sub);
        setEarnings(earnings);
        setLoading(true);
      })();
    }
  }, [props.token]);

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        {loading ? (
          <div id="creator-dashboard" className="dashboard-container">
            <div className="dashboard-body">
              <div style={{ display: "flex" }}>
                <div className="column is-8">
                  <div style={{ height: "400px" }}>
                    <DataGrid
                      rows={earnings}
                      columns={columns}
                      pageSize={6}
                      rowsPerPageOptions={[5]}
                      disableColumnMenu
                    />
                  </div>
                </div>
                <div className="column is-4">
                  <div className="dashboard-box is-compact">
                    <div className="help-block">
                      <h4>Earnings</h4>
                      <h4>
                        {moment().format("MMMM")} 1st Tokens :{" "}
                        {earningsMonthly.firstHalfMonth}
                      </h4>
                      <h4>
                        {moment().format("MMMM")} 15th Tokens :{" "}
                        {earningsMonthly.secondHalfMonth}
                      </h4>
                      <p>
                        Earnings are made every 1st and 15th of every month. In
                        case of earnings not process, the amount carried over.
                      </p>
                      {/* <p>{props.user?.walletAmount}</p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                {/*Dashboard column*/}
                <div className="column is-4">
                  {/*Dashboard box*/}
                  {post ? (
                    <div className="dashboard-box">
                      <h3 className="title is-5 is-thin">Latest content</h3>
                      <div className="box-media">
                        {/* <img src={post?.postMedias[0].type === "image" ? post?.postMedias[0].url : "https://via.placeholder.com/1600x900"} /> */}
                      </div>
                      <div className="box-content">
                        <p className="small">First 2 days 4 hours</p>
                        <div className="box-lines">
                          <div className="box-line">
                            <span className="left">Total Tips Recieved</span>
                            <span className="right">
                              Tokens: {post?.totalTips.toFixed(2)}
                            </span>
                          </div>
                          <div className="box-line">
                            <span className="left">Likes</span>
                            <span className="right">{post?.likesCount}</span>
                          </div>
                          <div className="box-line">
                            <span className="left">Comments</span>
                            <span className="right">
                              {post?.comments.length}
                            </span>
                          </div>
                          <div className="box-line">
                            <span className="left">Views</span>
                            <span className="right">{post?.views}</span>
                          </div>
                        </div>
                        <div className="button-wrap">
                          <button
                            className="button is-solid primary-button is-fullwidth"
                            onClick={() =>
                              props.history.push(`/post/${post?.urlCode}`)
                            }
                          >
                            View Post
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="dashboard-box">
                      <h3 className="title is-5 is-thin">No Latest content</h3>
                    </div>
                  )}
                  {/*Dashboard box*/}
                  <div className="dashboard-box">
                    <h3 className="title is-5 is-thin">Latest subscribers</h3>
                    <p className="small">{analytics?.lastDayRecord}</p>
                    <div className="box-content">
                      <div className="box-subscribers">
                        {subs?.map((i, k) => {
                          return (
                            <div className="box-subscriber" key={k}>
                              <img
                                className="subscriber-avatar"
                                src={i.subscriber.imageUrl}
                              />
                              <div className="subscriber-meta">
                                <span className="meta-title">
                                  {i.subscriber.nickName}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/*Dashboard column*/}
                <div className="column is-4">
                  {/*Dashboard box*/}
                  <div className="dashboard-box">
                    <h3 className="title is-5 is-thin">Analytics</h3>
                    <div className="stat-block">
                      <span className="stat-title">Current subscribers</span>
                      <span className="stat-value">
                        {analytics?.currentSubscribers}
                      </span>
                      <span className="stat-subtitle">
                        <span className="positive">
                          +{analytics?.subscriptions}
                        </span>{" "}
                        {analytics?.lastDayRecord}
                      </span>
                    </div>
                    <div className="box-content">
                      <div className="box-lines">
                        <h4 className="topheading">Highlights</h4>
                        <p className="subheading mb-2">
                          {analytics?.lastDayRecord}
                        </p>
                        <div className="box-line">
                          <span className="left">Post views</span>
                          <span className="right">
                            <span className="positive">
                              {analytics?.postViews}
                            </span>
                          </span>
                        </div>
                        <div className="box-line">
                          <span className="left">Likes</span>
                          <span className="right">
                            <span className="negative">{analytics?.likes}</span>
                          </span>
                        </div>
                        <div className="box-line">
                          <span className="left">Comments</span>
                          <span className="right">
                            <span>{analytics?.Comments}</span>
                          </span>
                        </div>
                        <div className="box-line">
                          <span className="left">Subscriptions</span>
                          <span className="right">
                            <span>{analytics?.subscriptions}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Dashboard box*/}
                  <div className="dashboard-box">
                    <h3 className="title is-5 is-thin">Latest comments</h3>
                    <div className="box-content">
                      <div className="box-comments">
                        {comments?.map((i, k) => {
                          return (
                            <div className="box-comment" key={k}>
                              <img
                                className="comment-avatar"
                                src={i.userProfile}
                              />
                              <div className="comment-meta">
                                <span className="meta-title">
                                  {i.nickName} •{" "}
                                  {moment(i.created_at).fromNow()}
                                </span>
                                <span className="meta-content">
                                  {i.comment}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/*Dashboard column*/}
                <div className="column is-4">
                  <div className="dashboard-box is-compact">
                    <div className="help-block">
                      <h4>Help center</h4>
                      <p>
                        Having trouble? Please search our&nbsp;
                        <Link className="standard-link" to="/help">
                          FAQ
                        </Link>{" "}
                        for a quick answer to your problem.
                      </p>
                    </div>
                  </div>
                  {/*Box*/}
                  <div className="dashboard-box is-compact">
                    <div className="help-block">
                      <h4>Support</h4>
                      <p>
                        Our Help Center didn't help? Please contact our&nbsp;
                        <a
                          className="standard-link"
                          href="mailto:support@tribeofbabes.com"
                        >
                          support@tribeofbabes.com
                        </a>{" "}
                        hotline.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <CircularProgress size="30px" style={{ color: "#ca1444" }} />
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
