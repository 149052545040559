import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import { X } from "react-feather";
import ProfilePosts from "../Elements/ProfilePosts";
import MasonaryProfilePosts from "../Elements/MasonaryProfilePosts";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import Footer from "./Footer";
import Button from "../Elements/Button";

const UserProfile = (props) => {
  const [share, setShare] = React.useState(false);
  const [posts, setPosts] = React.useState([]);
  const [latest, setLatest] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [subLoading, setSubLoading] = React.useState(false);
  const [followLoading, setFollowLoading] = React.useState(false);
  const [user, setUser] = React.useState(undefined);
  const [sub, setSub] = React.useState(undefined);
  const [open, setOpen] = React.useState(undefined);
  const [suggested, setSuggested] = React.useState([]);

  const getUser = async () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/profile/${props.match.params.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    setUser(response.data.response.data);
    getSub(response.data.response.data);
    getPosts(response.data.response.data);
    setLoading(true);
  };

  const getPosts = () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/${user?.id}/posts`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPosts(response.data.response.data);
        setLatest(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSub = (values) => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/subscription/${values.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
    axios(config).then((response) => {
      setSub(response.data.response.data);
    });
  };

  const getSuggested = () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/suggested",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setSuggested(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (props.token) {
      getUser();
      getSuggested();
    }
  }, [props.token]);

  React.useEffect(() => {
    if (props.token) {
      getPosts();
    }
  }, [latest, user]);

  const subcribeHandler = async () => {
    setSubLoading(true);
    try {
      var data = new FormData();
      data.append("subscribeUserId", user.id);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/subscribe/user",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      await axios(config);
      props.updateHandler(true);
      setSubLoading(false);
      setLatest(true);
      getUser();
    } catch (e) {
      toast.error(e.response.data.response.message);
      setSubLoading(false);
      getUser();
      props.updateHandler(true);
    }
  };

  const unsubcribeHandler = async () => {
    setSubLoading(true);
    try {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("subscribeUserId", user.id);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/unsubscribe/user",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      getUser();
      props.updateHandler(true);
      toast.success(response.data.response.message);
      setSubLoading(false);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setSubLoading(false);
    }
  };

  const followHandler = async (id) => {
    setFollowLoading(true);
    try {
      var config = {
        method: "get",
        url: `https://admin-staging.tribeofbabes.com/api/v1/user/${id}/follow`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      };

      await axios(config);
      props.updateHandler(true);
      setFollowLoading(false);
      setLatest(true);
      getUser();
    } catch (e) {
      toast.error(e.response.data.response.message);
      setFollowLoading(false);
      getUser();
      props.updateHandler(true);
    }
  };

  const unfollowHandler = async (id) => {
    setFollowLoading(true);
    try {
      var config = {
        method: "get",
        url: `https://admin-staging.tribeofbabes.com/api/v1/user/${id}/unfollow`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      };

      await axios(config);
      props.updateHandler(true);
      setFollowLoading(false);
      setLatest(true);
      getUser();
    } catch (e) {
      toast.error(e.response.data.response.message);
      setFollowLoading(false);
      getUser();
      props.updateHandler(true);
    }
  };

  console.log({ user });
  console.log(sub);
  return (
    <>
      <div className={`app-overlay is-sidebar-v1`}></div>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        {loading ? (
          <div
            id="main-feed"
            className="container sidebar-boxed"
            data-page-title="Timeline"
          >
            <div className="columns is-multiline no-margin">
              {/* Left side column */}
              <div className="column is-paddingless">
                {/* Timeline Header */}
                {/* html/partials/pages/profile/timeline/timeline-header.html */}
                <div className="cover-bg">
                  <img
                    className="cover-image"
                    src={
                      user?.bannerImageUrl
                        ? user.bannerImageUrl
                        : `https://via.placeholder.com/1600x460`
                    }
                  />
                  <div className="tb-user-profile__avatar avatar">
                    <img
                      id="user-avatar"
                      className="avatar-image"
                      src={
                        user?.imageUrl
                          ? user.imageUrl
                          : `https://via.placeholder.com/300x300`
                      }
                    />
                    {user?.creatorType === "Model" && (
                      <div
                        className="tb-user-profile__follow"
                        onClick={() =>
                          user.isFollowed
                            ? unfollowHandler(user?.id)
                            : followHandler(user?.id)
                        }
                      >
                        {user.isFollowed ? "-" : "+"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-subheader">
                  <div className="subheader-middle">
                    <h2>{user?.nickName}</h2>
                    <p>{user?.bio}</p>
                    {user?.creatorType === "Model" ? (
                      <span>
                        <Button
                          style={{
                            margin: "10px auto",
                            maxWidth: "350px",
                            textAlign: "center",
                            padding: "0 32px",
                          }}
                          className="button is-solid primary-button raised is-rounded is-fullwidth"
                          disabled={!loading}
                          type="submit"
                          onClick={() =>
                            user.isSubscribed
                              ? unsubcribeHandler()
                              : subcribeHandler(sub?.price)
                          }
                        >
                          {subLoading ? (
                            <CircularProgress
                              size="1rem"
                              style={{ color: "#fff" }}
                            />
                          ) : user?.isSubscribed ? (
                            "Unsubscribe"
                          ) : (
                            `Subscribe ${
                              sub
                                ? `${sub?.price} Tokens (${sub?.discountPercentage}% off )`
                                : ""
                            }`
                          )}
                        </Button>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div id="activity-feed" className="view-wrap true-dom">
              <div className="columns">
                {/* Middle column */}
                {posts.length ? (
                  <div className="column is-8">
                    {/* Posts */}
                    {user.isSubscribed ? (
                      <>
                        <MasonaryProfilePosts
                          share={setShare}
                          posts={posts}
                          navigate={props.history}
                          token={props.token}
                          setLatest={setLatest}
                        />
                        <div className="load-more-wrap has-text-centered">
                          <a className="load-more-button">Load More</a>
                        </div>
                      </>
                    ) : posts.find((find) => find.postType === "Teaser") ? (
                      <MasonaryProfilePosts
                        share={setShare}
                        navigate={props.history}
                        posts={posts.filter(
                          (filter) => filter.postType === "Teaser"
                        )}
                        token={props.token}
                        setLatest={setLatest}
                      />
                    ) : (
                      <h3 style={{ textAlign: "center" }}>
                        Subcribe To See Posts
                      </h3>
                    )}
                  </div>
                ) : (
                  <div className="column is-8">
                    <h3 style={{ textAlign: "center" }}>0 Posts</h3>
                  </div>
                )}
                <div className="column is-4">
                  {/* Stories widget */}
                  {/* /partials/widgets/stories-widget.html */}
                  {/* Birthday widget */}
                  {/* /partials/widgets/birthday-widget.html */}
                  {/* Suggested friends widget */}
                  {/* /partials/widgets/suggested-friends-1-widget.html */}
                  <div className="card">
                    <div className="card-heading is-bordered">
                      <h4>Suggested Friends</h4>
                    </div>
                    <div className="card-body no-padding">
                      {/* Suggested friend */}
                      {suggested.map((i, k) => {
                        return (
                          <div
                            className="add-friend-block transition-block"
                            key={k}
                          >
                            <div style={{ display: "flex" }}>
                              <img src={i.imageUrl} />
                              <div className="page-meta">
                                <span>{i.nickName}</span>
                                <span>Creator</span>
                              </div>
                            </div>
                            <button
                              className="button is-solid primary-button raised is-rounded"
                              onClick={() =>
                                props.history.push(`/user/${i.userName}`)
                              }
                            >
                              View Profile
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* New job widget */}
                  {/* /partials/widgets/new-job-widget.html */}
                </div>
                {/* /Right side column */}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <CircularProgress size="30px" style={{ color: "#ca1444" }} />
          </div>
        )}

        <div
          className={`modal is-xsmall has-light-bg share-modal ${
            open ? "is-active" : ""
          }`}
        >
          <div className="modal-background">
            <div className="modal-content tip-modal">
              <div className="card">
                <div className="card-heading">
                  <h3>Tip</h3>
                  <div className="close-wrap">
                    <span
                      className="close-modal"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <X />
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ marginBottom: "10px" }}>
                      Are You Sure To Subcribe User for{" "}
                      {`${(sub?.discountPercentage / 100) * sub?.price}`} Token
                    </h5>
                    <button
                      className="button is-solid primary-button raised is-rounded"
                      disabled={loading}
                      style={{ marginRight: "10px" }}
                    >
                      {loading ? (
                        <CircularProgress
                          size="1rem"
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        "Confirm"
                      )}
                    </button>
                    <button
                      className="button is-solid primary-button raised is-rounded"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    updateHandler: (value) => dispatch({ type: "UPDATE", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
