import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Elements/Button";

const CreateAccountBanner = () => {
  return (
    <div className="tb-create-account-banner">
      <div className="tb-create-account-banner__wrapper">
        <div className="tb-create-account-banner__button container">
          <div className="tb-create-account-banner__icon" />
          <Link to="/login">
            <Button>Create your Account</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountBanner;
