import React from "react";
import {
  Calendar,
  DollarSign,
  Facebook,
  Globe,
  Instagram,
  Mail,
  MapPin,
  Menu,
  Percent,
  Twitter,
  User,
  Youtube,
} from "react-feather";
import { ReactComponent as UserGuide } from "../images/1.svg";
import { ReactComponent as Bank } from "../images/Bank.svg";
import { ReactComponent as Noti } from "../images/Noti.svg";
import { ReactComponent as Sub } from "../images/Subs.svg";
import Header from "./Header";
import Sidebar from "./Sidebar";
import moment from "moment";
import { connect } from "react-redux";
import Slider from "react-slick";
import "flatpickr/dist/themes/dark.css";
import { ReactComponent as Pin } from "../images/Pin.svg";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const postDetail = yup.object().shape({
  postType: yup.string().required("Post Type is required!"),
  categoryId: yup.string().required("Category is required"),
  detail: yup.string().nullable(),
});

const bank = yup.object().shape({
  bankName: yup.string().required("bank name required!"),
  bankNumber: yup.string().required("bank number required"),
  bankNumber: yup.string().required("bank number required"),
  bankCountryName: yup.string().required("bank country name required"),
  bankRoutingNumber: yup.string().required("bank routing number required"),
  w9Form: yup
    .mixed()
    .required("W9 Form is Required")
    .test("FILE_SIZE", "Uploaded file is too big.", (value) =>
      value ? !value || (value && value.size <= 52428800) : ""
    )
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) =>
      value ? value.type === "application/pdf" : ""
    ),
});

const notification = yup.object().shape({
  backupEmail: yup.bool(),
  comment: yup.bool(),
  like: yup.bool(),
  follow: yup.bool(),
  liveStream: yup.bool(),
});

const subs = yup.object().shape({
  price: yup.number().required(),
  discount: yup.number(),
});

const borderless = yup.object().shape({
  id: yup.string().required(),
});

const PostSingleEdit = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [post, setPost] = React.useState({});
  const [postId, setPostId] = React.useState("");
  const [token, setToken] = React.useState("");
  const [cat, setCat] = React.useState([]);
  const [mediaSlider, setMediaSlider] = React.useState({});
  const [currentPostMedia, setCurrentPostMedia] = React.useState(false);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => {},
    beforeChange: (current, next) => setCurrentPostMedia(post.postMedias[next]),
  };

  const getPost = async () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${props.match?.params?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    console.log(response.data.response.data);
    setPost(response.data.response.data);
    setPostId(response.data.response.data.id);
  };

  const getCat = () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/categories",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setCat(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removePostMedia = () => {
    let postMediaId = false;

    if (!currentPostMedia) {
      if (post.postMedias?.length > 0) {
        setCurrentPostMedia(post.postMedias[0]);
        postMediaId = post.postMedias[0]?.id;
      }
    } else {
      postMediaId = currentPostMedia?.id;
    }

    if (postMediaId) {
      axios({
        method: "delete",
        url: `https://admin-staging.tribeofbabes.com/api/v1/post-media/remove/${postMediaId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
        .then(function (response) {
          console.log(response);
          getPost();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const postHandler = async (values) => {
    setLoading(true);
    // var x = picture.map((val) => {
    //   return val.img;
    // });
    var data = new FormData();
    data.append("postType", values.postType);
    data.append("detail", values.desc);
    values.postType === "Scheduled" &&
      data.append(
        "scheduleDateTime",
        new moment(values.time, "YYYY-MM-DD HH:mm")
          .utc()
          .format("YYYY-MM-DD HH:mm")
      );
    data.append("categoryId", values.category);
    // for (let i = 0; i < x.length; i++) {
    //   data.append('post_media[' + i + ']', x[i]);
    // }

    var config = {
      method: "post",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${postId}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        //setPercentage(percentCompleted);
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    if (props.token) {
      getPost();
      getCat();
    }
  }, [props.token]);

  const getToken = React.useCallback(() => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/generate-jwt",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setToken(response.data.response.data.jwt);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props]);

  React.useEffect(() => {
    if (props.token) {
      getToken();
    }
  }, [props]);

  React.useEffect(() => {
    if (bank) {
      console.log(bank);
      var data = new FormData();
      data.append("contactId", bank.externalContactId);
      data.append("status", bank.status);
      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };
      axios(config).then(() => {
        toast.success("Bank Details Updated");
      });
    }
  }, [bank]);

  const handleIdhandler = React.useCallback(
    (values, setSubmitting) => {
      var data = new FormData();
      data.append("contactId", values.id);
      data.append("status", "success");
      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };
      axios(config).then(() => {
        toast.success("Bank Details Updated");
        setSubmitting(false);
      });
    },
    [props]
  );

  return (
    <>
      <Sidebar props={props} />
      {props.user ? (
        <div
          className={`view-wrapper is-sidebar-v1 ${
            props.sidebar ? "is-fold" : ""
          }`}
        >
          <div
            id="settings"
            className="container sidebar-boxed"
            data-open-sidebar
            data-page-title="Settings"
          >
            <Header navigate={props.history} />
            <div className="settings-wrapper is-full">
              {/* /partials/settings/sections/general-settings.html */}
              <div id="general-settings" className="settings-section is-active">
                <div className="settings-panel">
                  <div className="title-wrap">
                    <a className="mobile-sidebar-trigger">
                      <Menu />
                    </a>
                    <h2>Details</h2>
                  </div>
                  <div className="settings-form-wrapper">
                    {post.id ? (
                      <Formik
                        onSubmit={(values) => {
                          postHandler(values);
                        }}
                        initialValues={{
                          postType: post.postType ? post.postType : "",
                          categoryId: post.categoryId ? post.categoryId : "",
                          detail: post.detail ? post.detail : "",
                        }}
                        validationSchema={postDetail}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                        }) => (
                          <Form>
                            <div className="settings-form mainForm">
                              <div className="columns is-multiline">
                                <div className="column is-6">
                                  {/* Post Type*/}
                                  <React.Fragment>
                                    <div className="field field-group">
                                      <label>Post Type</label>
                                      <div className="control has-icon">
                                        {/* <input type="text" className="input is-fade" placeholder="Post Type" name="email" id="email" value={values.email} onChange={handleChange} onBlur={handleBlur} disabled /> */}
                                        <select
                                          className="input is-fade"
                                          name="postType"
                                          value={values.postType}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          id="postType"
                                        >
                                          <option value="Published">
                                            Published
                                          </option>
                                          <option value="Teaser">Teaser</option>
                                          <option value="Archieved">
                                            Archieved
                                          </option>
                                          <option value="Scheduled">
                                            Scheduled
                                          </option>
                                        </select>
                                        <div className="form-icon">
                                          <Mail />
                                        </div>
                                      </div>
                                    </div>
                                    {errors.postType &&
                                    touched.postType &&
                                    errors.postType ? (
                                      <div className="dangerText">
                                        {errors.postType}
                                      </div>
                                    ) : null}
                                  </React.Fragment>
                                </div>

                                <div className="column is-6">
                                  {/* last name */}
                                  <React.Fragment>
                                    <div className="field field-group">
                                      <label>Category</label>
                                      <div className="control has-icon">
                                        {/* <input type="text" className="input is-fade" placeholder="last name" name="lastName" id="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur} /> */}
                                        <select
                                          className="input is-fade"
                                          name="categoryId"
                                          value={values.categoryId}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          id="categoryId"
                                        >
                                          <option value="">Uncategory</option>
                                          <option value="">News</option>
                                        </select>
                                        <div className="form-icon">
                                          <User />
                                        </div>
                                      </div>
                                    </div>
                                    {errors.categoryId &&
                                    touched.categoryId &&
                                    errors.categoryId ? (
                                      <div className="dangerText">
                                        {errors.categoryId}
                                      </div>
                                    ) : null}
                                  </React.Fragment>
                                </div>

                                <div className="column is-12">
                                  <React.Fragment>
                                    <div className="field field-group">
                                      <label>Description</label>
                                      <div className="control has-icon">
                                        {/* <input type="text" className="input is-fade" placeholder="backup email" name="backupEmail" id="backupEmail" value={values.backupEmail} onChange={handleChange} onBlur={handleBlur} /> */}
                                        <textarea
                                          className="input is-fade"
                                          placeholder="Message"
                                          name="detail"
                                          id="detail"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.detail}
                                        ></textarea>
                                        <div className="form-icon">
                                          <Mail />
                                        </div>
                                      </div>
                                    </div>
                                    {errors.detail &&
                                    touched.detail &&
                                    errors.detail ? (
                                      <div className="dangerText">
                                        {errors.detail}
                                      </div>
                                    ) : null}
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {post.id ? (
              <div className="settings-wrapper is-full">
                {/* /partials/settings/sections/general-settings.html */}
                <div
                  id="general-settings"
                  className="settings-section is-active"
                >
                  <div className="settings-panel">
                    <div className="title-wrap">
                      <a className="mobile-sidebar-trigger">
                        <Menu />
                      </a>
                      <div className="headingButton mb-5">
                        <h2>Media</h2>
                        <div className="headingButtonInner">
                          <button className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                          <button className="" onClick={removePostMedia}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>
                          <button
                            className=""
                            onClick={() => {
                              let f = mediaSlider?.slickPrev
                                ? mediaSlider?.slickPrev()
                                : "";
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 19l-7-7 7-7"
                              />
                            </svg>
                          </button>
                          <button
                            className=""
                            onClick={() => {
                              let f = mediaSlider?.slickNext
                                ? mediaSlider?.slickNext()
                                : "";
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <Slider
                        ref={(slider) => setMediaSlider(slider)}
                        {...settings}
                      >
                        {post.postMedias.map((media, mediaKey) => {
                          return (
                            <React.Fragment key={mediaKey}>
                              {media.type === "image" ? (
                                <div className="post-media">
                                  <img src={media.url} />
                                </div>
                              ) : (
                                <div className="post-media">
                                  <video controls>
                                    <source src={media.url} />
                                  </video>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <Footer />
        </div>
      ) : (
        <CircularProgress size="1rem" />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    userHandler: (value) => dispatch({ type: "USER", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostSingleEdit);
