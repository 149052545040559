import React from "react";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";

const TermsAndCondition = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div style={{ marginTop: "4rem", height: "80vh" }}>
          <h1>Terms And Conditions</h1>
          <iframe
            title="Privacy"
            src="https://app.termly.io/document/terms-of-use-for-website/1dfc3e5a-c109-4945-86f5-d685354e5fd4"
            style={{ width: "100%", height: "calc(100% - 4rem)" }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default TermsAndCondition;
