import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const SigninLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="tb-signin-layout">
        <div className="tb-signin-layout__left">
          <div className="tb-signin-layout__left-wrapper" />
        </div>

        <div className="tb-signin-layout__right">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default SigninLayout;
