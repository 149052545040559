import React from "react";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import Header from "./Header";
import { DollarSign } from "react-feather";
import { ReactComponent as Album } from "../images/wallet.svg";
import * as yup from "yup";
import { Formik, Form } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import Footer from "./Footer";

const wallet = yup.object().shape({
  amount: yup.number().required(),
});

const complaint = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  url: yup.string().url().required(),
  complain: yup.string().required(),
});

const Wallet = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [transactions, setTransactions] = React.useState(false);

  const walletHandler = (values, setSubmitting) => {
    var data = new FormData();
    data.append("amount", values.amount);

    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/ccbill/url?amount=${values.amount}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.open(response.data.response.data.ccbil_url, "_self");
        toast.success("Requesting Tokens");
        setSubmitting(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.response.message);
        setSubmitting(false);
      });
  };

  const userProfile = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTransactions = () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/transactions?transactionType=All",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setTransactions(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (props.token) {
      getTransactions();
      userProfile();
    }
  }, [props.token]);

  function getFullName(params) {
    return `${params.row.user.nickName}`;
  }

  function getTime(params) {
    return `${moment(params.row.completedDatetime).format("YY-MM-DD, h:mm a")}`;
  }

  const columns = [
    { field: "id", headerName: "TID", width: 100 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "transactionType", headerName: "Type", width: 100 },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      editable: true,
      valueGetter: getFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "completedDatetime",
      headerName: "Timestamp",
      width: 160,
      editable: true,
      valueGetter: getTime,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
  ];

  const complaintHandler = React.useCallback((values) => {
    setLoading(true);
    var data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("url", values.url);
    data.append("complain", values.complain);

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/complaint",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        userProfile();
        toast.success(response.data.response.message);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        {/* Container */}
        <div
          id="shop-page"
          className="container sidebar-boxed"
          data-open-sidebar
          data-page-title="Payment"
        >
          {/*Wrapper*/}
          <div className="shop-wrapper">
            {/*Container*/}
            <div id="payment-container" className="checkout-container">
              {/*Left Side*/}
              <div className="left">
                <div className="left-header">
                  <div className="header-inner">
                    <Album />
                    <div className="separator"></div>
                    <div className="title-wrap">
                      <h3 className="payment-title">
                        Tokens: {props.user?.walletAmount}
                      </h3>
                    </div>
                  </div>
                  <Formik
                    initialValues={{ amount: "" }}
                    validationSchema={wallet}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                      walletHandler(values, setSubmitting);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      isSubmitting,
                    }) => (
                      <Form>
                        <div className="header-coupon">
                          <div className="field field-group is-autocomplete">
                            <label>Amount</label>
                            <div className="control has-icon">
                              <input
                                type="number"
                                name="amount"
                                id="amount"
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="input is-fade"
                                placeholder="200"
                              />
                              {errors.amount &&
                              touched.amount &&
                              errors.amount ? (
                                <div className="dangerText">
                                  {errors.amount}
                                </div>
                              ) : null}
                              <div className="form-icon">
                                <DollarSign />
                              </div>
                            </div>
                          </div>
                          <div className="column is-12">
                            <div className="buttons">
                              <button
                                className="button is-solid accent-button form-button"
                                type="submit"
                              >
                                {isSubmitting ? (
                                  <CircularProgress
                                    size="1rem"
                                    style={{ color: "#fff" }}
                                  />
                                ) : (
                                  "Confirm"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="left">
                <div className="left-header">
                  <div className="header-inner">
                    <div className="title-wrap">
                      <h3 className="payment-title">Complaint Form</h3>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      url: "",
                      complain: "",
                    }}
                    validationSchema={complaint}
                    onSubmit={(values, { resetForm }) => {
                      complaintHandler(values);
                      resetForm();
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                    }) => (
                      <Form>
                        <div className="header-coupon">
                          <div className="field field-group is-autocomplete">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="input is-fade"
                              placeholder="Name"
                            />
                            {errors.name && touched.name && errors.name ? (
                              <div className="dangerText">{errors.name}</div>
                            ) : null}
                          </div>
                          <div className="field field-group is-autocomplete">
                            <label>Email</label>
                            <input
                              type="text"
                              name="email"
                              id="name"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="input is-fade"
                              placeholder="Email"
                            />
                            {errors.email && touched.email && errors.email ? (
                              <div className="dangerText">{errors.email}</div>
                            ) : null}
                          </div>
                          <div className="field field-group is-autocomplete">
                            <label>url</label>
                            <input
                              type="text"
                              name="url"
                              id="url"
                              value={values.url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="input is-fade"
                              placeholder="Url"
                            />
                            {errors.url && touched.url && errors.url ? (
                              <div className="dangerText">{errors.url}</div>
                            ) : null}
                          </div>
                          <div className="field field-group is-autocomplete">
                            <label>complain</label>
                            <textarea
                              type="text"
                              name="complain"
                              id="complain"
                              value={values.complain}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="input is-fade"
                              placeholder="Comaplaint"
                            />
                          </div>
                          <div className="column is-12">
                            <div className="buttons">
                              <button
                                className="button is-solid accent-button form-button"
                                type="submit"
                              >
                                {loading ? (
                                  <CircularProgress
                                    size="1rem"
                                    style={{ color: "#fff" }}
                                  />
                                ) : (
                                  "Confirm"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>
                          We are doing our best so you will enjoy the content
                          provided. If, in any case, you have a complaint,
                          please let us know. All complaints will be reviewed
                          and resolved within seven business days. Any content
                          that is deemed inappropriate or illegal will be
                          removed from our platform. Creators who post illegal
                          content or violate our terms of service will also
                          receive a ban.
                        </p>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <a
                            href="https://community.tribeofbabes.com/terms_and_condition"
                            target="_blank"
                          >
                            Terms And Condition
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          <a
                            href="https://community.tribeofbabes.com/privacy_policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="columns is-12 mobile-col">
              <div className="card transactions">
                <div style={{ height: "400px" }}>
                  <DataGrid
                    rows={transactions}
                    columns={columns}
                    pageSize={6}
                    rowsPerPageOptions={[5]}
                    disableColumnMenu
                  />
                </div>
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-6">
                <div className="card" style={{ padding: "20px" }}>
                  <p>
                    <b>18 U.S.C. 2257 Compliance</b>
                    <br />
                    18 U.S.C. 2257 Record-Keeping Requirements Compliance
                    Statement
                    <br />
                    In compliance with the Federal Labeling and Record-Keeping
                    Law (also known as 18 U.S.C. 2257), all models located
                    within our domain were 18 years of age or older during the
                    time of photography. All models' proof of age is held by the
                    custodian of records, which is listed below, organized by
                    producer. All content and images are in full compliance with
                    the requirements of 18 U.S.C. 2257 and associated
                    regulations.
                    <br />
                    Cinema Studio, LLC
                    <br />
                    5505 E Santa Ana Canyon Road # 17722, Anaheim, CA 92817 US
                    <br />
                    Administrator:{" "}
                    <a href="mailto:support@tribeofbabes.com">
                      support@tribeofbabes.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="column is-6">
                <div className="card" style={{ padding: "20px" }}>
                  <p>
                    <b>Refund Policy</b>
                    <br />
                    Refunds will only be given in exceptional circumstances.
                    Refunds for purchases or recurring charges may be requested
                    by contacting customer support.
                    <br />
                    Tribe of Babes reserves the right to grant a refund or a
                    credit applicable to purchases to the Site at its
                    discretion. The decision to refund a charge does not imply
                    the obligation to issue additional future refunds. Should a
                    refund be issued by Tribe of Babes. for any reason, it will
                    be credited solely to the payment method used in the
                    original transaction. Tribe of Babes will not issue refunds
                    by cash, check, or to another payment mechanism.
                    <br />
                    For questions, please contact us at{" "}
                    <a href="mailto:support@tribeofbabes.com">
                      support@tribeofbabes.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="column is-12">
                <div className="card" style={{ padding: "20px" }}>
                  <p>
                    <b>Cancellation Policy</b>
                    <br />
                    If you'd like to cancel your subscription and not to be
                    billed again,
                    <br />
                    please email us at support@tribeofbabes.com or contact
                    CCBill at (https://support.ccbill.com/) via live chat, phone
                    1.888.596.9279 email at{" "}
                    <a href="mailto:support@ccbill.com">support@ccbill.com.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userHandler: (value) => dispatch({ type: "USER", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
