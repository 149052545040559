import React from "react";
import {
  Calendar,
  DollarSign,
  Facebook,
  Globe,
  Instagram,
  Mail,
  MapPin,
  Menu,
  Percent,
  Twitter,
  User,
  Youtube,
} from "react-feather";
import { ReactComponent as UserGuide } from "../images/1.svg";
import { ReactComponent as Bank } from "../images/Bank.svg";
import { ReactComponent as Noti } from "../images/Noti.svg";
import { ReactComponent as Sub } from "../images/Subs.svg";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import "flatpickr/dist/themes/dark.css";
import { ReactComponent as Pin } from "../images/Pin.svg";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const general = yup.object().shape({
  firstName: yup.string().required("first name required!"),
  lastName: yup.string().required("last name required!"),
  nickName: yup.string().required("nick name required!"),
  email: yup.string().required("email name required!"),
  bio: yup.string().required("bio name required!"),
  backupEmail: yup.string().required("backup email required!").nullable(),
  phoneNumber: yup.string().required("phone number required!").nullable(),
  address: yup.string().required("address required!").nullable(),
  city: yup.string().required("city required!").nullable(),
  state: yup.string().required("state required!").nullable(),
  zipCode: yup.string().required("zip code required!").nullable(),
  country: yup.string().required("country required!").nullable(),
  dateOfBirth: yup.string().required("date of birth required!").nullable(),
  facebook: yup.string().url().nullable(),
  instagram: yup.string().url().nullable(),
  twitter: yup.string().url().nullable(),
  pinterest: yup.string().url().nullable(),
  youtube: yup.string().url().nullable(),
});
const bank = yup.object().shape({
  bankName: yup.string().required("bank name required!"),
  bankNumber: yup.string().required("bank number required"),
  bankNumber: yup.string().required("bank number required"),
  bankCountryName: yup.string().required("bank country name required"),
  bankRoutingNumber: yup.string().required("bank routing number required"),
  w9Form: yup
    .mixed()
    .required("W9 Form is Required")
    .test("FILE_SIZE", "Uploaded file is too big.", (value) =>
      value ? !value || (value && value.size <= 52428800) : ""
    )
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) =>
      value ? value.type === "application/pdf" : ""
    ),
});

const notification = yup.object().shape({
  backupEmail: yup.bool(),
  comment: yup.bool(),
  like: yup.bool(),
  follow: yup.bool(),
  liveStream: yup.bool(),
});

const subs = yup.object().shape({
  price: yup.number().required(),
  discount: yup.number(),
});

const borderless = yup.object().shape({
  id: yup.string().required(),
});

const Transanctions = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [, setBankDetails] = React.useState(0);
  const [token, setToken] = React.useState("");
  const [bank, setbank] = React.useState();
  const [subTransanctions, setSubTransanctions] = React.useState([]);
  const [tipTransanctions, setTipTransanctions] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date());

  function getFullName(params) {
    return `${params.row.user.nickName}`;
  }

  function getTime(params) {
    return `${moment(params.row.completedDatetime).format("YY-MM-DD, h:mm a")}`;
  }

  const columns = [
    { field: "id", headerName: "TID", width: 100 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "transactionType", headerName: "Type", width: 100 },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      editable: true,
      valueGetter: getFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "completedDatetime",
      headerName: "Timestamp",
      width: 160,
      editable: true,
      valueGetter: getTime,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
  ];

  const generalHandler = async (values) => {
    setLoading(true);
    try {
      var data = new FormData();
      data.append("firstName", values.firstName);
      data.append("lastName", values.lastName);
      data.append("backupEmail", values.backupEmail);
      data.append("phoneNumber", values.phoneNumber);
      data.append("dateOfBirth", values.dateOfBirth);
      data.append("city", values.city);
      data.append("country", values.country);
      data.append("zipcode", values.zipCode);
      data.append("state", values.state);
      data.append("address", values.address);
      data.append("facebookUrl", values.facebook);
      data.append("instagramUrl", values.instagram);
      data.append("twitterUrl", values.twitter);
      data.append("youtubeUrl", values.youtube);
      data.append("pinterestUrl", values.pinterest);
      data.append("nickName", values.nickName);
      data.append("bio", values.bio);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile/update",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      await userProfile();
      toast.success(response.data.response.message);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  const userProfile = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const subscriptionHandler = async (values) => {
    setLoading(true);
    try {
      var data = new FormData();
      data.append("price", values.price);
      data.append("discountPercentage", values.discount);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/subscription",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      await axios(config);
      getSubs();
      toast.success("Subscription Updated");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const getSubs = () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/earnings?transactionType=All`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPrice(response.data.response.data.price);
        setDiscount(response.data.response.data.discountPercentage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getSubTransanctions = () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/transactions?transactionType=Subscription`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("transanctions", response);
        setSubTransanctions(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getTipTransanctions = () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/transactions?transactionType=Tip`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("transanctions", response);
        setTipTransanctions(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const notificationHandler = (values) => {
    setLoading(true);
    var data = JSON.stringify({
      isNotificationEnableBackupEmail: values.backupEmail,
      isNotificationEnableComment: values.comment,
      isNotificationEnableLike: values.like,
      isNotificationEnableFollow: values.follow,
      isNotificationEnableLiveStream: values.liveStream,
    });

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/notification/setting",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function () {
        setLoading(false);
        userProfile();
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error.response.data.response.message);
      });
  };
  const bankInfo = async () => {
    setLoading(true);
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setBankDetails(response.data.response.data);
        setLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.response.message);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (props.token) {
      getSubs();
      bankInfo();
      getTransactions();
      getSubTransanctions();
      getTipTransanctions();
    }
  }, [props.token]);

  const getToken = React.useCallback(() => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/generate-jwt",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setToken(response.data.response.data.jwt);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props]);

  React.useEffect(() => {
    if (props.token) {
      getToken();
    }
  }, [props]);

  React.useEffect(() => {
    if (bank) {
      console.log(bank);
      var data = new FormData();
      data.append("contactId", bank.externalContactId);
      data.append("status", bank.status);
      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };
      axios(config).then(() => {
        toast.success("Bank Details Updated");
      });
    }
  }, [bank]);

  const getTransactions = () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/transactions?transactionType=All",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setTransactions(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTransactionsByMonth = (date) => {
    setStartDate(date);
    date = moment(date).format("YYYY-MM");
    console.log(date);
    var config = {
      method: "get",
      url:
        "https://admin-staging.tribeofbabes.com/api/v1/transactions?transactionType=All&month=" +
        date,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setTransactions(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleIdhandler = React.useCallback(
    (values, setSubmitting) => {
      var data = new FormData();
      data.append("contactId", values.id);
      data.append("status", "success");
      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/bank",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };
      axios(config).then(() => {
        toast.success("Bank Details Updated");
        setSubmitting(false);
      });
    },
    [props]
  );

  React.useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data.externalContactId) {
        setbank(e.data);
      }
    });

    return () => {
      window.removeEventListener("message", console.log("working"));
    };
  }, []);

  return (
    <>
      <Sidebar props={props} />
      {props.user ? (
        <div
          className={`view-wrapper is-sidebar-v1 ${
            props.sidebar ? "is-fold" : ""
          }`}
        >
          <div
            id="settings"
            className="container sidebar-boxed"
            data-open-sidebar
            data-page-title="Settings"
          >
            <Header navigate={props.history} />
            <div className="settings-wrapper is-full">
              {/* /partials/settings/sections/general-settings.html */}
              <div id="general-settings" className="settings-section is-active">
                <div className="columns is-12 mobile-col">
                  <div className="card transactions">
                    <div className="monthInput" style={{ height: "60vh" }}>
                      <div className="monthInputInner">
                        <Calendar className="calendarIcon" />
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => getTransactionsByMonth(date)}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                        />
                      </div>
                      <DataGrid
                        rows={transactions}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableColumnMenu
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="settings-panel">
                  <div className="title-wrap">
                    <a className="mobile-sidebar-trigger">
                      <Menu />
                    </a>
                    <h2>Subscriptions</h2>
                  </div>
                  <div className="settings-form-wrapper">
                    <table style={{width:'100%'}}>
                        <tr>
                            <th>From</th>
                            <th>To</th>
                            <th>Amount</th>
                            <th>Discount Percentage</th>
                            <th>Date</th>
                        </tr>
                        {subTransanctions.map((transaction, index) => (
                            <tr key={index}>
                                <td><a href={`/user/${encodeURI(transaction.user?.nickName)}`}>{transaction.user?.nickName}</a></td>
                                <td><a href={`/user/${encodeURI(transaction.sender_user?.nickName)}`}>{transaction.sender_user?.nickName}</a></td>
                                <td>{transaction.amount}</td>
                                <td>{transaction.discountPercentage}</td>
                                <td>{transaction.post?.urlCode}</td>
                                <td>{moment(transaction.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                            </tr>
                        ))}
                    </table>
                  </div>
                </div>
                <div className="settings-panel">
                  <div className="title-wrap">
                    <a className="mobile-sidebar-trigger">
                      <Menu />
                    </a>
                    <h2>Tips</h2>
                  </div>
                  <div className="settings-form-wrapper">
                    <table style={{width:'100%'}}>
                        <tr>
                            <th>From</th>
                            <th>To</th>
                            <th>Amount</th>
                            <th>Post</th>
                            <th>Date</th>
                        </tr>
                        {tipTransanctions.map((transaction, index) => (
                            <tr key={index}>
                                <td><a href={`/user/${encodeURI(transaction.user?.nickName)}`}>{transaction.user?.nickName}</a></td>
                                <td><a href={`/user/${encodeURI(transaction.sender_user?.nickName)}`}>{transaction.sender_user?.nickName}</a></td>
                                <td>{transaction.amount}</td>
                                <td><a href={`/post/${encodeURI(transaction.post?.urlCode)}`}>{transaction.post?.urlCode}</a></td>
                                <td>{moment(transaction.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                            </tr>
                        ))}
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <CircularProgress size="1rem" />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    userHandler: (value) => dispatch({ type: "USER", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Transanctions);
