import React from 'react';
import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import Header from './Header';
import Streaming from '../images/streaming.jpg';
const Streams = (props) => {
 return (
  <>
   <Sidebar props={props} />
   <div className={`view-wrapper is-sidebar-v1 ${props.sidebar ? 'is-fold' : ''}`}>
    <Header navigate={props.history} />
    <div className="container sidebar-boxed">
     <div className="streams">
      <div className="column is-4">
       <div className="card" style={{ padding: '1em' }}>
        <img src={Streaming} />
        <span>live</span>
       </div>
      </div>
      <div className="column is-4">
       <div className="card" style={{ padding: '1em' }}>
        <img src={Streaming} />
        <span>live</span>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};
const mapStateToProps = (state) => {
 return {
  sidebar: state.StreamApp.sidebar,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  sidebarHandler: (value) => dispatch({ type: 'SIDEBAR', value: value }),
 };
};
export default connect(mapStateToProps, mapDispatchToProps)(Streams);
