import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { connect } from "react-redux";
import AgoraRTC from "agora-rtc-sdk";
import {
  Activity,
  Heart,
  Eye,
  Link2,
  X,
  Camera,
  Mic,
  RotateCw,
} from "react-feather";
import axios from "axios";
import Pubnub from "pubnub";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Footer from "./Footer";

const Tip = yup.object().shape({
  tip: yup.number().required(),
});
// Agora Recording Token
const id = (Math.floor(Math.random() * 1000000) + 10000).toString();
var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  params: {},
};
var option = {
  appID: "6dcf0eff9b4a4bb09a963c2efcb55311",
  channel: "",
  uid: null,
};

const LiveStream = (props) => {
  const pubnub = new Pubnub({
    publishKey: "pub-c-13f5ff65-2098-430e-abbb-491891f50057",
    subscribeKey: "sub-c-244b83d6-4171-11eb-ae10-b69578166507",
    uuid: props.user?.id,
  });

  const [audio, setAudio] = React.useState(true);
  const [video, setVideo] = React.useState(true);
  const [desc, setDesc] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [liveToken, setLiveToken] = React.useState("");
  const [live, setLive] = React.useState(false);
  const [postId, setPostId] = React.useState("");
  const [resourceID, SetResourceID] = React.useState("");
  const [sid, setSid] = React.useState("");
  const [loading, setLoading] = React.useState("");
  const [action, setAction] = React.useState("chat");
  const [tipAmount, setTipAmount] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [tip, setTip] = React.useState(false);
  const [camera, setCamera] = React.useState([]);
  const [flip, setFlip] = React.useState(true);
  const [postCode, setPostCode] = React.useState(true);
  const [videoExists, setVideoExists] = React.useState(false);

  //  Client Init
  const initClient = () => {
    rtc.client.init(
      option.appID,
      function () {
        console.log("init success");
      },
      (err) => {
        console.error(err);
      }
    );
  };

  //  Token Call
  const getToken = async () => {
    var data = new FormData();
    data.append("username", props.user?.userName);
    data.append("userid", 0);

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/agora/generateToken",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    const response = await axios(config);
    Stream(response.data.response.data);
    setLiveToken(response.data.response.data.token);
  };
  //  Agora Stream Setup
  const Stream = (stream) => {
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
    initClient();
    rtc.client.setClientRole("host");
    rtc.client.join(
      stream.token ? stream.token : null,
      stream.channelName,
      option.uid ? +option.uid : null,
      (uid) => {
        rtc.params.uid = uid;
      }
    );
    rtc.localStream = AgoraRTC.createStream({
      streamID: rtc.params.uid,
      audio: true,
      video: true,
      screen: false,
      cameraId: "",
    });
    rtc.localStream.setVideoProfile("1080p_5");
    rtc.localStream.init(() => {
      AgoraRTC.getDevices(
        (devices) => {
          const data = devices.filter((item) => {
            return item.kind == "videoinput";
          });
          setCamera(data);
        },
        function (errStr) {
          console.error("Failed to getDevice", errStr);
        }
      );
      rtc.localStream.play("agora_local");
    });
  };
  //  Publish Stream
  const publish = async () => {
    setLoading(true);
    await rtc.client.publish(rtc.localStream);
    var data = new FormData();
    data.append("postType", "Live");
    data.append("liveStreamerToken", liveToken);
    data.append("categoryId", "2");
    data.append("detail", desc);

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/posts",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };
    const response = await axios(config);
    setPostId(response.data.response.data.id);
    setPostCode(response.data.response.data.urlCode);
    setLive(true);
    RecordingStart();
    setLoading(false);
  };

  React.useEffect(() => {
    if (props.token) {
      getToken();
    }
    return () => {
      if (liveToken) {
        rtc.client.leave(function () {
          rtc.localStream.stop();
          rtc.localStream.close();
        });
      }
    };
  }, [props.token]);

  React.useEffect(() => {
    if (props.user?.userName) {
      let newData = undefined;
      pubnub.subscribe({
        channels: [`Live${props.user?.userName}`],
      });
      pubnub.addListener({
        message: (msg) => {
          console.log(msg);
          newData = {
            title: msg.message.title,
            image: msg.message.image,
            description: msg.message.description,
            Time: msg.message.Time,
            Tip: msg.message.Tip,
          };
          setMessages((messages) => [...messages, newData]);
        },
      });
    }

    return () => {
      pubnub.unsubscribeAll();
    };
  }, [props.user]);

  //  pubnub message
  const publishSampleMessage = () => {
    if (message !== "") {
      var publishPayload = {
        channel: `Live${props.user?.userName}`,
        message: {
          title: props.user?.firstName,
          image: props.user?.image,
          description: message,
          Time: moment().format("h:mm"),
        },
      };
      pubnub.publish(publishPayload);
      setMessage("");
    }
  };
  // tip message
  const publishTipMessage = async () => {
    var publishPayload = {
      channel: `chat`,
      message: {
        title: props.user?.firstName,
        image: props.user?.image,
        description: message,
        Time: moment().format("h:mm"),
      },
    };
    pubnub.publish(publishPayload);
    setTipAmount("");
  };
  //  Stop Streaming
  const stopStreaming = async (value) => {
    setLoading(true);
    var data = new FormData();
    data.append("postType", "CloudRecording");
    data.append("liveStreamerLive", value);

    var config = {
      method: "post",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${postId}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    await axios(config);
    pubnub.unsubscribeAll();
    StopLive();
    setLoading(false);
    props.history.push("/profile");
  };
  //  Stop
  const StopLive = () => {
    rtc.client.leave(function () {
      rtc.localStream.stop();
      rtc.localStream.close();
    });
  };
  //  Recording Start
  const RecordingStart = async () => {
    var data = JSON.stringify({
      cname: `${props.user?.userName}`,
      uid: id,
      clientRequest: {},
    });
    var config = {
      method: "post",
      url: "https://api.agora.io/v1/apps/6dcf0eff9b4a4bb09a963c2efcb55311/cloud_recording/acquire",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization:
          "Basic ZjVmZTRiMmUzODQxNDRjMGE4Y2MwNGQ4OWE0ODQyM2Y6ZDdiMmY2NjM0ZTQ1NDZjNmE2NzQ0MGI1MDU4M2UyYjQ=",
      },
      data: data,
    };
    const response = await axios(config);
    const startResponse = await axios({
      method: "post",
      url: `https://api.agora.io/v1/apps/6dcf0eff9b4a4bb09a963c2efcb55311/cloud_recording/resourceid/${response.data.resourceId}/mode/mix/start`,
      headers: {
        "content-type": "application/json;charset=utf-8",
        Authorization:
          "Basic ZjVmZTRiMmUzODQxNDRjMGE4Y2MwNGQ4OWE0ODQyM2Y6ZDdiMmY2NjM0ZTQ1NDZjNmE2NzQ0MGI1MDU4M2UyYjQ=",
      },
      data: JSON.stringify({
        cname: `${props.user?.userName}`,
        uid: id,
        clientRequest: {
          token: `${liveToken}`,
          recordingConfig: {
            channelType: 0,
            streamTypes: 2,
            audioProfile: 1,
            videoStreamType: 0,
            maxIdleTime: 120,
            transcodingConfig: {
              width: 1280,
              height: 720,
              fps: 30,
              bitrate: 1710,
              maxResolutionUid: "1",
              mixedVideoLayout: 1,
            },
          },
          storageConfig: {
            vendor: 1,
            region: 1,
            bucket: `tribeofbabes-live-stream`,
            accessKey: `AKIAX37665Z2BU7EUVOB`,
            secretKey: `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`,
          },
        },
      }),
    });
    SetResourceID(startResponse.data.resourceId);
    setSid(startResponse.data.sid);
  };

  const getOrientation = async () => {
    var orientation =
      window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
    if (orientation === "Portrait") {
      var data = JSON.stringify({
        cname: `${props.user?.userName}}`,
        uid: `${id}`,
        clientRequest: {
          mixedVideoLayout: 3,
          backgroundColor: "#000000",
          maxResolutionUid: "1",
          layoutConfig: [
            {
              x_axis: 0.0,
              y_axis: 0.0,
              width: 1.0,
              height: 1.0,
              alpha: 1.0,
              render_mode: 1,
            },
          ],
        },
      });

      var config = {
        method: "post",
        url: `https://api.agora.io/v1/apps/6dcf0eff9b4a4bb09a963c2efcb55311/cloud_recording/resourceid/${resourceID}/sid/${sid}/mode/mix/updateLayout`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic ZjVmZTRiMmUzODQxNDRjMGE4Y2MwNGQ4OWE0ODQyM2Y6ZDdiMmY2NjM0ZTQ1NDZjNmE2NzQ0MGI1MDU4M2UyYjQ=",
        },
        data: data,
      };

      await axios(config);
    } else if (orientation === "Landscape") {
      var data2 = JSON.stringify({
        cname: `${props.user?.userName}`,
        uid: `${id}`,
        clientRequest: {
          mixedVideoLayout: 1,
          maxResolutionUid: "1",
          backgroundColor: "#000000",
        },
      });

      var config2 = {
        method: "post",
        url: `https://api.agora.io/v1/apps/6dcf0eff9b4a4bb09a963c2efcb55311/cloud_recording/resourceid/${this.state.resourceID}/sid/${this.state.sid}/mode/mix/updateLayout`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic ZjVmZTRiMmUzODQxNDRjMGE4Y2MwNGQ4OWE0ODQyM2Y6ZDdiMmY2NjM0ZTQ1NDZjNmE2NzQ0MGI1MDU4M2UyYjQ=",
        },
        data: data2,
      };

      await axios(config2);
    }
  };

  React.useEffect(() => {
    if (live) {
      window.addEventListener("resize", getOrientation);
    }

    return () => {
      window.removeEventListener("resize", getOrientation);
    };
  }, [getOrientation]);

  //  Recording Stop
  const RecordingStop = async () => {
    var data3 = {
      cname: props.user?.userName,
      uid: id,
      clientRequest: {},
    };
    var config = {
      method: "post",
      url: `https://api.agora.io/v1/apps/6dcf0eff9b4a4bb09a963c2efcb55311/cloud_recording/resourceid/${resourceID}/sid/${sid}/mode/mix/stop`,
      headers: {
        "content-type": "application/json;charset=utf-8",
        Authorization:
          "Basic ZjVmZTRiMmUzODQxNDRjMGE4Y2MwNGQ4OWE0ODQyM2Y6ZDdiMmY2NjM0ZTQ1NDZjNmE2NzQ0MGI1MDU4M2UyYjQ=",
      },
      data: data3,
    };
    const response = await axios(config);
    stopStreaming(response.data.serverResponse.fileList);
  };
  // Tip
  const tipHandler = () => {
    if (live) {
      setLoading(true);
      var data = new FormData();
      data.append("postId", postId);
      data.append("amount", tipAmount);
      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/tip/post",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          toast.success(response.data.response.message);
          publishTipMessage();
          setLoading(false);
          setOpen(!open);
          setTip(false);
        })
        .catch(function (error) {
          toast.error(error.response.data.response.message);
          setLoading(false);
          setOpen(!open);
        });
    }
  };

  const cameraFlip = () => {
    if (camera.length > 1) {
      if (flip) {
        rtc.localStream.switchDevice("video", camera[1].deviceId);
        setFlip(false);
      } else {
        rtc.localStream.switchDevice("video", camera[0].deviceId);
        setFlip(true);
      }
    }
  };
  const muteHandler = () => {
    if (audio) {
      setAudio(false);
      rtc.localStream.muteAudio();
    } else {
      setAudio(true);
      rtc.localStream.unmuteAudio();
    }
  };
  const videoHandler = () => {
    if (video) {
      setVideo(false);
      rtc.localStream.muteVideo();
    } else {
      setVideo(true);
      rtc.localStream.unmuteVideo();
    }
  };

  React.useMemo(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
      return;
    }

    // List cameras and microphones.

    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        devices.forEach(function (device) {
          if (device.kind === "videoinput") {
            setVideoExists(true);
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  }, []);

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        <div className="container sidebar-boxed">
          <div
            style={{
              background: "#ff000024",
              padding: "20px",
              border: "1px solid #ca1444",
              borderRadius: "10px",
              margin: "20px 0",
            }}
          >
            <p style={{ color: "#ca1444" }}>
              Note: Livestream quality is automatically controlled based on your
              internet speed for best streaming possible. The highest quality
              setting is 1080p.
              <br />
              For best practice please follow below points:
              <br />
              1. If you are streaming from phone, always stream in horizontal.
              Make sure your screen is flipped by turning on (auto-rotate) this
              will enable horizontal stream.
              <br />
              2. Recommended browsers are Chrome, Mozilla.
              <br />
              3. If you are using a webcam to stream, the flip button will not
              work, as it is only for phones.
              <br />
              4. If you webcam is not available the livestream preview/stream
              will not show. Please make sure the the camera is on "Check
              browser camera permissions".
              <br />
              5. The camera preview should show below if your camera is active.
              <br />
              6. You can still stream but only audio will go through if the
              camera is not available.
            </p>
          </div>
          <div className="videos-modal">
            <div className="card-body">
              <div className="inner">
                <div className="left-section">
                  <div className="video-wrapper">
                    <div className="video-wrap">
                      <div id="agora_local" className="start" />
                    </div>
                  </div>
                </div>
                <div className="right-section">
                  <div className="header">
                    <img src={props.user?.image} />
                    <div className="user-meta">
                      <span>
                        {props.user?.nickName}{" "}
                        {live ? <small>is live</small> : null}
                      </span>
                      <span>
                        {props.user?.nickName}{" "}
                        {live ? <small>rigth now</small> : null}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="button is-solid primary-button raised is-rounded"
                      disabled={desc === ""}
                      onClick={() => (live ? RecordingStop() : publish())}
                    >
                      {loading ? (
                        <CircularProgress
                          size="1rem"
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        `${live ? "End Stream" : "Go Live"}`
                      )}
                    </button>
                  </div>
                  <div className="inner-content">
                    <div className="control">
                      <input
                        type="text"
                        className="input is-sm is-fade"
                        placeholder="What is this live about?"
                        onChange={(e) => setDesc(e.target.value)}
                      />
                      <div className="icon">
                        <Activity />
                      </div>
                    </div>
                    <div className="actions">
                      <div className="action" onClick={() => videoHandler()}>
                        <Camera />
                        <span>Camera {video ? "On" : "Off"}</span>
                      </div>
                      <div className="action" onClick={() => muteHandler()}>
                        <Mic />
                        <span>Mic {audio ? "On" : "Off"}</span>
                      </div>
                      <div className="action" onClick={() => cameraFlip()}>
                        <RotateCw />
                        <span>Flip</span>
                      </div>
                    </div>
                    <div className="live-stats">
                      <div className="social-count">
                        <div className="likes-count">
                          <Heart />
                          <span>0</span>
                        </div>
                      </div>
                      <div className="social-count ml-auto">
                        <div className="views-count">
                          <Eye />
                          <span>0</span>
                          <span className="views">
                            <small>views</small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="actions">
                      <div className="action">
                        <CopyToClipboard
                          text={`https://community.tribeofbabes.com/post/${postCode}`}
                          onCopy={() => toast.success("Link Copied")}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Link2 style={{ margin: "5px" }} /> Copy Link
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                  <div className="tabs-wrapper">
                    <div className="tabs is-fullwidth">
                      <ul>
                        <li
                          className={action === "chat" ? "is-active" : ""}
                          onClick={() => setAction("chat")}
                        >
                          <a>Chat</a>
                        </li>
                        <li
                          className={
                            action === "leaderboard" ? "is-active" : ""
                          }
                          onClick={() => setAction("leaderboard")}
                        >
                          <a>Leaderboard</a>
                        </li>
                      </ul>
                    </div>
                    {action === "chat" ? (
                      <div className="tab-content has-slimscroll">
                        {messages
                          .filter((filter) => filter.Tip === undefined)
                          .map((i, k) => {
                            return (
                              <div className="media is-comment" key={k}>
                                <figure className="media-left">
                                  <p className="image is-32x32">
                                    <img src={i.image} />
                                  </p>
                                </figure>
                                <div className="media-content">
                                  <div className="username">{i.title}</div>
                                  <p>{i.description}</p>
                                  <div className="comment-actions">
                                    <span style={{ margin: "0px" }}>
                                      {i.Time}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div className="tab-content has-slimscroll">
                        {messages
                          .filter((filter) => filter.Tip !== undefined)
                          .map((i, k) => {
                            return (
                              <div className="media is-comment" key={k}>
                                <figure className="media-left">
                                  <p className="image is-32x32">
                                    <img src={i.image} />
                                  </p>
                                </figure>
                                <div className="media-content">
                                  <div className="username">{i.title}</div>
                                  <p>Tokens {i.Tip}</p>
                                  <div className="comment-actions">
                                    <span style={{ margin: "0px" }}>
                                      {i.Time}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div className="comment-controls">
                    <div className="controls-inner">
                      <img src={props.user?.image} />
                      <div className="control">
                        <input
                          className="textarea comment-textarea is-rounded"
                          rows={1}
                          placeholder="write your message..."
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          onKeyPress={(e) =>
                            e.key === "Enter" ? publishSampleMessage() : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div
        className={`modal is-xsmall has-light-bg share-modal ${
          open ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <h3>Tip</h3>
                <div className="close-wrap">
                  <span
                    className="close-modal"
                    onClick={() => {
                      setTip(false);
                      setOpen(false);
                    }}
                  >
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <React.Fragment>
                  {!tip ? (
                    <Formik
                      initialValues={{ tip: "" }}
                      validationSchema={Tip}
                      onSubmit={(values, { resetForm }) => {
                        setTip(true);
                        setTipAmount(values.tip);
                        resetForm();
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                      }) => (
                        <Form>
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                type="number"
                                placeholder="10"
                                name="tip"
                                id="tip"
                                value={values.tip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.tip && touched.tip && errors.tip ? (
                                <div className="dangerText">{errors.tip}</div>
                              ) : null}
                            </div>
                          </div>
                          <button
                            className="button is-solid primary-button raised is-rounded is-fullwidth"
                            disabled={loading}
                            type="submit"
                          >
                            {loading ? (
                              <CircularProgress
                                size="1rem"
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              "Confirm"
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ marginBottom: "10px" }}>
                        Are You Sure To Tip {`${tipAmount}`} Token
                      </h5>
                      <button
                        className="button is-solid primary-button raised is-rounded"
                        disabled={loading}
                        style={{ marginRight: "10px" }}
                        onClick={() => tipHandler()}
                      >
                        {loading ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                      <button
                        className="button is-solid primary-button raised is-rounded"
                        onClick={() => {
                          setTip(false);
                          setTipAmount("");
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    token: state.StreamApp.token,
    user: state.StreamApp.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LiveStream);
