import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import Button from "../../Elements/Button";

const LOGOUT_TIMEOUT = 2 * 60000;
const LOGOUT_ACTION_TIMEOUT = 20 * 1000;

const LogoutDialog = ({ logout }) => {
  const [open, setOpened] = useState(false);

  const logoutTimer = () => {
    return setTimeout(() => {
      setOpened(true);
      clearTimeout(timerLougout.current);
      timerLougout.current = logoutActionTimer();
    }, LOGOUT_TIMEOUT);
  };

  const logoutActionTimer = () => {
    return setTimeout(() => {
      logout();
    }, LOGOUT_ACTION_TIMEOUT);
  };

  const timer = useRef(logoutTimer());
  const timerLougout = useRef(null);

  const keepHandler = useCallback(() => {
    setOpened(false);
    clearTimeout(timer.current);
    clearTimeout(timerLougout.current);

    timer.current = logoutTimer();
  }, []);

  useEffect(() => {
    const keepIfNoVisible = () => {
      if (!open) {
        keepHandler();
      }
    };

    document.body.addEventListener("keyup", keepIfNoVisible);
    document.body.addEventListener("scroll", keepIfNoVisible);
    document.body.addEventListener("mousemove", keepIfNoVisible);

    return () => {
      document.body.removeEventListener("keyup", keepIfNoVisible);
      document.body.removeEventListener("scroll", keepIfNoVisible);
      document.body.removeEventListener("mousemove", keepIfNoVisible);
    };
  }, [open]);

  return (
    <div className={cx("logout-dialog", open && "logout-dialog--open")}>
      <div className="logout-dialog__description">
        Due to inactivity you are being logged out
      </div>
      <Button onClick={keepHandler}>Keep Me Logged In</Button>
    </div>
  );
};

export default LogoutDialog;
