import React from "react";

const Breadcrumbs = ({ title }) => {
  return (
    <div className="tb-breadcrumbs">
      <div className="tb-breadcrumbs__previous">Member Portal</div>
      <div className="tb-breadcrumbs__current">{title}</div>
    </div>
  );
};

export default Breadcrumbs;
