import React from "react";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import Header from "./Header";
import {
  Trash2,
  Flag,
  Heart,
  Link2,
  MessageCircle,
  MoreVertical,
  X,
  DollarSign,
} from "react-feather";
import { ReactComponent as Bubble } from "../images/bubble.svg";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import Slider from "react-slick";
import CircularProgress from "@mui/material/CircularProgress";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Formik, Form } from "formik";
import * as yup from "yup";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const Tip = yup.object().shape({
  tip: yup.number().required(),
});

const report = yup.object().shape({
  postType: yup.string().required(),
  report: yup.string().required(),
});

const PostSingle = (props) => {
  const [overlayView, setOverlayView] = React.useState({
    isOpen: false,
    src: "",
    type: "",
  });
  const [comment, setComment] = React.useState(false);
  const [action, setAction] = React.useState(false);
  const [post, setPost] = React.useState([]);
  const [commentText, setCommentText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [reply, setReply] = React.useState(false);
  const [replyOpen, setReplyOpen] = React.useState(-1);
  const [updated, setUpdated] = React.useState(false);
  const [likeLoader, setLikeLoader] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [tip, setTip] = React.useState(false);
  const [tipAmount, setTipAmount] = React.useState("");
  const [reportOpen, setReportOpen] = React.useState(false);
  const [postId, setPostId] = React.useState("");

  const tipModal = () => {
    setOpen(!open);
  };

  const getPost = async () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${props.match?.params?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    setPost([response.data.response.data]);
    setPostId(response.data.response.data.id);
    setUpdated(false);
  };

  React.useEffect(() => {
    if (props.token) {
      getPost();
    }
  }, [props.token, updated]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const commentHandler = async (i) => {
    setLoading(true);
    try {
      var data = new FormData();
      data.append("postId", i.id);
      data.append("comment", commentText);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/comments",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      toast.success("Comment Added");
      setLoading(false);
      setCommentText("");
      setUpdated(true);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };

  const replyToggle = (commentKey) => {
    if (replyOpen === commentKey) {
      setReplyOpen(-1);
    } else {
      setReplyOpen(commentKey);
    }
  };

  const replyHandler = async (comment, i) => {
    try {
      var data = new FormData();
      data.append("postId", i.id);
      data.append("comment", reply);
      data.append("commentId", comment.id);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/sub-comments",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      await axios(config);
      toast.success("Reply Added");
      setReply("");
      setUpdated(true);
      setReplyOpen(-1);
    } catch (e) {
      toast.error(e.response.data.response.message);
    }
  };

  const deleteReply = (subComment) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/sub-comments/${subComment.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        toast.success("Reply Deleted");
        setUpdated(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteComment = (comment) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/comments/${comment.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        toast.success("Comment Deleted");
        setUpdated(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const postDelete = async (i) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${i.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    setUpdated(true);
    toast.success(response.data.response.message);
  };

  const likeHandler = (i) => {
    setLikeLoader(i.id);
    var data = new FormData();
    data.append("postId", i.id);
    data.append("type", "post");

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/likes/posts",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function () {
        setUpdated(true);
        setLikeLoader(-1);
      })
      .catch(function (error) {
        setLikeLoader(-1);
      });
  };

  const overlayHandler = (open, src = "", type = "") => {
    if (open) {
      document.getElementsByClassName("sidebar-v1")[0].style.position =
        "inherit";
      window.scrollTo(0, 0);
      document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    } else {
      document.getElementsByClassName("sidebar-v1")[0].style.position = "fixed";
      window.scrollTo(0, 0);
      document.getElementsByTagName("html")[0].style.overflowY = "scroll";
    }
    setOverlayView({
      isOpen: open,
      src: src,
      type: type,
    });
  };

  const tipHandler = () => {
    setLoading(true);
    var data = new FormData();
    data.append("postId", post[0].id);
    data.append("amount", tipAmount);
    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/tip/post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success(response.data.response.message);
        setTip(false);
        setTipAmount("");
        setOpen(false);
        props.updateHandler(true);
        setLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.response.message);
        setLoading(false);
        setTip(false);
      });
  };

  const reportHandler = React.useCallback(
    (values, setSubmitting, resetForm) => {
      var data = new FormData();
      data.append("reportType", values.report);
      data.append("remarks", values.postType);

      var config = {
        method: "post",
        url: `https://admin-staging.tribeofbabes.com/api/v1/post_report/${postId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      axios(config).then(function (response) {
        toast.success(response.data.response.message);
        setReportOpen(false);
        setSubmitting(false);
        resetForm();
      });
    },
    [postId]
  );

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} postType={post[0]?.postType} />
        <div
          id="posts-feed"
          className="container sidebar-boxed"
          data-open-sidebar
          data-page-title="Feed"
          style={{ marginTop: "30px" }}
        >
          {post.map((i, k) => {
            return (
              <div className="card is-post is-simple" key={k}>
                {/* Main wrap */}
                <div className={`content-wrap ${comment ? "is-hidden" : ""}`}>
                  {/* Header */}
                  <div className="card-heading">
                    {/* User image */}
                    <div className="user-block">
                      <div className="image">
                        <img src={i.user_profile} />
                      </div>
                      <div className="user-info">
                        <Link to={`/user/${i.username}`}>{i.nickName}</Link>
                        <span className="time">
                          {moment(i.created_at).fromNow()}
                        </span>
                      </div>
                    </div>
                    {/* /partials/pages/feed/dropdowns/feed-post-dropdown.html */}
                    <div
                      className={`dropdown is-spaced is-right is-neutral dropdown-trigger ${
                        action ? "is-active" : ""
                      }`}
                    >
                      <div>
                        <div
                          className="button"
                          onClick={() => setAction(!action)}
                        >
                          <MoreVertical />
                        </div>
                      </div>
                      <div className="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                          {i.username === props.user?.userName ? (
                            <>
                              <a className="dropdown-item">
                                <div className="media">
                                  <Trash2 />
                                  <div
                                    className="media-content"
                                    onClick={() => postDelete(i)}
                                  >
                                    <h3>Delete</h3>
                                    <small>Delete this Post</small>
                                  </div>
                                </div>
                              </a>
                              <hr className="dropdown-divider" />
                            </>
                          ) : null}

                          {!i.isReported ? (
                            <a
                              className="dropdown-item"
                              onClick={() => setReportOpen(true)}
                            >
                              <div className="media">
                                <Flag />
                                <div className="media-content">
                                  <h3>Flag</h3>
                                  <small>
                                    In case of inappropriate content.
                                  </small>
                                </div>
                              </div>
                            </a>
                          ) : (
                            <a
                              className="dropdown-item"
                              onClick={() => setReportOpen(true)}
                            >
                              <div className="media">
                                <Flag />
                                <div className="media-content">
                                  <h3>Flag</h3>
                                  <small>Delete Report</small>
                                </div>
                              </div>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Header */}
                  {/* Post body */}
                  <div className="card-body">
                    {/* Post body text */}
                    <div className="post-text">
                      <p>{i.detail}</p>
                      <p />
                    </div>
                    <div className="post-image">
                      {i.postType !== "CloudRecording" ? (
                        <Slider {...settings}>
                          {i.postMedias.map((media, mediaKey) => {
                            return (
                              <React.Fragment key={mediaKey}>
                                {media.type === "image" ? (
                                  <div className="post-media">
                                    <img
                                      src={media.url}
                                      onClick={() => {
                                        overlayHandler(true, media.url, "img");
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="post-media">
                                    <video
                                      src={media.url}
                                      controls
                                      onClick={() => {
                                        overlayHandler(
                                          true,
                                          media.url,
                                          "video"
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Slider>
                      ) : (
                        <ReactPlayer
                          width="100%"
                          height="100%"
                          controls
                          url={`https://tribeofbabes-live-stream.s3.us-east-2.amazonaws.com/${i.liveStreamerLive}`}
                        />
                      )}
                    </div>
                    {/* Post actions */}
                  </div>
                  {/* /Post body */}
                  {/* Post footer */}
                  <div className="card-footer customPostFooter">
                    {/* Followers */}
                    <div style={{ display: "flex" }}>
                      <div className="likers-group">
                        {i.likes.slice(0, 2).map((likeImg, likeId) => {
                          return <img key={likeId} src={likeImg.userProfile} />;
                        })}
                      </div>
                      <div className="likers-text">
                        <p>
                          {i.likes.slice(0, 2).map((like, likeKey) => {
                            return <a key={likeKey}>{like.nickName}</a>;
                          })}
                        </p>
                        <p>liked this</p>
                      </div>
                    </div>

                    <div className="post-actions">
                      {/* /partials/pages/feed/buttons/feed-post-actions.html */}
                      <div style={{ display: "flex" }}>
                        <div className="like-wrapper">
                          {likeLoader !== i.id ? (
                            <a
                              className="like-button"
                              onClick={() => likeHandler(i)}
                            >
                              {i.likes.find(
                                (like) => like.userId === i.userId
                              ) ? (
                                <Heart
                                  style={{ stroke: "#ca1444" }}
                                  className="mdi mdi-heart not-liked bouncy likedPost"
                                />
                              ) : (
                                <Heart
                                  style={{ stroke: "#ca1444" }}
                                  className="mdi mdi-heart not-liked"
                                />
                              )}
                              <span className="like-overlay" />
                            </a>
                          ) : (
                            <CircularProgress
                              size="1rem"
                              style={{ color: "#ca1444" }}
                            />
                          )}
                        </div>
                        <div className="fab-wrapper is-share">
                          <CopyToClipboard
                            text={`https://community.tribeofbabes.com/post/${i.urlCode}`}
                            onCopy={() => toast.success("Link Copied")}
                          >
                            <a className="small-fab share-fab modal-trigger">
                              <Link2 />
                            </a>
                          </CopyToClipboard>
                        </div>
                        <div
                          className="fab-wrapper is-comment"
                          onClick={() => setComment(true)}
                        >
                          <a className="small-fab">
                            <MessageCircle />
                          </a>
                        </div>
                        {i.userId !== props.user?.id ? (
                          <div
                            className="fab-wrapper is-comment"
                            onClick={() => tipModal(true)}
                          >
                            <a className="small-fab tooltip">
                              <span className="tooltip-text tooltip-right">
                                Tip Creator
                              </span>
                              <DollarSign />
                            </a>
                          </div>
                        ) : null}
                      </div>

                      <div
                        className="social-count"
                        style={{ marginTop: "10px", justifyContent: "end" }}
                      >
                        <div className="likes-count">
                          <Heart />
                          <span>{i.likes.length}</span>
                        </div>
                        <div className="comments-count">
                          <MessageCircle />
                          <span>{i.comments.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Post footer */}
                </div>
                {/* /Main wrap */}
                {/* Post #6 comments */}
                <div className={`comments-wrap ${!comment ? "is-hidden" : ""}`}>
                  {/* Header */}
                  <div className="comments-heading">
                    <h4>
                      Comments
                      <small>({i.comments.length})</small>
                    </h4>
                    <div
                      className="close-comments"
                      onClick={() => setComment(false)}
                    >
                      <X />
                    </div>
                  </div>
                  {/* /Header */}
                  {/* Comments body */}
                  <div className="comments-body has-slimscroll">
                    {!i.comments.length ? (
                      <div className="comments-placeholder">
                        <Bubble style={{ maxWidth: "80px" }} />
                        <h3>Nothing in here yet</h3>
                        <p>Be the first to post a comment.</p>
                      </div>
                    ) : (
                      <React.Fragment>
                        {i.comments.map((comment, commentKey) => {
                          return (
                            <div className="media is-comment" key={commentKey}>
                              <div className="media-left">
                                <div className="image">
                                  <img src={comment.userProfile} />
                                </div>
                              </div>
                              <div className="media-content">
                                <a>{comment.nickName}</a>
                                <span className="time">
                                  {moment(comment.created_at).fromNow()}
                                </span>
                                <p>{comment.comment}</p>
                                <div className="controls controls-d-block">
                                  <div className="controls-actions-custom">
                                    <div className="reply">
                                      <a
                                        onClick={() => replyToggle(commentKey)}
                                      >
                                        {replyOpen === commentKey
                                          ? "Close"
                                          : "Reply"}
                                      </a>
                                    </div>
                                    <div className="reply">
                                      <a onClick={() => deleteComment(comment)}>
                                        Delete
                                      </a>
                                    </div>
                                  </div>

                                  {replyOpen === commentKey ? (
                                    <div className="media post-comment has-emojis">
                                      {/* Textarea */}
                                      <div className="media-content">
                                        <div className="field">
                                          <p className="control">
                                            <textarea
                                              className="textarea comment-textarea"
                                              rows={5}
                                              placeholder="Write a reply..."
                                              onChange={(e) =>
                                                setReply(e.target.value)
                                              }
                                            />
                                          </p>
                                        </div>
                                        {/* Additional actions */}
                                        <div className="actions">
                                          <div className="toolbar">
                                            <a
                                              className="button is-solid primary-button raised"
                                              onClick={() =>
                                                replyHandler(comment, i)
                                              }
                                              style={{
                                                color: "#fff",
                                                borderRadius: "10px",
                                              }}
                                            >
                                              {loading ? (
                                                <CircularProgress
                                                  size="1rem"
                                                  style={{ color: "#fff" }}
                                                />
                                              ) : (
                                                "Post Reply"
                                              )}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {comment.subComments.map(
                                  (subComment, subCommentkey) => {
                                    return (
                                      <div
                                        className="media is-comment"
                                        key={subCommentkey}
                                      >
                                        <div className="media-left">
                                          <div className="image">
                                            <img src={subComment.userProfile} />
                                          </div>
                                        </div>
                                        <div className="media-content">
                                          <a>{subComment.nickName}</a>
                                          <span className="time">
                                            {moment(
                                              subComment.created_at
                                            ).fromNow()}
                                          </span>
                                          <p>{subComment.comment}</p>

                                          <div className="controls">
                                            <div className="reply">
                                              <a
                                                onClick={() =>
                                                  deleteReply(subComment)
                                                }
                                              >
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="media-right">
                                          <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                                            <div>
                                              <div className="button">
                                                <i data-feather="more-vertical"></i>
                                              </div>
                                            </div>
                                            <div
                                              className="dropdown-menu"
                                              role="menu"
                                            >
                                              <div className="dropdown-content">
                                                <a className="dropdown-item">
                                                  <div className="media">
                                                    <i data-feather="x"></i>
                                                    <div className="media-content">
                                                      <h3>Hide</h3>
                                                      <small>
                                                        Hide this comment.
                                                      </small>
                                                    </div>
                                                  </div>
                                                </a>
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item">
                                                  <div className="media">
                                                    <i data-feather="flag"></i>
                                                    <div className="media-content">
                                                      <h3>Report</h3>
                                                      <small>
                                                        Report this comment.
                                                      </small>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <div className="media-right">
                                <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                                  <div>
                                    <div className="button">
                                      <i data-feather="more-vertical"></i>
                                    </div>
                                  </div>
                                  <div className="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                      <a className="dropdown-item">
                                        <div className="media">
                                          <i data-feather="x"></i>
                                          <div className="media-content">
                                            <h3>Hide</h3>
                                            <small>Hide this comment.</small>
                                          </div>
                                        </div>
                                      </a>
                                      <div className="dropdown-divider"></div>
                                      <a href="#" className="dropdown-item">
                                        <div className="media">
                                          <i data-feather="flag"></i>
                                          <div className="media-content">
                                            <h3>Report</h3>
                                            <small>Report this comment.</small>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </div>
                  {/* /Comments body */}
                  {/* Comments footer */}
                  <div className="card-footer">
                    <div className="media post-comment has-emojis">
                      {/* Textarea */}
                      <div className="media-content">
                        <div className="field">
                          <p className="control">
                            <textarea
                              className="textarea comment-textarea"
                              rows={5}
                              placeholder="Write a comment..."
                              value={commentText}
                              onChange={(e) => setCommentText(e.target.value)}
                            />
                          </p>
                        </div>
                        {/* Additional actions */}
                        <div className="actions">
                          <div className="toolbar">
                            <a
                              className="button is-solid primary-button raised"
                              onClick={() => commentHandler(i)}
                            >
                              {loading ? (
                                <CircularProgress
                                  size="1rem"
                                  style={{ color: "#fff" }}
                                />
                              ) : (
                                "Post Comment"
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Comments footer */}
                </div>
                {/* /Post #6 comments */}
              </div>
            );
          })}
        </div>
        <Footer />
      </div>

      <div
        className={`modal is-xsmall has-light-bg share-modal ${
          open ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <h3>Tip</h3>
                <div className="close-wrap">
                  <span
                    className="close-modal"
                    onClick={() => {
                      setTip(false);
                      setTipAmount("");
                      setOpen(false);
                    }}
                  >
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <React.Fragment>
                  {!tip ? (
                    <Formik
                      initialValues={{ tip: "" }}
                      validationSchema={Tip}
                      onSubmit={(values, { resetForm }) => {
                        setTip(true);
                        setTipAmount(values.tip);
                        resetForm();
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                      }) => (
                        <Form>
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                type="number"
                                placeholder="10"
                                name="tip"
                                id="tip"
                                value={values.tip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.tip && touched.tip && errors.tip ? (
                                <div className="dangerText">{errors.tip}</div>
                              ) : null}
                            </div>
                          </div>
                          <button
                            className="button is-solid primary-button raised is-rounded is-fullwidth"
                            disabled={loading}
                            type="submit"
                          >
                            {loading ? (
                              <CircularProgress
                                size="1rem"
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              "Confirm"
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ marginBottom: "10px" }}>
                        Are You Sure To Tip {`${tipAmount}`} Token
                      </h5>
                      <button
                        className="button is-solid primary-button raised is-rounded"
                        disabled={loading}
                        style={{ marginRight: "10px" }}
                        onClick={() => tipHandler()}
                      >
                        {loading ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                      <button
                        className="button is-solid primary-button raised is-rounded"
                        onClick={() => {
                          setTip(false);
                          setTipAmount("");
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal is-xsmall has-light-bg share-modal ${
          reportOpen ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <h3>Report</h3>
                <div className="close-wrap">
                  <span
                    className="close-modal"
                    onClick={() => {
                      setReportOpen(false);
                    }}
                  >
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                {" "}
                <Formik
                  initialValues={{
                    postType: "",
                    report: "",
                  }}
                  validationSchema={report}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    reportHandler(values, setSubmitting, resetForm);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <Form>
                      <div className="field">
                        <div className="control">
                          <select
                            className="input"
                            type="text"
                            placeholder="report"
                            name="postType"
                            id="postType"
                            value={values.postType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option className="d-none">Report Type</option>
                            <option value="Hate speech or symbols">
                              Hate speech or symbols
                            </option>
                            <option value="Violence or dangerous organizations">
                              Violence or dangerous organizations
                            </option>
                            <option value="Sale of illegal or regulated goods">
                              Sale of illegal or regulated goods
                            </option>
                            <option value="Bullying or harassment">
                              Bullying or harassment
                            </option>
                            <option value="Intellectual property violation">
                              Intellectual property violation
                            </option>
                            <option value="Suicide, self-injury or eating disorders">
                              suicide, self-injury or eating disorders
                            </option>
                            <option value="Scam or fraud">Scam or fraud</option>
                          </select>
                          {errors.postType &&
                          touched.postType &&
                          errors.postType ? (
                            <div className="dangerText">{errors.tip}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="message"
                            name="report"
                            id="report"
                            value={values.report}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.report && touched.report && errors.report ? (
                            <div className="dangerText">{errors.report}</div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        className="button is-solid primary-button raised is-rounded is-fullwidth"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {overlayView.isOpen ? (
        <div className="overlayFull">
          <div className="post-media overlayImage">
            {overlayView.type === "img" ? <img src={overlayView.src} /> : ""}
            {overlayView.type === "video" ? (
              <video src={overlayView.src} controls />
            ) : (
              ""
            )}
          </div>
          <svg
            onClick={() => {
              overlayHandler(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            className="overlayIcon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    token: state.StreamApp.token,
    user: state.StreamApp.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    updateHandler: (value) => dispatch({ type: "UPDATE", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostSingle);
