import React from "react";
import cx from "classnames";

const Button = ({
  type = "submit",
  color = "primary",
  style,
  onClick,
  disabled,
  children,
}) => {
  return (
    <button
      style={style}
      type="submit"
      className={cx("tb-button", {
        "tb-button--primary": color === "primary",
        "tb-button--secondary": color === "secondary",
        "tb-button--dark": color === "dark",
        "tb-button--white": color === "white",
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
