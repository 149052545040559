import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const ContactUsLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="tb-contact-us-layout">
        <div className="container">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsLayout;
