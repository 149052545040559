import React from "react";
import cx from "classnames";

const BigTextarea = ({ className, error, description, value, ...props }) => {
  return (
    <div className="tb-big-textarea">
      <textarea className={cx("tb-big-textarea__input", className)} {...props}>
        {value}
      </textarea>
      {description && (
        <div
          className={cx("tb-big-textarea__description", {
            "tb-big-textarea__description--error": error,
          })}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default BigTextarea;
