import React from "react";
import { Link } from "react-router-dom";

import Logo from "../images/Logo.png";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div style={{ marginTop: "4rem", height: "80vh" }}>
          <h1>Privacy Policy</h1>
          <iframe
            title="Privacy"
            src="https://app.termly.io/document/privacy-policy/a569dc75-4636-4cb5-a4e1-fc712151e607"
            style={{ width: "100%", height: "calc(100% - 4rem)" }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
