import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Posts from "../Elements/Posts";
import { connect } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "./Footer";

const Home = (props) => {
  const [posts, setPosts] = React.useState({ data: [], otherData: [] });
  const [share, setShare] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    props.user?.creatorType !== "None" ? setShare(false) : setShare(true);
  }, [props.user]);

  React.useEffect(() => {
    if (props.token) {
      getPosts();
    }
  }, [props.token]);

  const getPosts = () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/posts",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.response.data.length) {
          setPosts({
            data: response.data.response.data,
            otherData: response.data.response.data_other,
          });
        } else if (props.user?.creatorType !== "None") {
          props.history.push("/search");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const creatorHandler = async (creator) => {
    setLoading(true);
    var data = new FormData();
    data.append("creatorType", creator);

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/make/content-creator",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setShare(false);
        setLoading(false);
        toast.success(response.data.response.message);
        userProfile();
        props.history.push("/welcome");
      })
      .catch(function (error) {
        toast.success(error.response.data.response.message);
        setLoading(false);
      });
  };

  const userProfile = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        {/* Container */}
        <div
          id="posts-feed"
          className="container"
          data-open-sidebar
          data-page-title="Feed"
        >
          {/*Stories*/}
          {/* <Stories navigate={props.history} posts={posts.filter((f) => f.postType == "Live")} /> */}
          {/*Posts Grid*/}
          <div className="posts-feed-wrapper is-2x">
            <Posts navigate={props.history} posts={posts} />
          </div>
        </div>
        <div
          id="share-modal"
          className={`modal share-modal is-xsmall has-light-bg ${
            share ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-content">
            <div className="card">
              <div className="columns" style={{ padding: "20px 0px" }}>
                <div className="text-modal">
                  <div className="getStared">
                    <h1>SELECT OPTION</h1>
                    <h4>LETS GET YOU STARTED</h4>
                  </div>
                </div>
                <div className="btn-red">
                  <div className="user" onClick={() => creatorHandler("Fan")}>
                    {!loading ? (
                      <h1>
                        STAY
                        <br />
                        USER
                      </h1>
                    ) : (
                      <CircularProgress
                        size={20}
                        style={{ color: "#fff", textAlign: "center" }}
                      />
                    )}
                  </div>
                </div>
                <div className="btn-red">
                  <div
                    className="creator"
                    onClick={() => (!loading ? creatorHandler("Model") : null)}
                  >
                    {!loading ? (
                      <h1>
                        BECOME
                        <br />
                        CREATOR
                      </h1>
                    ) : (
                      <CircularProgress
                        size={20}
                        style={{ color: "#fff", textAlign: "center" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    token: state.StreamApp.token,
    user: state.StreamApp.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    userHandler: (value) => dispatch({ type: "USER", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
