import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import {
  Edit3,
  Video,
  X,
  Check,
  Search,
  MapPin,
  Camera,
  Smile,
  ChevronDown,
  Globe,
  Users,
  Film,
  Lock,
  Plus,
  User,
  Bell,
  Clock,
  Eye,
  Mail,
  File,
  Heart,
  AlignLeft,
  AlignRight,
  Calendar,
  Coffee,
  Copy,
  Trash,
  Slash,
  AlertOctagon,
  RotateCw,
  Settings,
} from "react-feather";
import ProfilePosts from "../Elements/ProfilePosts";
import { ReactComponent as Album } from "../images/albums.svg";
import { ReactComponent as Upload } from "../images/upload.svg";
import Stream from "../Elements/Stream";
import Crop from "../Elements/Cropper";
import axios from "axios";
import * as yup from "yup";
import { Formik, Form } from "formik";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Link } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import Footer from "./Footer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";

const post = yup.object().shape({
  desc: yup.string().required("Description cannot be empty!"),
  postType: yup.string().required(),
  category: yup.string().required(),
  time: yup.string().when("postType", {
    is: "Scheduled",
    then: yup.string().required("Time is required"),
  }),
});
const Profile = (props) => {
  const [hinter, setHinter] = React.useState(true);
  const [thumbnailHinter, setThumbnailHinter] = React.useState(false);
  const [Post, setPost] = React.useState(false);
  const [stream, setStream] = React.useState(false);
  const [share, setShare] = React.useState(false);
  const [picture, setPicture] = React.useState([]);
  const [thumbnails, setThumbnails] = React.useState([]);
  const [imgData, setImgData] = React.useState([]);
  const [uploadGroup, setUploadGroup] = React.useState(1);
  const [group, setGroup] = React.useState(false);
  const [groupStart, setGroupStart] = React.useState(false);
  const [profileModal, setProfileModal] = React.useState(false);
  const [posts, setPosts] = React.useState([]);
  const [latest, setLatest] = React.useState(false);
  const [cat, setCat] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState(false);
  const [percentage, setPercentage] = React.useState(0);
  const [activePosts, setActivePosts] = React.useState("ActivePosts");

  const [gallery, setGallery] = React.useState({
    instance: Math.floor(Math.random() * 10000000),
    media: [],
  });

  React.useEffect(() => {
    if (props.token) {
      getPosts();
      getCat();
    }
  }, [props.token]);

  React.useEffect(() => {
    if (props.token) {
      getPosts();
    }
  }, [props.token, latest]);

  const getCat = () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/categories",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setCat(response.data.response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPosts = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/posts",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    const response = await axios(config);
    setPosts(response.data.response.data);
    setLatest(false);
  };

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isVideo = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mp4":
      case "mov":
      case "mpg":
      case "mpeg":
        return true;
      default:
        return false;
    }
  };

  const initThumbnail = (i) => {
    console.log({ pictureIndex: i });
    setThumbnails([...thumbnails, i]);
  };

  const removeThumbnails = (i) => {
    console.log({ pictureIndex: i });
    setThumbnails([...thumbnails.filter((t) => t.id != i.id)]);
  };

  const removethumbnailFile = (index, thumbnail_index) => {
    let new_thumbnails = [...thumbnails];
    if (new_thumbnails[index].thumbnails) {
      new_thumbnails[index].thumbnails = new_thumbnails[
        index
      ].thumbnails.filter((i, k) => k != thumbnail_index);
    }
    setThumbnails(new_thumbnails);
  };

  const addThumbnail = (i) => {
    console.log({ pictureIndex: i });
    setThumbnails([...thumbnails.filter((t) => t.id != i.id)]);
  };

  const selectThumbnail = (index) => {
    let thumbnail_collection = thumbnails;
    let thumbnail_data = thumbnail_collection[index];

    thumbnail_collection.forEach((t) => {
      t.select = false;
    });
    thumbnail_collection[index].select = true;
    setThumbnails([...thumbnail_collection]);
  };

  const galleryHandler = (action, payload = null) => {
    let __gallery = gallery;
    let index = -1;

    __gallery.instance = Math.floor(Math.random() * 10000000);

    console.warn(`performing --action=${action}`);
    console.log({ action, payload, __gallery, index });

    switch (action) {
      case "choose":
        let media = [];
        Array(...payload.target.files).forEach(async (file) => {
          let mime = file.type.split("/");
          if (mime[0] == "video") {
            setThumbnailHinter(true);
            await generateVideoThumbnails(file, 20)
              .then(async (thumbnailArray) => {
                let collection = [];
                await thumbnailArray.forEach(async (base64) => {
                  const base64Response = await fetch(base64);
                  const blob = await base64Response.blob();
                  //var data = window.atob(base64.split(',')[1]);
                  let thumbnail = {
                    id:
                      Math.floor(Math.random() * 10000000) +
                      Math.floor(Math.random() * 10000000),
                    file: DataURIToBlob(base64),
                    type: "image",
                    blob: URL.createObjectURL(blob),
                    auto: true,
                    selected: false,
                  };
                  collection.push(thumbnail);
                });
                if (collection.length > 0) {
                  collection[0].selected = true;
                }

                let __file = {
                  id: Math.floor(Math.random() * 10000000),
                  file: file,
                  type: mime[0],
                  blob: URL.createObjectURL(file),
                  thumbnail: {
                    toggle: false,
                    collection: collection,
                  },
                  poster: false,
                };
                media.push(__file);
                __gallery.media = media;
                setGallery({ ...__gallery });
              })
              .catch((err) => {
                console.error(err);
                setThumbnailHinter(false);
              });
            setThumbnailHinter(false);
          } else {
            let __file = {
              id: Math.floor(Math.random() * 10000000),
              file: file,
              type: mime[0],
              blob: URL.createObjectURL(file),
              thumbnail: {
                toggle: false,
                collection: [],
              },
              poster: false,
            };
            media.push(__file);
            __gallery.media = media;
            setGallery({ ...__gallery });
          }
        });
        break;
      case "remove":
        __gallery.media = __gallery.media.filter(
          (file) => file.id !== payload.id
        );
        break;
      case "toggle-thumbnail":
        index = __gallery.media.findIndex((file) => file.id === payload.id);
        if (index != -1 && index !== undefined) {
          __gallery.media[index].thumbnail.toggle =
            !__gallery.media[index].thumbnail.toggle;
        }
        break;
      case "choose-thumbnail":
        let collection = [];
        Array(...payload.event.target.files).forEach((file) => {
          let mime = file.type.split("/");
          let thumbnail = {
            id: Math.floor(Math.random() * 10000000),
            file: file,
            type: mime[0],
            auto: false,
            blob: URL.createObjectURL(file),
            selected: false,
          };
          collection.push(thumbnail);
        });
        index = __gallery.media.findIndex((f) => f.id === payload.file.id);
        if (index != -1 && index !== undefined) {
          __gallery.media[index].thumbnail.collection.push(...collection);
        }
        break;
      case "select-thumbnail":
        __gallery.media = __gallery.media.map((file) => {
          if (file.id === payload.file.id) {
            console.log("BEFORE", { ...file.thumbnail.collection });
            file.thumbnail.collection = file.thumbnail.collection.map(
              (thumbnail) => {
                if (thumbnail.id === payload.thumbnail.id) {
                  file.poster = thumbnail.blob;
                  thumbnail.selected = true;
                } else {
                  thumbnail.selected = false;
                }
                return thumbnail;
              }
            );
          }
          console.log("AFTER", { ...file.thumbnail.collection });
          return file;
        });
        break;
      case "remove-thumbnail":
        __gallery.media = __gallery.media.map((file) => {
          if (file.id === payload.file.id) {
            file.thumbnail.collection = file.thumbnail.collection.filter(
              (thumbnail) => thumbnail.id !== payload.thumbnail.id
            );
          }
          return file;
        });
        break;
      default:
        break;
    }

    console.log("BEFORE-SET-STATE", { gallery: __gallery });
    if (!["choose"].includes(action)) {
      setGallery({ ...__gallery });
    }
  };

  const groupHandler = () => {
    setGroup(true);
    setGroupStart(false);
  };

  const postHandler = async (values, resetForm = () => {}) => {
    setLoading(true);

    var data = new FormData();
    data.append("postType", values.postType);
    data.append("detail", values.desc);
    values.postType === "Scheduled" &&
      data.append(
        "scheduleDateTime",
        new moment(values.time, "YYYY-MM-DD HH:mm")
          .utc()
          .format("YYYY-MM-DD HH:mm")
      );
    data.append("categoryId", values.category);

    let __gallery = gallery;
    __gallery.media.forEach((__file, index) => {
      data.append("post_media[" + index + "]", __file.file);
      if (__file.type === "video" && __file.thumbnail.collection.length > 0) {
        __file.thumbnail.collection
          .filter((thum) => thum.auto == false || thum.selected)
          .forEach((thumbnail, __index) => {
            if (thumbnail.auto) {
              data.append(
                "post_media_thumbnail[" + index + "][" + __index + "]",
                thumbnail.file,
                "thumbnail.jpg"
              );
            } else {
              data.append(
                "post_media_thumbnail[" + index + "][" + __index + "]",
                thumbnail.file
              );
            }
            if (thumbnail.selected) {
              data.append(
                "post_media_thumbnail_featured[" + index + "][" + __index + "]",
                thumbnail.selected
              );
            }
          });
      }
    });

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/posts",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPercentage(percentCompleted);
      },
    };

    axios(config).then(async (response) => {
      resetForm();
      setGallery({
        instance: Math.floor(Math.random() * 10000000),
        media: [],
      });
      await getPosts();
      setPost(false);
      setLatest(true);
      setLoading(false);
      setPicture([]);
      setImgData([]);
      setPercentage(0);
    });
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const modalHandler = (value) => {
    setAction(value);
    setProfileModal(true);
  };

  var date = localStorage.getItem("createdAt");
  var now = moment(new Date());
  var end = moment(date).format("YYYY-MM-DD");
  var duration = moment.duration(now.diff(end));
  var days = duration.asDays();

  return (
    <>
      <div
        className={`app-overlay is-sidebar-v1 ${Post ? "is-active" : ""}`}
      ></div>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        <div
          id="main-feed"
          className="container sidebar-boxed"
          data-page-title="Timeline"
        >
          <div className="columns is-multiline no-margin">
            {/* Left side column */}
            <div className="column is-paddingless">
              {/* Timeline Header */}
              {/* html/partials/pages/profile/timeline/timeline-header.html */}
              <div className="cover-bg">
                <img
                  className="cover-image"
                  src={
                    props.user?.bannerImage
                      ? props.user?.bannerImage
                      : "https://via.placeholder.com/1600x460"
                  }
                />
                <div className="avatar">
                  <img
                    id="user-avatar"
                    className="avatar-image"
                    src={
                      props.user?.image
                        ? props.user?.image
                        : "https://via.placeholder.com/300x300"
                    }
                  />
                  <Plus
                    className="plus-avatar"
                    onClick={() => modalHandler("Profile")}
                  />
                </div>
                <div className="cover-overlay" />
                <div
                  className="cover-edit modal-trigger"
                  onClick={() => modalHandler("Banner")}
                >
                  <i className="mdi mdi-camera" />
                  <span>Edit cover image</span>
                </div>
              </div>
              <div className="profile-subheader">
                <div className="subheader-middle">
                  <h2>
                    {props.user?.nickName}
                    <CopyToClipboard
                      text={`https://community.tribeofbabes.com/user/${props.user?.userName}`}
                      onCopy={() => toast.success("Link Copied")}
                    >
                      <Copy
                        size={15}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      />
                    </CopyToClipboard>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div id="activity-feed" className="view-wrap true-dom">
            {props.user?.creatorType === "Model" ? (
              <div className="columns">
                {/* Middle column */}
                <div className="column is-8">
                  {/* Publishing Area */}
                  {/* /partials/pages/feed/compose-card.html */}

                  <div
                    id="compose-card"
                    className={`card is-new-content ${
                      Post ? "is-highlighted" : ""
                    }`}
                  >
                    {/* Top tabs */}
                    <div className="tabs-wrapper">
                      <div className="tabs is-boxed is-fullwidth">
                        <ul>
                          <li className="is-active">
                            <a>
                              <span className="icon is-small">
                                <Edit3 />
                              </span>
                              <span>Publish</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="modal-trigger"
                              data-modal="videos-help-modal"
                              onClick={() => props.history.push("/live")}
                            >
                              <span className="icon is-small">
                                <Video />
                              </span>
                              <span>Live Stream</span>
                            </a>
                          </li>
                          {/* Close X button */}
                          <li
                            className="close-wrap"
                            onClick={() => setPost(false)}
                          >
                            <span className="close-publish">
                              <X />
                            </span>
                          </li>
                        </ul>
                      </div>
                      {/* Tab content */}
                      <div
                        className="tab-content"
                        onClick={() => setPost(true)}
                      >
                        {/* Compose form */}
                        <Formik
                          onSubmit={(values, { resetForm }) => {
                            postHandler(values, resetForm);
                          }}
                          initialValues={{
                            desc: "",
                            postType: "Published",
                            category: "1",
                            time: "",
                          }}
                          validationSchema={post}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }) => (
                            <Form>
                              <div className="compose hintUiCompose">
                                <div className="compose-form">
                                  <img src={props.user?.image} />
                                  <div className="control">
                                    <textarea
                                      value={values.desc}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      id="desc"
                                      name="desc"
                                      className="textarea"
                                      rows={3}
                                      placeholder="Write something about you..."
                                    />
                                    {errors.desc &&
                                    touched.desc &&
                                    errors.desc ? (
                                      <div className="dangerText">
                                        {errors.desc}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                {gallery.media?.length > 0 ? (
                                  <div
                                    id="feed-upload"
                                    className="feed-upload outer-over-flow"
                                    key={gallery.instance + 1}
                                  >
                                    <div className="flex-upper-overflow-x">
                                      {gallery.media
                                        .filter(
                                          (file) => !file.thumbnail.toggle
                                        )
                                        .map((file, index) => {
                                          return file ? (
                                            <div
                                              className="upload-wrap"
                                              key={index}
                                            >
                                              {file.type === "video" ? (
                                                <video
                                                  style={{
                                                    backgroundColor: "#121313",
                                                    height: "100px",
                                                    borderRadius: "0.5rem",
                                                  }}
                                                  width={"100%"}
                                                  height={"100px"}
                                                  controls
                                                  poster={file.poster}
                                                  src={file.blob}
                                                ></video>
                                              ) : (
                                                <img
                                                  src={file.blob}
                                                  height={"110px"}
                                                  alt="upload"
                                                />
                                              )}
                                              {file.type === "video" ? (
                                                <span>
                                                  <span
                                                    className="remove-file"
                                                    onClick={() =>
                                                      galleryHandler(
                                                        "remove",
                                                        file
                                                      )
                                                    }
                                                  >
                                                    <Trash />
                                                  </span>
                                                  <span
                                                    className="video-thumbnail"
                                                    onClick={() =>
                                                      galleryHandler(
                                                        "toggle-thumbnail",
                                                        file
                                                      )
                                                    }
                                                  >
                                                    <Film />
                                                  </span>
                                                </span>
                                              ) : (
                                                <span
                                                  className="remove-file"
                                                  onClick={() =>
                                                    galleryHandler(
                                                      "remove",
                                                      file
                                                    )
                                                  }
                                                >
                                                  <Trash />
                                                </span>
                                              )}
                                            </div>
                                          ) : null;
                                        })}
                                    </div>
                                  </div>
                                ) : null}
                                {gallery.media?.length > 0 ? (
                                  <div
                                    id="feed-upload"
                                    className="feed-upload"
                                    key={gallery.instance + 2}
                                  >
                                    {gallery.media
                                      .filter((file) => file.thumbnail.toggle)
                                      .map((file, index) => {
                                        return file.type === "video" ? (
                                          <span
                                            style={{
                                              position: "relative",
                                              display: "grid",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <div
                                              className="upload-wrap borderWrap upload-w-full"
                                              style={{ border: "none" }}
                                              key={index}
                                            >
                                              {file.type === "video" ? (
                                                <video
                                                  className="border-wrap-video"
                                                  style={{
                                                    backgroundColor: "#121313",
                                                    height: "100%",
                                                  }}
                                                  width={"100%"}
                                                  height={"100%"}
                                                  controls
                                                  poster={file.poster}
                                                  src={file.blob}
                                                ></video>
                                              ) : (
                                                <img
                                                  src={file.blob}
                                                  height={"110px"}
                                                  alt="upload"
                                                />
                                              )}
                                              {file.type === "video" ? (
                                                <span>
                                                  <span
                                                    className="remove-file"
                                                    onClick={() =>
                                                      galleryHandler(
                                                        "remove",
                                                        file
                                                      )
                                                    }
                                                  >
                                                    <Trash />
                                                  </span>
                                                  <span
                                                    className="video-thumbnail"
                                                    onClick={() =>
                                                      galleryHandler(
                                                        "toggle-thumbnail",
                                                        file
                                                      )
                                                    }
                                                  >
                                                    <X />
                                                  </span>
                                                </span>
                                              ) : (
                                                <span
                                                  className="remove-file"
                                                  onClick={() =>
                                                    galleryHandler(
                                                      "remove",
                                                      file
                                                    )
                                                  }
                                                >
                                                  <Trash />
                                                </span>
                                              )}
                                            </div>

                                            <div
                                              className="childWrap outer-over-flow"
                                              key={gallery.instance + 3}
                                            >
                                              <div className="flex-upper-overflow-x">
                                                <span>
                                                  <div
                                                    className="compose-optionFilm"
                                                    key={gallery.instance + 4}
                                                  >
                                                    <div className="attachMain">
                                                      <Film />
                                                      <span>
                                                        Attach thumbnails ...
                                                      </span>
                                                    </div>
                                                    <input
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      type="file"
                                                      multiple
                                                      onChange={(e) =>
                                                        galleryHandler(
                                                          "choose-thumbnail",
                                                          { file, event: e }
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </span>
                                                {[...file.thumbnail.collection]
                                                  .reverse()
                                                  .map((thumbnail, _index) => {
                                                    return (
                                                      <div
                                                        className="upload-wrap"
                                                        key={thumbnail.id}
                                                      >
                                                        <img
                                                          src={thumbnail.blob}
                                                          height={"110px"}
                                                          alt="upload"
                                                        />
                                                        <span
                                                          className="remove-file"
                                                          onClick={() =>
                                                            galleryHandler(
                                                              "remove-thumbnail",
                                                              {
                                                                file,
                                                                thumbnail,
                                                              }
                                                            )
                                                          }
                                                        >
                                                          <Trash />
                                                        </span>
                                                        <span
                                                          className={`${
                                                            thumbnail.selected
                                                              ? "thumbnail-selected"
                                                              : ""
                                                          } check-ico`}
                                                          onClick={() =>
                                                            galleryHandler(
                                                              "select-thumbnail",
                                                              {
                                                                file,
                                                                thumbnail,
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {thumbnail.selected ? (
                                                            <Check />
                                                          ) : (
                                                            <Slash />
                                                          )}
                                                        </span>
                                                      </div>
                                                    );
                                                  })}
                                              </div>
                                            </div>
                                          </span>
                                        ) : null;
                                      })}
                                  </div>
                                ) : null}
                              </div>
                              {thumbnailHinter ? (
                                <div className="flex justify-between items-center">
                                  <div className="hintUI">
                                    <span className="alertIcon filmSvg">
                                      <Settings
                                        className="hint-loader"
                                        style={{ padding: "2px" }}
                                      />
                                    </span>
                                    <span> Video Detected : </span>
                                    <span> generating video thumbnails...</span>
                                  </div>
                                  <div
                                    className="rightCrossMain"
                                    onClick={() => {
                                      setThumbnailHinter(false);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="crossIcon"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      stroke-width="2"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              ) : null}
                              {[...gallery.media].findIndex(
                                (m) =>
                                  m.type === "video" &&
                                  m.thumbnail.toggle === false &&
                                  hinter
                              ) !== -1 ? (
                                <div className="flex justify-between items-center">
                                  <div className="hintUI">
                                    <span className="alertIcon filmSvg">
                                      <AlertOctagon />
                                    </span>
                                    <span> Hint : </span>(
                                    <span className="filmSvg">
                                      <Film />
                                    </span>
                                    ) <span> to choose video thumbnail...</span>
                                  </div>
                                  <div
                                    className="rightCrossMain"
                                    onClick={() => {
                                      setHinter(false);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="crossIcon"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      stroke-width="2"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              ) : null}

                              {[...gallery.media].findIndex(
                                (m) =>
                                  m.type === "video" &&
                                  m.thumbnail.toggle === true &&
                                  hinter
                              ) !== -1 ? (
                                <div className="flex justify-between items-center">
                                  <div className="hintUI">
                                    <span className="alertIcon filmSvg">
                                      <AlertOctagon />
                                    </span>
                                    <span> Hint : </span>
                                    <div className="">
                                      <div className="flex">
                                        <span className="filmSvg mr-2 mb-2">
                                          (<X />)
                                        </span>{" "}
                                        <span>
                                          {" "}
                                          to minimize video thumbnail viewer...
                                        </span>
                                      </div>
                                      <div className="flex">
                                        <span className="filmSvg mr-2">
                                          (<Slash />)
                                        </span>{" "}
                                        <span>
                                          {" "}
                                          click to select the thumbnail...
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="rightCrossMain"
                                    onClick={() => {
                                      setHinter(false);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="crossIcon"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      stroke-width="2"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              ) : null}

                              {/* /General extended options */}
                              {/* General basic options */}
                              <div
                                id="basic-options"
                                className="compose-options"
                              >
                                {/* Upload action */}
                                <div className="compose-option">
                                  <Camera />
                                  <span>Attach Photos/Videos</span>
                                  <input
                                    style={{ cursor: "pointer" }}
                                    type="file"
                                    multiple
                                    onChange={(e) =>
                                      galleryHandler("choose", e)
                                    }
                                  />
                                </div>
                                <span className="mediaSupported">
                                  Videos must be {`<`}500mb. - Images must be{" "}
                                  {`<`}2mb.
                                  <br /> Videos supported formats .mp4 .mov .MOV
                                  - Image supported formats .png .jpg .jpeg
                                </span>
                              </div>
                              {/* /General basic options */}
                              {/* Hidden Options */}
                              <div className="hidden-options">
                                <div className="target-channels">
                                  {/* Publication Channel */}
                                  <div className="channel">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="channel-icon">
                                        <Bell />
                                      </div>
                                      <div className="channel-name">
                                        Post Type
                                      </div>
                                    </div>
                                    <div style={{ textAlign: "end" }}>
                                      <select
                                        className="customSelect"
                                        value={values.postType}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="postType"
                                        name="postType"
                                      >
                                        <option value="Published">
                                          Published
                                        </option>
                                        <option value="Teaser">Teaser</option>
                                        <option value="Archived">
                                          Archived
                                        </option>
                                        <option value="Scheduled">
                                          Scheduled
                                        </option>
                                      </select>
                                      {errors.postType &&
                                      touched.postType &&
                                      errors.postType ? (
                                        <div className="dangerText">
                                          {errors.postType}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  {/* Publication Channel */}
                                  <div className="channel">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="channel-icon">
                                        <Bell />
                                      </div>
                                      <div className="channel-name">
                                        Categories
                                      </div>
                                    </div>
                                    <div style={{ textAlign: "end" }}>
                                      <select
                                        className="customSelect"
                                        value={values.category}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="category"
                                        name="category"
                                      >
                                        <option>Uncategorized</option>
                                        {cat
                                          .filter(
                                            (f) =>
                                              f.name !== "Uncategorized" &&
                                              f.name !== "Live Streaming"
                                          )
                                          .map((c, k) => {
                                            return (
                                              <option value={c.id} key={k}>
                                                {c.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {errors.category &&
                                      touched.category &&
                                      errors.category ? (
                                        <div className="dangerText">
                                          {errors.category}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  {/* Scheduled Time */}
                                  {values.postType === "Scheduled" ? (
                                    <div className="channel">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div className="channel-icon">
                                          <Clock />
                                        </div>
                                        <div className="channel-name">Time</div>
                                      </div>
                                      <div style={{ textAlign: "end" }}>
                                        <Flatpickr
                                          data-enable-time
                                          className="input is-fade"
                                          name="time"
                                          id="time"
                                          placeholder="Publish Time"
                                          onBlur={handleBlur}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "time",
                                              moment(e[0]).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                            );
                                          }}
                                        />
                                        {errors.time &&
                                        touched.time &&
                                        errors.time ? (
                                          <div className="dangerText">
                                            {errors.time}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {/* Footer buttons */}
                              <div className="more-wrap">
                                {/* Publish button */}
                                {!loading ? (
                                  <button
                                    id="publish-button"
                                    type="sumbit"
                                    className="button is-solid accent-button is-fullwidth"
                                  >
                                    Publish
                                  </button>
                                ) : (
                                  <ProgressBar
                                    completed={percentage}
                                    bgColor="#ca1444"
                                  />
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                  {/* Posts */}
                  <div className="profileTabs">
                    <h2
                      onClick={() => setActivePosts("ActivePosts")}
                      className={
                        activePosts === "ActivePosts" ? "activePostTab" : ""
                      }
                    >
                      Active Posts
                    </h2>
                    <h2
                      onClick={() => setActivePosts("ScheduledPosts")}
                      className={
                        activePosts === "ScheduledPosts" ? "activePostTab" : ""
                      }
                    >
                      Scheduled Posts
                    </h2>
                    <h2
                      onClick={() => setActivePosts("LiveStreamPosts")}
                      className={
                        activePosts === "LiveStreamPosts" ? "activePostTab" : ""
                      }
                    >
                      Live Stream Posts
                    </h2>
                    <h2
                      onClick={() => setActivePosts("archivePosts")}
                      className={
                        activePosts === "archivePosts" ? "activePostTab" : ""
                      }
                    >
                      Archive Posts
                    </h2>
                    <h2
                      onClick={() => setActivePosts("TeaserPosts")}
                      className={
                        activePosts === "TeaserPosts" ? "activePostTab" : ""
                      }
                    >
                      Teaser Posts
                    </h2>
                  </div>
                  <ProfilePosts
                    share={setShare}
                    posts={posts.filter((f) => {
                      if (activePosts === "ActivePosts") {
                        return f.postType === "Published";
                      } else if (activePosts === "ScheduledPosts") {
                        return f.postType === "Scheduled";
                      } else if (activePosts === "LiveStreamPosts") {
                        return f.postType === "CloudRecording";
                      } else if (activePosts === "archivePosts") {
                        return f.postType === "Archived";
                      } else if (activePosts === "TeaserPosts") {
                        return f.postType === "Teaser";
                      }
                    })}
                    token={props.token}
                    setLatest={setLatest}
                  />
                </div>
                <div className="column is-4">
                  {props.user?.creatorType === "Model" ? (
                    <div className="dashboard-box">
                      <h4>Recent Streams</h4>
                      <div className="box-content">
                        <div className="box-comments">
                          {posts
                            .filter((f) => f.postType === "CloudRecording")
                            .sort(function (a, b) {
                              return (
                                new Date(b.created_at) - new Date(a.created_at)
                              );
                            })
                            .map((i, k) => {
                              return (
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="box-comment"
                                  key={k}
                                  onClick={() =>
                                    props.history.push(`/post/${i.urlCode}`)
                                  }
                                >
                                  <img
                                    className="comment-avatar"
                                    src={i.user_profile}
                                  />
                                  <div className="comment-meta">
                                    <span className="meta-title">
                                      {i.userFirstName} {i.userLastName}{" "}
                                      {i.detail}
                                    </span>
                                    <span className="meta-content">
                                      {moment(i.created_at).fromNow()}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* New job widget */}
                  {/* /partials/widgets/new-job-widget.html */}
                  {days.toFixed(0) < 15 ? (
                    <div
                      className="card is-new-job-card has-background-image"
                      data-background="assets/img/illustrations/cards/job-bg.svg"
                    >
                      {props.user?.creatorType === "Fan" ? (
                        <div className="card-body">
                          <div>
                            <div className="job-avatar">
                              <Coffee style={{ color: "#fff" }} />
                            </div>
                            <div className="job-content">
                              <h4>
                                Welcome on board with Tribe of Babes. Feel free
                                to look around and enjoy content by your
                                favorite creators.
                              </h4>
                              <button
                                type="button"
                                className="button light-button"
                                onClick={() => props.history.push("/wallet")}
                              >
                                Add Tokens
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="card-body">
                          <div>
                            <div className="job-avatar">
                              <Coffee style={{ color: "#fff" }} />
                            </div>
                            <div className="job-content">
                              <h4>
                                Welcome on board with Tribe of Babes.
                                Congratulations on becoming a creator. We
                                recommend that you keep your information upto
                                date on <Link to={"/settings"}>settings</Link>{" "}
                                page.
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
                {/* /Right side column */}
              </div>
            ) : null}
          </div>
        </div>
        <div
          id="albums-modal"
          className={`modal albums-modal is-xxl has-light-bg ${
            group ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-content">
            <div className="card">
              <div className="card-heading">
                <h3>New album</h3>
                <div className="button is-solid accent-button fileinput-button">
                  <Plus /> Add pictures/videos
                </div>
                {/* Close X button */}
                <div className="close-wrap">
                  <span className="close-modal">
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="left-section">
                  <div className="album-form">
                    <div className="control">
                      <input
                        type="text"
                        className="input is-sm no-radius is-fade"
                        placeholder="Album name"
                      />
                      <div className="icon">
                        <Camera />
                      </div>
                    </div>
                    <div className="control">
                      <textarea
                        className="textarea is-fade no-radius is-sm"
                        rows={3}
                        placeholder="describe your album ..."
                        defaultValue={""}
                      />
                    </div>
                    <div className="control">
                      <input
                        type="text"
                        className="input is-sm no-radius is-fade"
                        placeholder="Place"
                      />
                      <div className="icon">
                        <MapPin />
                      </div>
                    </div>
                  </div>
                  <div id="album-date" className="album-date">
                    <div className="head">
                      <h4>Change the date</h4>
                      <button
                        type="button"
                        className="button is-solid dark-grey-button icon-button"
                      >
                        <Plus style={{ marginTop: "10px" }} />
                      </button>
                    </div>
                    <p>
                      Set a date for your album. You can always change it later.
                    </p>
                    <div className="control is-hidden">
                      <input
                        id="album-datepicker"
                        type="text"
                        className="input is-sm is-fade"
                        placeholder="Select a date"
                      />
                      <div className="icon">
                        <Calendar />
                      </div>
                    </div>
                  </div>
                  <div id="tagged-in-album" className="tagged-in-album">
                    <div className="head">
                      <h4>Tag friends in this album</h4>
                      <button
                        type="button"
                        className="button is-solid dark-grey-button icon-button"
                      >
                        <Plus />
                      </button>
                    </div>
                    <p>
                      Tag friends in this album. Tagged friends can see photos
                      they are tagged in.
                    </p>
                    <div className="field is-autocomplete is-hidden">
                      <div className="control">
                        <input
                          id="create-album-friends-autocpl"
                          type="text"
                          className="input is-sm is-fade"
                          placeholder="Search for friends"
                        />
                        <div className="icon">
                          <Search />
                        </div>
                      </div>
                    </div>
                    <div id="album-tag-list" className="album-tag-list" />
                  </div>
                  <div className="shared-album">
                    <div className="head">
                      <h4>Allow friends to add photos</h4>
                      <div className="basic-checkbox">
                        <input
                          className="styled-checkbox"
                          id="styled-checkbox-1"
                          type="checkbox"
                          defaultValue="value1"
                        />
                        <label htmlFor="styled-checkbox-1" />
                      </div>
                    </div>
                    <p>
                      Tagged friends will be able to share content inside this
                      album.
                    </p>
                  </div>
                </div>
                <div className="right-section has-slimscroll">
                  <div className="modal-uploader">
                    <div id="actions" className="columns is-multiline no-mb">
                      <div className="column is-12">
                        <span className="button has-icon is-solid grey-button fileinput-button">
                          <Plus />
                        </span>
                        <button
                          type="submit"
                          className="button start is-hidden"
                        >
                          <span>Upload</span>
                        </button>
                        <button
                          type="reset"
                          className="button is-solid grey-button cancel"
                        >
                          <span>Clear all</span>
                        </button>
                        <span className="file-count">
                          <span id="modal-uploader-file-count">0</span> file(s)
                          selected
                        </span>
                      </div>
                      <div className="column is-12 is-hidden">
                        {/* The global file processing state */}
                        <div className="fileupload-process">
                          <div
                            id="total-progress"
                            className="progress progress-striped active"
                            role="progressbar"
                            aria-valuemin={0}
                            aria-valuemax={100}
                            aria-valuenow={0}
                          >
                            <div
                              className="progress-bar progress-bar-success"
                              style={{ width: "0%" }}
                              data-dz-uploadprogress
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns is-multiline" id="previews">
                      <div id="template" className="column is-4 is-template">
                        <div className="preview-box">
                          {/* This is used as the file preview template */}
                          <div className="remove-button" data-dz-remove>
                            <X />
                          </div>
                          <div>
                            <span className="preview">
                              <img
                                src="https://via.placeholder.com/120x120"
                                data-dz-thumbnail
                              />
                            </span>
                          </div>
                          <div className="preview-body">
                            <div className="item-meta">
                              <div>
                                <p className="name" data-dz-name />
                                <strong
                                  className="error text-danger"
                                  data-dz-errormessage
                                />
                              </div>
                              <small className="size" data-dz-size />
                            </div>
                            <div className="upload-item-progress">
                              <div
                                className="progress active"
                                role="progressbar"
                                aria-valuemin={0}
                                aria-valuemax={100}
                                aria-valuenow={0}
                              >
                                <div
                                  className="progress-bar progress-bar-success"
                                  data-dz-uploadprogress
                                />
                              </div>
                            </div>
                            <div className="upload-item-description">
                              <div className="control">
                                <textarea
                                  className="textarea is-small is-fade no-radius"
                                  rows={4}
                                  placeholder="Describe this photo ..."
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="upload-item-actions is-hidden">
                              <button className="button start is-hidden">
                                <span>Start</span>
                              </button>
                              <button
                                data-dz-remove
                                className="button cancel is-hidden"
                              >
                                <span>Cancel</span>
                              </button>
                              <button
                                data-dz-remove
                                className="button delete is-hidden"
                              >
                                <span>Delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                {/* Dropdown menu */}
                <div className="dropdown is-up is-spaced is-modern is-neutral is-right dropdown-trigger">
                  <div>
                    <button className="button" aria-haspopup="true">
                      <Smile className="main-icon" />
                      <span>Friends</span>
                      <ChevronDown />
                    </button>
                  </div>
                  <div className="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                      <a href="#" className="dropdown-item">
                        <div className="media">
                          <Globe />
                          <div className="media-content">
                            <h3>Public</h3>
                            <small>Anyone can see this publication.</small>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item">
                        <div className="media">
                          <Users />
                          <div className="media-content">
                            <h3>Friends</h3>
                            <small>
                              only friends can see this publication.
                            </small>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item">
                        <div className="media">
                          <User />
                          <div className="media-content">
                            <h3>Specific friends</h3>
                            <small>Don't show it to some friends.</small>
                          </div>
                        </div>
                      </a>
                      <hr className="dropdown-divider" />
                      <a className="dropdown-item">
                        <div className="media">
                          <Lock />
                          <div className="media-content">
                            <h3>Only me</h3>
                            <small>Only me can see this publication.</small>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="button is-solid accent-button close-modal"
                >
                  Create album
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="albums-help-modal"
          className={`modal albums-help-modal is-xsmall has-light-bg ${
            groupStart ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-content">
            <div className="card">
              <div className="card-heading">
                <h3>Add Photos</h3>
                {/* Close X button */}
                <div className="close-wrap">
                  <span className="close-modal">
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div
                  className={`content-block ${
                    uploadGroup === 1 ? "is-active" : ""
                  }`}
                >
                  <Album className="albumsvg" />
                  <div className="help-text">
                    <h3>Manage your photos</h3>
                    <p>
                      Lorem ipsum sit dolor amet is a dummy text used by the
                      typography industry and the web industry.
                    </p>
                  </div>
                </div>
                <div
                  className={`content-block ${
                    uploadGroup === 2 ? "is-active" : ""
                  }`}
                >
                  <Upload className="albumsvg" />
                  <div className="help-text">
                    <h3>Upload your photos</h3>
                    <p>
                      Lorem ipsum sit dolor amet is a dummy text used by the
                      typography industry and the web industry.
                    </p>
                  </div>
                </div>
                <div className="slide-dots">
                  <div
                    className={`dot ${uploadGroup === 1 ? "is-active" : ""}`}
                  />
                  <div
                    className={`dot ${uploadGroup === 2 ? "is-active" : ""}`}
                  />
                </div>
                <div className="action">
                  <button
                    type="button"
                    className="button is-solid accent-button next-modal raised"
                    data-modal="albums-modal"
                    onClick={() =>
                      uploadGroup === 1 ? setUploadGroup(2) : groupHandler()
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="share-modal"
          className={`modal share-modal is-xsmall has-light-bg ${
            share ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-content">
            <div className="card">
              <div className="card-heading">
                <div className="dropdown is-primary share-dropdown">
                  <div>
                    <div className="button">
                      <AlignLeft /> <span>Share in your feed</span>{" "}
                      <ChevronDown />
                    </div>
                  </div>
                  <div className="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                      <div className="dropdown-item" data-target-channel="feed">
                        <div className="media">
                          <AlignRight />
                          <div className="media-content">
                            <h3>Share in your feed</h3>
                            <small>Share this publication on your feed.</small>
                          </div>
                        </div>
                      </div>
                      <div
                        className="dropdown-item"
                        data-target-channel="friend"
                      >
                        <div className="media">
                          <Heart />
                          <div className="media-content">
                            <h3>Share in a friend's feed</h3>
                            <small>
                              Share this publication on a friend's feed.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div
                        className="dropdown-item"
                        data-target-channel="group"
                      >
                        <div className="media">
                          <Users />
                          <div className="media-content">
                            <h3>Share in a group</h3>
                            <small>Share this publication in a group.</small>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-item" data-target-channel="page">
                        <div className="media">
                          <File />
                          <div className="media-content">
                            <h3>Share in a page</h3>
                            <small>Share this publication in a page.</small>
                          </div>
                        </div>
                      </div>
                      <hr className="dropdown-divider" />
                      <div
                        className="dropdown-item"
                        data-target-channel="private-message"
                      >
                        <div className="media">
                          <Mail />
                          <div className="media-content">
                            <h3>Share in message</h3>
                            <small>
                              Share this publication in a private message.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Close X button */}
                <div className="close-wrap">
                  <span className="close-modal" onClick={() => setShare(false)}>
                    <X />
                  </span>
                </div>
              </div>
              <div className="share-inputs">
                <div className="field is-autocomplete">
                  <div
                    id="share-to-friend"
                    className="control share-channel-control is-hidden"
                  >
                    <input
                      id="share-with-friend"
                      type="text"
                      className="input is-sm no-radius share-input simple-users-autocpl"
                      placeholder="Your friend's name"
                    />
                    <div className="input-heading">Friend :</div>
                  </div>
                </div>
                <div className="field is-autocomplete">
                  <div
                    id="share-to-group"
                    className="control share-channel-control is-hidden"
                  >
                    <input
                      id="share-with-group"
                      type="text"
                      className="input is-sm no-radius share-input simple-groups-autocpl"
                      placeholder="Your group's name"
                    />
                    <div className="input-heading">Group :</div>
                  </div>
                </div>
                <div
                  id="share-to-page"
                  className="control share-channel-control no-border is-hidden"
                >
                  <div className="page-controls">
                    <div className="page-selection">
                      <div className="dropdown is-accent page-dropdown">
                        <div>
                          <div className="button page-selector">
                            <img
                              src="https://via.placeholder.com/150x150"
                              data-demo-src="assets/img/avatars/hanzo.svg"
                            />{" "}
                            <span>Css Ninja</span> <ChevronDown />
                          </div>
                        </div>
                        <div className="dropdown-menu" role="menu">
                          <div className="dropdown-content">
                            <div className="dropdown-item">
                              <div className="media">
                                <img
                                  src="https://via.placeholder.com/150x150"
                                  data-demo-src="assets/img/avatars/hanzo.svg"
                                />
                                <div className="media-content">
                                  <h3>Css Ninja</h3>
                                  <small>Share on Css Ninja.</small>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-item">
                              <div className="media">
                                <img
                                  src="https://via.placeholder.com/150x150"
                                  data-demo-src="assets/img/icons/logos/nuclearjs.svg"
                                />
                                <div className="media-content">
                                  <h3>NuclearJs</h3>
                                  <small>Share on NuclearJs.</small>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-item">
                              <div className="media">
                                <img
                                  src="https://via.placeholder.com/150x150"
                                  data-demo-src="assets/img/icons/logos/slicer.svg"
                                />
                                <div className="media-content">
                                  <h3>Slicer</h3>
                                  <small>Share on Slicer.</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="alias">
                      <img
                        src="https://via.placeholder.com/150x150"
                        data-demo-src="assets/img/avatars/jenna.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="field is-autocomplete">
                  <div
                    id="share-to-private-message"
                    className="control share-channel-control is-hidden"
                  >
                    <input
                      id="share-with-private-message"
                      type="text"
                      className="input is-sm no-radius share-input simple-users-autocpl"
                      placeholder="Message a friend"
                    />
                    <div className="input-heading">To :</div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="control">
                  <textarea
                    className="textarea comment-textarea"
                    rows={1}
                    placeholder="Say something about this ..."
                    defaultValue={""}
                  />
                  <button className="emoji-button">
                    <Smile />
                  </button>
                </div>
                <div className="shared-publication">
                  <div className="featured-image">
                    <img
                      id="share-modal-image"
                      src="https://via.placeholder.com/1600x900"
                      data-demo-src="assets/img/demo/unsplash/1.jpg"
                    />
                  </div>
                  <div className="publication-meta">
                    <div className="inner-flex">
                      <img
                        id="share-modal-avatar"
                        src="https://via.placeholder.com/300x300"
                        data-demo-src="assets/img/avatars/dan.jpg"
                        data-user-popover={1}
                      />
                      <p id="share-modal-text">
                        Yesterday with <a href="#">@Karen Miller</a> and{" "}
                        <a href="#">@Marvin Stemperd</a> at the{" "}
                        <a href="#">#Rock'n'Rolla</a> concert in LA. Was totally
                        fantastic! People were really excited about this one!
                      </p>
                    </div>
                    <div className="publication-footer">
                      <div className="stats">
                        <div className="stat-block">
                          <Globe />
                          <small>Public</small>
                        </div>
                        <div className="stat-block">
                          <Eye />
                          <small>163 views</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-share-inputs">
                <div
                  id="action-place"
                  className="field is-autocomplete is-dropup is-hidden"
                >
                  <div
                    id="share-place"
                    className="control share-bottom-channel-control"
                  >
                    <input
                      type="text"
                      className="input is-sm no-radius share-input simple-locations-autocpl"
                      placeholder="Where are you?"
                    />
                    <div className="input-heading">Location :</div>
                  </div>
                </div>
                <div
                  id="action-tag"
                  className="field is-autocomplete is-dropup is-hidden"
                >
                  <div
                    id="share-tags"
                    className="control share-bottom-channel-control"
                  >
                    <input
                      id="share-friend-tags-autocpl"
                      type="text"
                      className="input is-sm no-radius share-input"
                      placeholder="Who are you with"
                    />
                    <div className="input-heading">Friends :</div>
                  </div>
                  <div
                    id="share-modal-tag-list"
                    className="tag-list no-margin"
                  />
                </div>
              </div>
              <div className="card-footer">
                <div className="action-wrap">
                  <div className="footer-action" data-target-action="tag">
                    <Plus />
                  </div>
                  <div className="footer-action" data-target-action="place">
                    <MapPin />
                  </div>
                  <div
                    className="footer-action dropdown is-spaced is-neutral dropdown-trigger is-up"
                    data-target-action="permissions"
                  >
                    <div>
                      <Clock />
                    </div>
                    <div className="dropdown-menu" role="menu">
                      <div className="dropdown-content">
                        <a href="#" className="dropdown-item">
                          <div className="media">
                            <Globe />
                            <div className="media-content">
                              <h3>Public</h3>
                              <small>Anyone can see this publication.</small>
                            </div>
                          </div>
                        </a>
                        <a className="dropdown-item">
                          <div className="media">
                            <Users />
                            <div className="media-content">
                              <h3>Friends</h3>
                              <small>
                                only friends can see this publication.
                              </small>
                            </div>
                          </div>
                        </a>
                        <a className="dropdown-item">
                          <div className="media">
                            <User />
                            <div className="media-content">
                              <h3>Specific friends</h3>
                              <small>Don't show it to some friends.</small>
                            </div>
                          </div>
                        </a>
                        <hr className="dropdown-divider" />
                        <a className="dropdown-item">
                          <div className="media">
                            <Lock />
                            <div className="media-content">
                              <h3>Only me</h3>
                              <small>Only me can see this publication.</small>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-wrap">
                  <button
                    type="button"
                    className="button is-solid dark-grey-button close-modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="button is-solid primary-button close-modal"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {profileModal ? (
          <Crop setProfileModal={setProfileModal} action={action} />
        ) : null}
        <Stream stream={stream} setStream={setStream} />
        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
