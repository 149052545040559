import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Clock,
  LogOut,
  Settings,
  Home,
  Users,
  MessageSquare,
  Search,
} from "react-feather";
import cx from "classnames";

import Logo from "../../assets/images/logo.svg";
import LogoMobile from "../../assets/images/logo-mobile.svg";
import LogoutDialog from "../Landing/LogoutDialog";

const Header = ({ user, token, props }) => {
  const history = useHistory();

  const logOutHandler = async () => {
    var axios = require("axios");

    var config = {
      method: "delete",
      url: "https://admin-staging.tribeofbabes.com/api/v1/logout",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config);
    localStorage.removeItem("isAuthenticated");

    history.push("/login");
  };

  const [smallMenu, setSmallMenu] = useState(false);

  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <>
      {user && <LogoutDialog logout={logOutHandler} />}
      <header>
        <nav className="tb-header">
          <div className="container">
            <div className="tb-header__container">
              <div>
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Tribe of Babes"
                    className="tb-header__brand"
                  />
                  <img
                    src={LogoMobile}
                    alt="Tribe of Babes"
                    class="tb-header__brand-mobile"
                  />
                </Link>
              </div>
              <div className="tb-header__menu">
                <div className="tb-header__navigation" />

                {user && (
                  <ul className="tb-header__navigation">
                    <li>
                      <div
                        className="tb-header__navigation tb-header__small-navigation-button"
                        onClick={() => setSmallMenu(!smallMenu)}
                      >
                        <a href="#">Menu</a>
                        <ul
                          className={cx("tb-header__small-navigation", {
                            "tb-header__small-navigation--open": smallMenu,
                          })}
                        >
                          <li>
                            <Link
                              to="/home"
                              className={
                                props?.match.url === "/home" ? "is-active" : ""
                              }
                            >
                              <Home />
                              <span>Home</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/profile"
                              className={
                                props?.match.url === "/profile"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Clock />
                              {user?.creatorType === "Fan" ? (
                                <span>Timeline</span>
                              ) : (
                                <span>Profile</span>
                              )}
                            </Link>
                          </li>
                          {user?.creatorType === "Model" ? (
                            <li>
                              <Link
                                to="/dashboard"
                                className={
                                  props?.match.url === "/dashboard"
                                    ? "is-active"
                                    : ""
                                }
                              >
                                <Clock />
                                <span>Dashboard</span>
                              </Link>
                            </li>
                          ) : null}
                          {user?.creatorType === "Model" ? (
                            <li>
                              <Link
                                to="/followers"
                                className={
                                  props?.match.url === "/followers"
                                    ? "is-active"
                                    : ""
                                }
                              >
                                <Users />
                                <span>Followers</span>
                              </Link>
                            </li>
                          ) : null}
                          <li>
                            <Link
                              to="/followings"
                              className={
                                props?.match.url === "/followings"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Users />
                              <span>Following</span>
                            </Link>
                          </li>
                          {user?.creatorType === "Model" ? (
                            <li>
                              <Link
                                to="/subscribers"
                                className={
                                  props?.match.url === "/subscribers"
                                    ? "is-active"
                                    : ""
                                }
                              >
                                <Users />
                                <span>Subscribers</span>
                              </Link>
                            </li>
                          ) : null}
                          <li>
                            <Link
                              to="/subscriptions"
                              className={
                                props?.match.url === "/subscriptions"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Users />
                              <span>Subscriptions</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/chat"
                              className={
                                props?.match.url === "/chat" ? "is-active" : ""
                              }
                            >
                              <MessageSquare />
                              <span>Chat</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/search"
                              className={
                                props?.match.url === "/search"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Search />
                              <span>Search</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/settings"
                              className={
                                props?.match.url === "/settings"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Settings />
                              <span>Settings</span>
                            </Link>
                          </li>
                          <li>
                            <a onClick={() => logOutHandler()}>
                              <LogOut />
                              <span>Logout</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="#" onClick={logOutHandler}>
                        Logout
                      </a>
                    </li>
                  </ul>
                )}
                {!user && (
                  <ul className="tb-header__navigation">
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                    <li>
                      <Link to="/signup">Signup</Link>
                    </li>
                  </ul>
                )}
              </div>
              <div
                class={cx("tb-header__mobile-menu-button", {
                  "tb-header__mobile-menu-button--open": mobileMenu,
                })}
                onClick={() => setMobileMenu(!mobileMenu)}
              >
                <div class={cx("tb-header__mobile-menu")}>
                  <ul class="tb-header__navigation-mobile">
                    {user && (
                      <>
                        <li>
                          <Link
                            to="/home"
                            className={
                              props?.match.url === "/home" ? "is-active" : ""
                            }
                          >
                            <Home />
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/profile"
                            className={
                              props?.match.url === "/profile" ? "is-active" : ""
                            }
                          >
                            <Clock />
                            {user?.creatorType === "Fan" ? (
                              <span>Timeline</span>
                            ) : (
                              <span>Profile</span>
                            )}
                          </Link>
                        </li>
                        {user?.creatorType === "Model" ? (
                          <li>
                            <Link
                              to="/dashboard"
                              className={
                                props?.match.url === "/dashboard"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Clock />
                              <span>Dashboard</span>
                            </Link>
                          </li>
                        ) : null}
                        {user?.creatorType === "Model" ? (
                          <li>
                            <Link
                              to="/followers"
                              className={
                                props?.match.url === "/followers"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Users />
                              <span>Followers</span>
                            </Link>
                          </li>
                        ) : null}
                        <li>
                          <Link
                            to="/followings"
                            className={
                              props?.match.url === "/followings"
                                ? "is-active"
                                : ""
                            }
                          >
                            <Users />
                            <span>Following</span>
                          </Link>
                        </li>
                        {user?.creatorType === "Model" ? (
                          <li>
                            <Link
                              to="/subscribers"
                              className={
                                props?.match.url === "/subscribers"
                                  ? "is-active"
                                  : ""
                              }
                            >
                              <Users />
                              <span>Subscribers</span>
                            </Link>
                          </li>
                        ) : null}
                        <li>
                          <Link
                            to="/subscriptions"
                            className={
                              props?.match.url === "/subscriptions"
                                ? "is-active"
                                : ""
                            }
                          >
                            <Users />
                            <span>Subscriptions</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/chat"
                            className={
                              props?.match.url === "/chat" ? "is-active" : ""
                            }
                          >
                            <MessageSquare />
                            <span>Chat</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/search"
                            className={
                              props?.match.url === "/search" ? "is-active" : ""
                            }
                          >
                            <Search />
                            <span>Search</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/settings"
                            className={
                              props?.match.url === "/settings"
                                ? "is-active"
                                : ""
                            }
                          >
                            <Settings />
                            <span>Settings</span>
                          </Link>
                        </li>
                        <li>
                          <a onClick={() => logOutHandler()}>
                            <LogOut />
                            <span>Logout</span>
                          </a>
                        </li>
                      </>
                    )}
                    {!user && (
                      <>
                        <li>
                          <Link to="/login">Login</Link>
                        </li>
                        <li>
                          <Link to="/signup">Signup</Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
