import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import Posts from "../Elements/Posts";
import Footer from "./Footer";
import BigInput from "../Elements/BigInput";
import BigSelect from "../Elements/BigSelect";
import Button from "../Elements/Button";

const Search = (props) => {
  const [search, setSearch] = React.useState("");
  const [searchType, setSearchType] = React.useState("Users");
  const [posts, setPosts] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [sort, setSort] = React.useState(10);

  const searchHandler = () => {
    setSort(0);
    if (searchType === "Users") {
      userHandler();
    } else {
      postHandler();
    }
  };

  const postHandler = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/posts?search=${search}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.response.data.length === 0) {
          toast.error("No Posts Found!");
          setLoader(false);
        } else {
          setPosts(response.data.response.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.response.message);
        setLoader(false);
      });
  };

  const userHandler = async () => {
    setLoader(true);
    try {
      var config = {
        method: "get",
        url: `https://admin-staging.tribeofbabes.com/api/v1/search/users?search=${search}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      };

      const response = await axios(config);
      setUsers(response.data.response.data);
      if (!response.data.response.data.length) {
        toast.error("No Creators Found!");
      }
      setLoader(false);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    if (props.token) {
      userHandler();
    }
  }, [props.token]);

  const loadMoreHandler = () => {
    if (searchType === "Users") {
      userLoadMore();
    } else {
      postLoadMore();
    }
  };

  const postLoadMore = () => {
    if (sort < posts.length) {
      setSort(sort + 10);
    }
  };

  const userLoadMore = () => {
    if (sort < users.length) {
      setSort(sort + 10);
    }
  };

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        <div style={{ padding: "50px 0" }}>
          <div className="container sidebar-boxed">
            <div className="columns is-multiline">
              <div className="column is-4">
                <BigInput
                  placeholder="Search Creators"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="column is-4">
                <BigSelect onChange={(e) => setSearchType(e.target.value)}>
                  <option value="Users">Creators</option>
                  <option value="Posts">Posts</option>
                </BigSelect>
              </div>
              <div className="column is-4">
                <Button
                  className="button is-solid primary-button raised is-rounded is-fullwidth"
                  onClick={() => searchHandler()}
                >
                  {loader ? (
                    <CircularProgress size="1rem" style={{ color: "#fff" }} />
                  ) : (
                    "Search Creators"
                  )}
                </Button>
              </div>
            </div>
            {searchType === "Posts" ? (
              <div className="posts-feed-wrapper is-2x">
                <Posts
                  navigate={props.history}
                  posts={posts.filter((f) => f.postType !== "Live")}
                />
              </div>
            ) : (
              <div className="card-row">
                {users
                  .filter((f) => f.creatorType === "Model")
                  .map((i, k) => {
                    return (
                      <div className="card-flex friend-card" key={k}>
                        <div className="img-container">
                          <img className="avatar" src={i.image} />
                        </div>
                        <div className="friend-info">
                          <h3>{i.nickName}</h3>
                        </div>
                        <Button
                          className="button is-solid primary-button raised is-rounded is-fullwidth"
                          style={{ marginTop: "16px" }}
                          onClick={() =>
                            props.history.push(`/user/${i.userName}`)
                          }
                        >
                          View Profile
                        </Button>
                      </div>
                    );
                  })}
              </div>
            )}
            {searchType === "Users" && users.length > 10 ? (
              <div
                style={{
                  textAlign: "center",
                  margin: "10px auto 0",
                  width: "240px",
                }}
              >
                <Button
                  className="loadMoreSearch"
                  onClick={() => loadMoreHandler()}
                >
                  load more
                </Button>
              </div>
            ) : null}
            {searchType === "Posts" && posts.length > 10 ? (
              <div
                style={{
                  textAlign: "center",
                  margin: "10px auto 0",
                  width: "240px",
                }}
              >
                <Button
                  className="loadMoreSearch"
                  onClick={() => loadMoreHandler()}
                >
                  load more
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    token: state.StreamApp.token,
  };
};
export default connect(mapStateToProps)(Search);
