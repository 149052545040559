import "./App.css";
import "./Custom.css";
import "./styles/app.scss";
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import React from "react";
import ReactGA from "react-ga";
import Routes from "./MainRoutes";
const TRACKING_ID = "G-P7SMVF0TPE";

ReactGA.initialize(TRACKING_ID);

const App = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const sidebarHandler = React.useCallback(() => {
    return {
      type: "SIDEBAR",
      value: true,
    };
  }, []);

  React.useMemo(() => {
    if (window.innerWidth < 800) {
      store.dispatch(sidebarHandler());
    }
  }, []);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
      <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
    </Provider>
  );
};

export default App;
