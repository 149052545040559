import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Elements/Button";

const ForCreators = () => {
  return (
    <div className="tb-for-creators container">
      <div className="tb-for-creators__title">CREATORS</div>
      <div className="tb-for-creators__subtitle">STAY CONNECTED</div>
      <div className="tb-for-creators__description">
        Our platform will let you connect with your friends, your family, and
        your fans.
      </div>
      <div className="tb-for-creators__button">
        <Link to="/login">
          <Button>Create your Account</Button>
        </Link>
      </div>
    </div>
  );
};

export default ForCreators;
