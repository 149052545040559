import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import Header from "./Header";
import axios from "axios";
import Footer from "./Footer";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "../Elements/Button";

const Subscriptions = (props) => {
  const [friends, setFriends] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getFriends = () => {
    setIsLoading(false);
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/subscribers/users",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setFriends(response.data.response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getFriends();
    return () => {
      setFriends([]);
    };
  }, [props.token]);

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        {/* Container */}
        {isLoading ? (
          <div
            className={cx({
              "tb-empty-list": !friends.length,
            })}
          >
            <div
              id="posts-feed"
              className={cx("container", "sidebar-boxed")}
              data-open-sidebar
              data-page-title="Friends"
            >
              {!friends.length ? (
                <div className="tb-empty-list__wrapper">
                  <h4 style={{ textAlign: "center" }}>
                    Please search for creators. You have not subcribed to any
                    creator
                  </h4>
                  <Button
                    style={{ maxWidth: "240px", marginTop: "44px" }}
                    className="button is-solid primary-button raised is-rounded"
                    onClick={() => props.history.push("/search")}
                  >
                    Search Creators
                  </Button>
                </div>
              ) : (
                <div
                  id="friends-page"
                  className="friends-wrapper is-static main-container"
                >
                  {/*First tab*/}
                  <div id="all-friends" className="card-row-wrap is-active">
                    <div className="card-row">
                      {/* /partials/pages/friends/friend-lists/all-friends.html */}
                      {/*Friend*/}
                      {friends.map((i, k) => {
                        return (
                          <div className="card-flex friend-card" key={k}>
                            <div className="img-container">
                              <img
                                className="avatar"
                                src={i.subscriber.imageUrl}
                              />
                              {/* <img className="country" src="assets/img/icons/flags/united-states-of-america.svg"   /> */}
                            </div>
                            <div className="friend-info">
                              <h3>{i.subscriber.nickName}</h3>
                            </div>

                            <div
                              style={{ textAlign: "center", marginTop: "10px" }}
                            >
                              <button
                                className="button is-solid primary-button raised is-rounded"
                                onClick={() =>
                                  props.history.push(
                                    `/user/${i.subscriber.userName}`
                                  )
                                }
                              >
                                View Profile
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size="30px" style={{ color: "#ca1444" }} />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
