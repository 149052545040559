import React from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, Form } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import SignupLayout from "./Layout/SignupLayout";
import BigInput from "../Elements/BigInput";
import Button from "../Elements/Button";

const signUp = yup.object().shape({
  email: yup.string().email().required("email required!"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("password required!"),
  firstName: yup.string().required("first name required!"),
  lastName: yup.string().required("last name required!"),
  nickname: yup.string().required("nick name required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
const SignUp = (props) => {
  const [loading, setLoading] = React.useState(false);

  const userProfile = async (token) => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
        props.history.push("/home");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const SignUpHandler = async (values) => {
    setLoading(true);
    try {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("email", values.email);
      data.append("firstName", values.firstName);
      data.append("lastName", values.lastName);
      data.append("password", values.password);
      data.append("userName", values.nickname);
      data.append("nickName", values.nickname);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/signup",
        headers: {
          Accept: "application/json",
        },
        data: data,
      };

      const Response = await axios(config);
      setLoading(false);
      localStorage.setItem("isAuthenticated", "true");
      props.tokenHandler(Response.data.response.data.auth.jwt);
      await userProfile(Response.data.response.data.auth.jwt);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  return (
    <SignupLayout>
      <h1>Register</h1>

      <Formik
        onSubmit={(values) => {
          SignUpHandler(values);
        }}
        initialValues={{
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          address: "",
          image: undefined,
          phoneNumber: "",
          confirmPassword: "",
          nickname: "",
        }}
        validationSchema={signUp}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="tb-signup-layout__form">
            <div className="tb-signup-layout__form-row">
              <BigInput
                name="firstName"
                placeholder="First name"
                autocomplete="given-name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName && touched.firstName}
                description={
                  errors.firstName && touched.firstName ? errors.firstName : ""
                }
              />
            </div>

            <div className="tb-signup-layout__form-row">
              <BigInput
                name="lastName"
                placeholder="Last name"
                autocomplete="family-name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName && touched.lastName}
                description={
                  errors.lastName && touched.lastName ? errors.lastName : ""
                }
              />
            </div>

            <div className="tb-signup-layout__form-row">
              <BigInput
                name="nickname"
                placeholder="Nick name"
                autocomplete="nickname"
                value={values.nickname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.nickname && touched.nickname}
                description={
                  errors.nickname && touched.nickname ? errors.nickname : ""
                }
              />
            </div>

            <div className="tb-signup-layout__form-row">
              <BigInput
                type="email"
                name="email"
                autocomplete="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                description={errors.email && touched.email ? errors.email : ""}
              />
            </div>

            <div className="tb-signup-layout__form-row">
              <BigInput
                type="password"
                name="password"
                placeholder="Password"
                autocomplete="new-password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                description={
                  errors.password && touched.password ? errors.password : ""
                }
              />
            </div>

            <div className="tb-signup-layout__form-row">
              <BigInput
                type="password"
                name="confirmPassword"
                placeholder="Repeat password"
                autocomplete="new-password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirmPassword && touched.confirmPassword}
                description={
                  errors.confirmPassword && touched.confirmPassword
                    ? errors.confirmPassword
                    : ""
                }
              />
            </div>

            <div className="tb-signup-layout__button">
              <Button disabled={loading} color="secondary">
                {loading ? (
                  <CircularProgress size="1rem" style={{ color: "#fff" }} />
                ) : (
                  "Create Account"
                )}
              </Button>
            </div>

            <div className="tb-signup-layout__links">
              <Link to="/login">Have an account? Sign In</Link>
              <Link to="/forgot_password">Forgot Password</Link>
            </div>
          </Form>
        )}
      </Formik>
    </SignupLayout>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    userHandler: (value) => dispatch({ type: "USER", value: value }),
    tokenHandler: (value) => dispatch({ type: "TOKEN", value: value }),
  };
};
export default connect(null, mapDispatchToProps)(SignUp);
