import React from "react";
import cx from "classnames";

const BigSelect = ({ className, error, description, children, ...props }) => {
  return (
    <div className="tb-big-select">
      <select className={cx("tb-big-select__input", className)} {...props}>
        {children}
      </select>
      {description && (
        <div
          className={cx("tb-big-select__description", {
            "tb-big-select__description--error": error,
          })}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default BigSelect;
