import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const Consent = (props) => {
  const [agree, setAgree] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const agreeHandler = () => {
    setLoading(true);
    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/agreed-term",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function () {
        userProfile();
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const userProfile = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
        props.history.push("/home");
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <div className="consent">
      <h1>Welcome Creator!</h1>
      <h1>
        To proceed further with the profile, please accept the creator terms and
        conditions.
      </h1>
      <br />
      <br />
      <h1>CONTENT CREATOR AGREEMENT</h1>
      <br />
      <br />
      <p>
        “Creator” means a User who has set up their Community Tribe of Babes
        account as a Creator account to post Content on Community Tribe of Babes
        to be viewed by other Users.
        <br />
        <br />
        <b>Overview:</b>
        As a creator on our site (https://community.tribeofbabes.com/), you
        agree to the following terms when you verified your account.
        <br />
        <br />
        <b>Agreement Terms:</b>
        <ul>
          <li>
            We (TOB) prohibit any activity that is illegal or otherwise violates
            the standard
          </li>
          <li>
            We (TOB) require the content creator to obtain and keep on record
            written consent form from all persons depicted in the content
            specific to the following areas:
          </li>
          <li>
            Consent to allow for the public sharing of the content to the
            community.tribeofbabes.com website
          </li>
          <li>
            {" "}
            Every creator who registers as a “Model” on Community Tribe of Babes
            mush submit documentation to prove they are 18 years old and verify
            their age. Every “Model” that sends us (TOB) documents agrees to
            give us permission to see their documents.
          </li>
          <li>
            Content can be downloaded or screen recorded. Anyone who registers
            allows this.
          </li>
          <li>
            Content can be downloaded or screen recorded. Anyone who registers
            allows this.
          </li>
        </ul>
        <br />
        <br />
        <b>Contract Termination:</b>
        <br />
        On our website (community.tribeofbabes.com) the contract period will
        continue as long as you do not close your account.
        <br />
        Confidentiality: This agreement is strictly confidential and only
        authorized personal are allowed to see.
      </p>
      <div
        style={{ marginTop: "20px", display: "block", marginBottom: "20px" }}
      >
        <input
          type="checkbox"
          id="scales"
          name="scales"
          onChange={(e) => setAgree(e.target.checked)}
        />
        <label for="scales" style={{ marginLeft: "10px" }}>
          I agree to terms
        </label>
      </div>
      <button
        className="customBtn"
        disabled={!agree}
        onClick={() => agreeHandler()}
      >
        {loading ? (
          <CircularProgress size="30px" style={{ color: "#FFF" }} />
        ) : (
          "Submit"
        )}
      </button>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userHandler: (value) => dispatch({ type: "USER", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Consent);
