import React from "react";
import { X, Bookmark, Bell, Flag, Play } from "react-feather";
import {
  Heart,
  Link2,
  MessageCircle,
  MoreVertical,
  Trash2,
  DollarSign,
} from "react-feather";
import { Formik, Form } from "formik";
import { ReactComponent as Bubble } from "../images/bubble.svg";
import * as yup from "yup";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import Slider from "react-slick";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { connect } from "react-redux";
import Live from "../images/stream-join.jpg";
import Record from "../images/stream-record.jpg";

const tip = yup.object().shape({
  tip: yup.number().required(),
});
const MasonaryProfilePosts = (props) => {
  console.log({ props });
  const [comment, setComment] = React.useState(-1);
  const [commentText, setCommentText] = React.useState("");
  const [reply, setReply] = React.useState(false);
  const [replyOpen, setReplyOpen] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [likeLoader, setLikeLoader] = React.useState(-1);
  const tipModal = () => {
    setOpen(!open);
  };
  const tipHandler = (values) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      toast.success(`${values.tip} Tokens Tipped`);
    }, 2000);
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const postHandler = React.useCallback((post) => {
    if (post.postType === "Live") {
      props.navigate.push(`/stream/${post.urlCode}`);
    } else {
      props.navigate.push(`/post/${post.urlCode}`);
    }
  }, []);

  const commentHandler = async (i) => {
    setLoading(true);
    try {
      var data = new FormData();
      data.append("postId", i.id);
      data.append("comment", commentText);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/comments",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      toast.success("Comment Added");
      setLoading(false);
      setCommentText("");
      props.setLatest(true);
    } catch (e) {
      toast.error(e.response.data.response.message);
      setLoading(false);
    }
  };
  const replyToggle = (commentKey) => {
    if (replyOpen === commentKey) {
      setReplyOpen(-1);
    } else {
      setReplyOpen(commentKey);
    }
  };
  const replyHandler = async (comment, i) => {
    try {
      var data = new FormData();
      data.append("postId", i.id);
      data.append("comment", reply);
      data.append("commentId", comment.id);

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/sub-comments",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      toast.success("Reply Added");
      setReply("");
      props.setLatest(true);
    } catch (e) {
      toast.error(e.response.data.response.message);
    }
  };
  const deleteReply = (subComment) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/sub-comments/${subComment.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        toast.success("Reply Deleted");
        props.setLatest(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const deleteComment = (comment) => {
    var config = {
      method: "delete",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/comments/${comment.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        toast.success("Comment Deleted");
        props.setLatest(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const likeHandler = (i) => {
    setLikeLoader(i.id);
    var data = new FormData();
    data.append("postId", i.id);
    data.append("type", "post");

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/likes/posts",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function () {
        props.setLatest(true);
        setLikeLoader(-1);
      })
      .catch(function (error) {
        console.log(error);
        setLikeLoader(-1);
      });
  };

  return (
    <div className="customGrid masonaryTwoColum">
      {props.posts
        .filter((filter) => filter.postType !== "Archived")
        .sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        .map((i, k) => {
          let user = props.user;
          console.log({ i, k, comment, user });
          return (
            <div
              className="gridColumn card-masonary"
              key={k}
              onClick={() => postHandler(i)}
              style={{ cursor: "pointer" }}
            >
              <div
                className={`card post-feed-item ${
                  comment === k ? "is-hidden" : ""
                }`}
              >
                <div className="card-image">
                  <div className="post-image">
                    {i.postType !== "Live" &&
                    i.postType !== "CloudRecording" ? (
                      <Slider {...settings}>
                        {i.postMedias.map((media, mediaKey) => {
                          return (
                            <React.Fragment key={mediaKey}>
                              {media.type === "image" ? (
                                <div className="post-media">
                                  <img src={media.url} />
                                </div>
                              ) : (
                                <div className="post-media">
                                  {media.featuredThumbnail !== "" ? (
                                    <video
                                      muted
                                      style={{ width: "300px" }}
                                      poster={media.featuredThumbnail}
                                    >
                                      <source src={`${media.url}#t=15`} />
                                    </video>
                                  ) : (
                                    <video muted style={{ width: "300px" }}>
                                      <source src={`${media.url}#t=15`} />
                                    </video>
                                  )}
                                  <Play
                                    style={{
                                      color: "#ca1444",
                                      position: "absolute",
                                    }}
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Slider>
                    ) : i.postType === "Live" ? (
                      <img src={Live} />
                    ) : i.postType === "CloudRecording" ? (
                      <img src={Record} />
                    ) : null}
                  </div>
                </div>
                <div className="card-content disable-flex">
                  <div className="media">
                    {/* <div className="media-left">
                                        <div className="post-avatar is-smaller">
                                            <img className="avatar" src={i.user_profile} />
                                        </div>
                                    </div> */}
                    <div className="media-content is-smaller">
                      {/* <span>
                                            {i.nickName}
                                        </span> */}
                      <span style={{ marginTop: "5px" }}>{i.detail}</span>
                      <span>{moment(i.created_at).fromNow()}</span>
                    </div>
                  </div>
                  <div className="card-footer customPostFooter is-hidden">
                    {/* Followers */}
                    {i.likes.length ? (
                      <div style={{ display: "flex" }}>
                        <div className="likers-group">
                          {i.likes
                            .slice(i.likes.length - 4, i.likes.length - 1)
                            .reverse()
                            .slice(0, 2)
                            .map((likeImg, likeId) => {
                              return (
                                <img key={likeId} src={likeImg.userProfile} />
                              );
                            })}
                        </div>
                        <div className="likers-text">
                          <p>
                            {i.likes
                              .slice(i.likes.length - 4, i.likes.length - 1)
                              .reverse()
                              .map((like, likeKey) => {
                                return (
                                  <a key={likeKey}>
                                    {like.nickName}{" "}
                                    {likeKey < 3 - 1 ? ", " : "..."}
                                  </a>
                                );
                              })}
                          </p>
                          <p>liked this</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p>0 Likes</p>
                      </div>
                    )}

                    <div className="post-actions chlid-div">
                      {/* /partials/pages/feed/buttons/feed-post-actions.html */}
                      <div style={{ display: "flex" }}>
                        <div className="like-wrapper ">
                          {likeLoader !== i.id ? (
                            <a
                              className="like-button"
                              onClick={() => likeHandler(i)}
                            >
                              {i.likes.find(
                                (like) => like.userId === i.userId
                              ) ? (
                                <Heart
                                  style={{ stroke: "#ca1444" }}
                                  className="mdi mdi-heart not-liked bouncy likedPost"
                                />
                              ) : (
                                <Heart
                                  style={{ stroke: "#ca1444" }}
                                  className="mdi mdi-heart not-liked"
                                />
                              )}
                              <span className="like-overlay" />
                            </a>
                          ) : (
                            <CircularProgress
                              size="1rem"
                              style={{ color: "#ca1444" }}
                            />
                          )}
                        </div>
                        <div className="fab-wrapper is-share">
                          <CopyToClipboard
                            text={`https://community.tribeofbabes.com/post/${i.urlCode}`}
                            onCopy={() => toast.success("Link Copied")}
                          >
                            <a className="small-fab share-fab modal-trigger">
                              <Link2 />
                            </a>
                          </CopyToClipboard>
                        </div>
                        <div
                          className="fab-wrapper is-comment"
                          onClick={() => setComment(k)}
                        >
                          <a className="small-fab">
                            <MessageCircle />
                          </a>
                        </div>
                        {i.userId !== props.user.id ? (
                          <div
                            className="fab-wrapper is-comment"
                            onClick={() => tipModal(i)}
                          >
                            <a className="small-fab">
                              <DollarSign />
                            </a>
                          </div>
                        ) : null}
                      </div>

                      <div
                        className="social-count"
                        style={{ marginTop: "10px", justifyContent: "end" }}
                      >
                        <div className="likes-count">
                          <Heart />
                          <span>{i.likes.length}</span>
                        </div>
                        <div className="comments-count">
                          <MessageCircle />
                          <span>{i.comments.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Post #6 comments */}
              <div
                className={`card post-feed-item comments-wrap ${
                  comment === k ? "" : "is-hidden"
                }`}
              >
                {/* Header */}
                <div className="comments-heading">
                  <h4>
                    Comments
                    <small>({i.comments.length})</small>
                  </h4>
                  <div
                    className="close-comments"
                    onClick={() => setComment(-1)}
                  >
                    <X />
                  </div>
                </div>
                {/* /Header */}
                {/* Comments body */}
                <div className="comments-body has-slimscroll">
                  {!i.comments.length ? (
                    <div className="comments-placeholder">
                      <Bubble style={{ maxWidth: "80px" }} />
                      <h3>Nothing in here yet</h3>
                      <p>Be the first to post a comment.</p>
                    </div>
                  ) : (
                    <React.Fragment>
                      {i.comments.map((comment, commentKey) => {
                        return (
                          <div className="media is-comment" key={commentKey}>
                            <div className="media-left">
                              <div className="image">
                                <img src={comment.userProfile} />
                              </div>
                            </div>
                            <div className="media-content">
                              <a>
                                {comment.userFirstName} {comment.userLastName}
                              </a>
                              <span className="time">
                                {moment(comment.created_at).fromNow()}
                              </span>
                              <p>{comment.comment}</p>
                              <div className="controls controls-d-block">
                                <div className="controls-actions-custom">
                                  <div className="reply">
                                    <a onClick={() => replyToggle(commentKey)}>
                                      {replyOpen === commentKey
                                        ? "Close"
                                        : "Reply"}
                                    </a>
                                  </div>
                                  <div className="reply">
                                    <a onClick={() => deleteComment(comment)}>
                                      Delete
                                    </a>
                                  </div>
                                </div>

                                {replyOpen === commentKey ? (
                                  <div className="media post-comment has-emojis">
                                    {/* Textarea */}
                                    <div className="media-content">
                                      <div className="field">
                                        <p className="control">
                                          <textarea
                                            className="textarea comment-textarea"
                                            rows={5}
                                            placeholder="Write a reply..."
                                            onChange={(e) =>
                                              setReply(e.target.value)
                                            }
                                          />
                                        </p>
                                      </div>
                                      {/* Additional actions */}
                                      <div className="actions">
                                        <div className="toolbar">
                                          <a
                                            className="button is-solid primary-button raised"
                                            onClick={() =>
                                              replyHandler(comment, i)
                                            }
                                            style={{
                                              color: "#fff",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            {loading ? (
                                              <CircularProgress
                                                size="1rem"
                                                style={{ color: "#fff" }}
                                              />
                                            ) : (
                                              "Post Reply"
                                            )}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {comment.subComments.map(
                                (subComment, subCommentkey) => {
                                  return (
                                    <div
                                      className="media is-comment"
                                      key={subCommentkey}
                                    >
                                      <div className="media-left">
                                        <div className="image">
                                          <img src={subComment.userProfile} />
                                        </div>
                                      </div>
                                      <div className="media-content">
                                        <a>{subComment.nickName}</a>
                                        <span className="time">
                                          {moment(
                                            subComment.created_at
                                          ).fromNow()}
                                        </span>
                                        <p>{subComment.comment}</p>

                                        <div className="controls">
                                          <div className="reply">
                                            <a
                                              onClick={() =>
                                                deleteReply(subComment)
                                              }
                                            >
                                              Delete
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className="media-right">
                              <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                                <div>
                                  <div className="button">
                                    <i data-feather="more-vertical"></i>
                                  </div>
                                </div>
                                <div className="dropdown-menu" role="menu">
                                  <div className="dropdown-content">
                                    <a className="dropdown-item">
                                      <div className="media">
                                        <i data-feather="x"></i>
                                        <div className="media-content">
                                          <h3>Hide</h3>
                                          <small>Hide this comment.</small>
                                        </div>
                                      </div>
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    <a href="#" className="dropdown-item">
                                      <div className="media">
                                        <i data-feather="flag"></i>
                                        <div className="media-content">
                                          <h3>Report</h3>
                                          <small>Report this comment.</small>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )}
                </div>
                {/* /Comments body */}
                {/* Comments footer */}
                <div className="card-footer">
                  <div className="media post-comment has-emojis">
                    {/* Textarea */}
                    <div className="media-content">
                      <div className="field">
                        <p className="control">
                          <textarea
                            className="textarea comment-textarea"
                            rows={5}
                            placeholder="Write a comment..."
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                          />
                        </p>
                      </div>
                      {/* Additional actions */}
                      <div className="actions">
                        <div className="toolbar">
                          <a
                            className="button is-solid primary-button raised"
                            onClick={() => commentHandler(i)}
                          >
                            {loading ? (
                              <CircularProgress
                                size="1rem"
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              "Post Comment"
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Comments footer */}
              </div>
              {/* /Post #6 comments */}
            </div>
          );
        })}

      <div
        className={`modal is-xsmall has-light-bg share-modal ${
          open ? "is-active" : ""
        }`}
      >
        <div className="modal-background">
          <div className="modal-content tip-modal">
            <div className="card">
              <div className="card-heading">
                <h3>Tip</h3>
                <div className="close-wrap">
                  <span className="close-modal" onClick={() => tipModal()}>
                    <X />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ tip: "" }}
                  validationSchema={tip}
                  onSubmit={(values, { resetForm }) => {
                    tipHandler(values);
                    resetForm();
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <Form>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="number"
                            placeholder="10"
                            name="tip"
                            id="tip"
                            value={values.tip}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.tip && touched.tip && errors.tip ? (
                            <div className="dangerText">{errors.tip}</div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        className="button is-solid primary-button raised is-rounded is-fullwidth"
                        disabled={loading}
                        type="submit"
                      >
                        {loading ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.StreamApp.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateHandler: (value) => dispatch({ type: "UPDATE", value: value }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MasonaryProfilePosts);
