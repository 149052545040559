import React from "react";
import {
  Clock,
  LogOut,
  Settings,
  Home,
  ArrowLeft,
  Users,
  MessageSquare,
  Search,
} from "react-feather";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
const Sidebar = (props) => {
  return null;

  const logOutHandler = async () => {
    var axios = require("axios");

    var config = {
      method: "delete",
      url: "https://admin-staging.tribeofbabes.com/api/v1/logout",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config);
    localStorage.removeItem("isAuthenticated");
    props.props.history.push("/login");
  };
  return (
    <React.Fragment>
      <div className={`sidebar-v1 ${props.sidebar ? "is-fold" : ""}`}>
        <div className="top-section">
          <button
            id="sidebar-v1-close"
            className="close-button"
            onClick={() => props.sidebarHandler(!props.sidebar)}
          >
            <ArrowLeft />
          </button>
          <div className="user-block">
            <img
              className="avatar"
              style={{ display: "block", objectFit: "cover" }}
              src={props.user?.image}
            />
            <div className="meta">
              <span>{props.user?.nickName}</span>
              <span>
                {props.user?.creatorType === "Model" ? "Creator" : "Fan"}
              </span>
            </div>
          </div>
        </div>
        <div className="bottom-section">
          <ul>
            <li>
              <Link
                to="/home"
                className={
                  props.props?.match.url === "/home" ? "is-active" : ""
                }
              >
                <Home />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                className={
                  props.props?.match.url === "/profile" ? "is-active" : ""
                }
              >
                <Clock />
                {props.user?.creatorType === "Fan" ? (
                  <span>Timeline</span>
                ) : (
                  <span>Profile</span>
                )}
              </Link>
            </li>
            {props.user?.creatorType === "Model" ? (
              <li>
                <Link
                  to="/dashboard"
                  className={
                    props.props?.match.url === "/dashboard" ? "is-active" : ""
                  }
                >
                  <Clock />
                  <span>Dashboard</span>
                </Link>
              </li>
            ) : null}
            {props.user?.creatorType === "Model" ? (
              <li>
                <Link
                  to="/followers"
                  className={
                    props.props?.match.url === "/followers" ? "is-active" : ""
                  }
                >
                  <Users />
                  <span>Followers</span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                to="/followings"
                className={
                  props.props?.match.url === "/followings" ? "is-active" : ""
                }
              >
                <Users />
                <span>Following</span>
              </Link>
            </li>
            {props.user?.creatorType === "Model" ? (
              <li>
                <Link
                  to="/subscribers"
                  className={
                    props.props?.match.url === "/subscribers" ? "is-active" : ""
                  }
                >
                  <Users />
                  <span>Subscribers</span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                to="/subscriptions"
                className={
                  props.props?.match.url === "/subscriptions" ? "is-active" : ""
                }
              >
                <Users />
                <span>Subscriptions</span>
              </Link>
            </li>
            <li>
              <Link
                to="/chat"
                className={
                  props.props?.match.url === "/chat" ? "is-active" : ""
                }
              >
                <MessageSquare />
                <span>Chat</span>
              </Link>
            </li>
            <li>
              <Link
                to="/search"
                className={
                  props.props?.match.url === "/search" ? "is-active" : ""
                }
              >
                <Search />
                <span>Search</span>
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                className={
                  props.props?.match.url === "/settings" ? "is-active" : ""
                }
              >
                <Settings />
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <a onClick={() => logOutHandler()}>
                <LogOut />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
