import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { connect } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import Footer from "./Footer";
import moment from "moment";
import { Link } from "react-router-dom";
import Logo from "../images/Logo.png";

const complaint = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  url: yup.string().url().required(),
  complain: yup.string().required(),
});

const AppealsForm = (props) => {
  const complaintHandler = React.useCallback((values, setSubmitting) => {
    var data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("url", values.url);
    data.append("complain", values.complain);

    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/complaint",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success(response.data.response.message);
        setSubmitting(false);
      })
      .catch(function (error) {
        toast.success(error.response.data.response.message);
        setSubmitting(false);
      });
  }, []);
  return (
    <div>
      {!props.token && (
        <div className="landingNav">
          <img src={Logo} onClick={() => props.history.push("/")} />
          <div>
            <Link style={{ marginRight: "10px" }} to="/login">
              Login
            </Link>
            <Link to="/signup">Sign up</Link>
          </div>
        </div>
      )}
      {props.token && <Sidebar props={props} />}
      <div
        className={`view-wrapper ${props.toeken && `is-sidebar-v1`} ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        {props.token && <Header navigate={props.history} />}
        <div className={`container ${props.token && `sidebar-boxed`}`}>
          {!props.token && <h1>Appeals Form</h1>}
          <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
            If you have been depicted in any content and would like to appeal
            removal of such content, please notify us by sending an email here
          </h1>
          <Formik
            initialValues={{
              name: "",
              email: "",
              url: "",
              complain: "",
            }}
            validationSchema={complaint}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              complaintHandler(values, resetForm, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <div className="header-coupon">
                  <div className="field field-group is-autocomplete">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input is-fade"
                      placeholder="Name"
                    />
                    {errors.name && touched.name && errors.name ? (
                      <div className="dangerText">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="field field-group is-autocomplete">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input is-fade"
                      placeholder="Email"
                    />
                    {errors.email && touched.email && errors.email ? (
                      <div className="dangerText">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="field field-group is-autocomplete">
                    <label>url</label>
                    <input
                      type="text"
                      name="url"
                      id="url"
                      value={values.url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input is-fade"
                      placeholder="Url"
                    />
                    {errors.url && touched.url && errors.url ? (
                      <div className="dangerText">{errors.url}</div>
                    ) : null}
                  </div>
                  <div className="field field-group is-autocomplete">
                    <label>complain</label>
                    <textarea
                      type="text"
                      name="complain"
                      id="complain"
                      value={values.complain}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input is-fade"
                      placeholder="Comaplaint"
                    />
                  </div>
                  <div className="column is-12">
                    <div
                      className="buttons"
                      style={{ justifyContent: "center" }}
                    >
                      <button
                        className="button is-solid accent-button form-button"
                        type="submit"
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            size="1rem"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <p>
                  We are doing our best so you will enjoy the content provided.
                  If, in any case, you have a complaint, please let us know. All
                  complaints will be reviewed and resolved within seven business
                  days. Any content that is deemed inappropriate or illegal will
                  be removed from our platform. Creators who post illegal
                  content or violate our terms of service will also receive a
                  ban.
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <a
                    href="https://community.tribeofbabes.com/terms_and_condition"
                    target="_blank"
                  >
                    Terms And Condition
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    href="https://community.tribeofbabes.com/privacy_policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {props.token ? (
          <Footer />
        ) : (
          <footer className="footer">
            <div className="landingContainer">
              <h3>
                Cancellation Policy: If you would like to cancel your purchase.
                Please contact CCBILL CONSUMER SUPPORT DEPARTMENT at{" "}
                <a href={"https://support.ccbill.com"} target="_blank">
                  https://support.ccbill.com
                </a>
              </h3>
              <div className="footerColumns" style={{ marginTop: "2rem" }}>
                <div>
                  <ul>
                    <li>Cinema Studio, LLC | support@tribeofbabes.com</li>
                    <li>5505 E Santa Ana Canyon Road # 17722</li>
                    <li>Anaheim, CA 92817 US</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>
                      if you encounter any issue about payment or cancellation
                      of recurring services
                    </li>
                    <li>
                      Consumer Support :: support@ccbill.com | 888.596.92.79 |
                      https://support.ccbill.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="landingContainer" style={{ marginTop: "4rem" }}>
              <div className="footerColumns">
                <div>
                  <h3>
                    © {moment().format("YYYY")} Tribe Of Babes. All Right
                    Reserved.
                  </h3>
                </div>

                <div>
                  <Link to="/privacy_policy">Privacy Policy</Link>&nbsp;
                  <Link to="/terms_and_condition">|| Terms & Conditions</Link>
                  &nbsp;
                  <Link to="/contact_us">|| Contact Us</Link>&nbsp;
                  <Link to="/appeals_form">|| Appeals Form</Link>&nbsp;
                </div>
              </div>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    token: state.StreamApp.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    userHandler: (value) => dispatch({ type: "USER", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppealsForm);
