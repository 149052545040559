import React from "react";
import { Bell, Moon, Sun, Settings, LifeBuoy, BarChart2 } from "react-feather";
import { connect } from "react-redux";
import axios from "axios";
import Pusher from "pusher-js";
import toast from "react-hot-toast";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import LayoutHeader from "./Layout/Header";
import Breadcrumbs from "../Elements/Breadcrumbs";

const Header = (props) => {
  const [menu, setMenu] = React.useState(false);
  const [, setMessages] = React.useState(false);
  const [noti, setNoti] = React.useState(false);
  const [likes, setLikes] = React.useState(false);
  const [dark, setDark] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const darkModeHandler = () => {
    if (dark) {
      var body = document.body;
      body.classList.remove("is-dark");
      setDark(!dark);
      props.darkHandler(false);
    } else {
      var body = document.body;
      body.classList.add("is-dark");
      setDark(!dark);
      props.darkHandler(true);
    }
  };

  const menuHandler = () => {
    setMenu(!menu);
    setMessages(false);
    setNoti(false);
    setLikes(false);
  };

  const NotiHandler = () => {
    notificationhandler();
    setNoti(!noti);
    setMessages(false);
    setMenu(false);
    setLikes(false);
  };

  const sidebarHandler = () => {
    props.sidebarHandler(!props.sidebar);
  };

  const userProfile = async () => {
    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        props.userHandler(response.data.response.data.auth);
        props.updateHandler(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (props.update) {
      userProfile();
    }
  }, [props.update]);

  React.useEffect(() => {
    if (props.dark) {
      var body = document.body;
      body.classList.add("is-dark");
    }
  }, [props.dark]);

  const notificationhandler = () => {
    setIsLoading(false);
    var axios = require("axios");

    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/notifications",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setNotifications(response.data.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    const pusher = new Pusher("3817133e9c63874ce1bc", {
      cluster: "mt1",
    });

    const likeChannel = pusher.subscribe("post_like");

    likeChannel.bind("like-event", function (data) {
      if (data.username.user_id === props.user?.id) {
        toast(() => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{data.username.username}</span>
            <span>{moment(data.username.time).fromNow()}</span>
          </div>
        ));
      }
    });

    const commentChannel = pusher.subscribe("comment");

    commentChannel.bind("comment-event", (data) => {
      if (data.username.user_id === props.user?.id) {
        toast(() => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{data.username.username}</span>
            <span>{moment(data.username.time).fromNow()}</span>
          </div>
        ));
      }
    });

    const tipChannel = pusher.subscribe("send_tip");
    tipChannel.bind("tip-event", (data) => {
      if (data.username.userId === props.user?.id) {
        toast(() => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{data.username.message}</span>
            <span>{moment(data.username.time).fromNow()}</span>
          </div>
        ));
      }
    });

    return () => {
      pusher.unsubscribe("post_like");
      pusher.unsubscribe("comment");
      pusher.unsubscribe("send_tip");
    };
  }, [props]);

  React.useEffect(() => {
    if (props.user) {
      if (props.user.isAgreedTerm === 0 && props.user.creatorType === "Model") {
        props.navigate.push("/consent");
      }
    }
  }, [props]);

  const breadCrums = React.useMemo(() => {
    const data = props.navigate.location.pathname.substring(0, 5);
    if (data === "/user") {
      return props.navigate.location.pathname.substring(6);
    } else if (props.postType) {
      return props.postType;
    } else {
      return props.navigate.location.pathname.substring(1);
    }
  }, [props.postType]);

  return (
    <>
      <LayoutHeader {...props} />
      <div className="container">
        <div className="tb-cabinet-header">
          <div className="tb-cabinet-header__left">
            {/* <div
            className="friendkit-hamburger sidebar-v1-trigger"
            onClick={() => sidebarHandler()}
          >
            <span className="menu-toggle has-chevron">
              <span
                className={`icon-box-toggle ${props.sidebar ? "active" : ""}`}
              >
                <span className="rotate">
                  <i className="icon-line-top" />
                  <i className="icon-line-center" />
                  <i className="icon-line-bottom" />
                </span>
              </span>
            </span>
          </div> */}
            <Breadcrumbs
              title={
                breadCrums === "Published"
                  ? "Post"
                  : breadCrums === "Appeals_form"
                  ? "Appeals Form"
                  : breadCrums
              }
            />
          </div>
          <div className="tb-cabinet-header__right controls">
            <div
              className="navbar-item is-icon drop-trigger"
              onClick={() => NotiHandler()}
            >
              <a className="icon-link">
                <Bell />
                <span className="indicator" />
              </a>
              <div className={`nav-drop is-right ${noti ? "is-active" : ""}`}>
                <div className="inner">
                  <div className="nav-drop-header">
                    <span>Notifications</span>
                    <a>
                      <Bell />
                    </a>
                  </div>
                  <div className="nav-drop-body is-notifications">
                    {isLoading ? (
                      <>
                        {notifications
                          .sort(function (a, b) {
                            return (
                              new Date(b.created_at) - new Date(a.created_at)
                            );
                          })
                          .splice(0, 10)
                          .map((i, k) => {
                            return (
                              <div className="media" key={k}>
                                <figure className="media-left">
                                  <p className="image">
                                    <img src={i.user.imageUrl} />
                                  </p>
                                </figure>
                                <div className="media-content">
                                  <span>
                                    <a>{i.message}</a>.
                                  </span>
                                  <span className="time">
                                    {moment(i.created_at).fromNow()}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <CircularProgress
                          size="30px"
                          style={{ color: "#ca1444" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              id="account-dropdown"
              className={`navbar-item is-account drop-trigger has-caret ${
                menu ? "is-opened" : ""
              } `}
              onClick={() => menuHandler()}
            >
              <div className="user-image">
                <img src={props.user?.image} />
                <span className="indicator" />
              </div>
              <div
                className={`nav-drop is-account-dropdown ${
                  menu ? "is-active" : ""
                }`}
              >
                <div className="inner">
                  <div className="nav-drop-header">
                    <span className="username">{props.user?.nickName}</span>
                    <label className="theme-toggle">
                      <input type="checkbox" defaultChecked={props.dark} />
                      <span
                        className="toggler"
                        onClick={() => darkModeHandler()}
                      >
                        <span className="dark">
                          <Moon />
                        </span>
                        <span className="light">
                          <Sun />
                        </span>
                      </span>
                    </label>
                  </div>
                  <div className="nav-drop-body account-items">
                    <a
                      id="profile-link"
                      onClick={() => props.navigate.push("/profile")}
                      className="account-item"
                    >
                      <div className="media">
                        <div className="media-left">
                          <div className="image">
                            <img src={props.user?.image} />
                          </div>
                        </div>
                        <div className="media-content">
                          <h3>{props.user?.nickName}</h3>
                          <small>Tokens: {props.user?.walletAmount}</small>
                        </div>
                        <div className="media-right">
                          <i data-feather="check" />
                        </div>
                      </div>
                    </a>
                    <hr className="account-divider" />
                    <a
                      onClick={() => props.navigate.push("/wallet")}
                      className="account-item"
                    >
                      <div className="media">
                        <div className="icon-wrap">
                          <Settings />
                        </div>
                        <div className="media-content">
                          <h3>Add Tokens</h3>
                          <small>You can buy tokens for your wallet</small>
                        </div>
                      </div>
                    </a>
                    <a
                      onClick={() => props.navigate.push("/transanctions")}
                      className="account-item"
                    >
                      <div className="media">
                        <div className="icon-wrap">
                          <BarChart2 />
                        </div>
                        <div className="media-content">
                          <h3>Transanctions</h3>
                          <small>View your all financial transanctions</small>
                        </div>
                      </div>
                    </a>
                    <a
                      className="account-item"
                      onClick={() => props.navigate.push("/help")}
                    >
                      <div className="media">
                        <div className="icon-wrap">
                          <LifeBuoy />
                        </div>
                        <div className="media-content">
                          <h3>Help</h3>
                          <small>Contact our support.</small>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    user: state.StreamApp.user,
    update: state.StreamApp.update,
    token: state.StreamApp.token,
    dark: state.StreamApp.dark,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
    updateHandler: (value) => dispatch({ type: "UPDATE", value: value }),
    userHandler: (value) => dispatch({ type: "USER", value: value }),
    darkHandler: (value) => dispatch({ type: "DARK", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
