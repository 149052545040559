import React from "react";
import { Search } from "react-feather";
import Logo from "../images/Logo.png";
import { connect } from "react-redux";
import Pubnub from "pubnub";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Chat = (props) => {
  const [sidebar, setSidebar] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [activeUser, setActiveUser] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [active, setActive] = React.useState(undefined);

  const pubnub = new Pubnub({
    publishKey: "pub-c-13f5ff65-2098-430e-abbb-491891f50057",
    subscribeKey: "sub-c-244b83d6-4171-11eb-ae10-b69578166507",
    uuid: 1,
  });

  const subToChannel = () => {
    setIsLoading(false);
    setMessages([]);
    const id = props.user.id + activeUser;
    var d = new Date();
    var n = d.getTime();
    pubnub
      .fetchMessages({
        channels: [`chat${id}`],
        end: n,
        count: 500,
      })
      .then((response) => {
        const test = Object.values(response.channels);
        test.map((item) => {
          return item.map((messgae) => {
            return setMessages((messages) => [...messages, messgae.message]);
          });
        });
        setIsLoading(true);
      });
  };

  const publishSampleMessage = React.useCallback(() => {
    const id = props.user.id + activeUser;
    if (message) {
      var publishPayload = {
        channel: `chat${id}`,
        message: {
          title: props.user.nickName,
          image: props.user.image,
          description: message,
          Time: moment().format("MMMM Do YYYY, h:mm:ss"),
          sentTime: moment().format("h:mm"),
        },
      };
      pubnub.publish(publishPayload);
      setMessage("");
    }
  }, [activeUser, setMessage, moment, props.user, pubnub]);

  const getUsers = () => {
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();

    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/subscriptions/users",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.response.data.length) {
          const data = response.data.response.data.map((i) => i.user);
          setUsers(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getFollowing = () => {
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();

    var config = {
      method: "get",
      url: "https://admin-staging.tribeofbabes.com/api/v1/subscribers/users",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.response.data.length) {
          const data = response.data.response.data.map((i) => i.subscriber);
          setUsers(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  React.useEffect(() => {
    if (props.token) {
      getUsers();
      getFollowing();
    }
  }, [props.token]);

  React.useMemo(() => {
    if (activeUser) {
      subToChannel();
    }
  }, [activeUser]);

  React.useEffect(() => {
    const id = props.user?.id + activeUser;
    let newData = undefined;
    pubnub.subscribe({
      channels: [`chat${id}`],
    });
    pubnub.addListener({
      message: (msg) => {
        newData = {
          title: msg.message.title,
          image: msg.message.image,
          description: msg.message.description,
          Time: msg.message.Time,
          sentTime: msg.message.sentTime,
        };
        setMessages((data) => [...data, newData]);
      },
    });

    return () => {
      pubnub.unsubscribeAll();
    };
  }, [activeUser]);

  const data = React.useMemo(() => {
    return messages
      .sort(function (a, b) {
        return new Date(b.Time) - new Date(a.Time);
      })
      .map((msg, msgKey) => {
        return (
          <div
            className={`chat-message ${
              msg.title === props.user.nickName ? "is-sent" : "is-received"
            }`}
            key={msgKey}
          >
            <img src={msg.image} />
            <div className="message-block">
              <div className="message-text">
                {msg.description}
                <br />
                <i>
                  -{" "}
                  <small>
                    {msg.title} / {msg.sentTime}
                  </small>
                </i>
              </div>
            </div>
          </div>
        );
      });
  }, [messages]);

  return (
    <>
      <Sidebar props={props} />
      <div
        className={`view-wrapper is-sidebar-v1 ${
          props.sidebar ? "is-fold" : ""
        }`}
      >
        <Header navigate={props.history} />
        <div className="chat-wrapper container is-active">
          <div className="chat-inner">
            {/* Chat top navigation */}
            <div className="chat-nav">
              <div className="nav-start">
                <div className="recipient-block">
                  <div className="avatar-container">
                    <img
                      className="user-avatar"
                      src={active ? active.imageUrl : props.user?.image}
                    />
                  </div>
                  <div className="username">
                    <span style={{ textTransform: "capitalize" }}>
                      {active ? active.nickName : props.user?.nickName}
                    </span>
                  </div>
                </div>
              </div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  props.user?.creatorType === "Model"
                    ? props.history.push("/profile")
                    : props.history.push("/home")
                }
              >
                Go back
              </span>
            </div>
            {/* Chat sidebar */}
            <div id="chat-sidebar" className="users-sidebar">
              {/* Header */}
              <div className="header-item">
                <div className="chat-user-avatar" />
              </div>
              {/* User list */}
              <div className="conversations-list has-slimscroll-xs">
                <div style={{ marginTop: "32px" }}>Creators</div>
                {/* User */}
                {users.map((i, k) => {
                  return (
                    <div
                      className={`user-item ${
                        i.id === activeUser ? "is-active" : ""
                      }`}
                      key={k}
                      onClick={() => {
                        setActiveUser(i.id);
                        setActive(i);
                      }}
                    >
                      <div className="avatar-container">
                        <img
                          className="user-avatar"
                          src={i.imageUrl}
                          alt={i.nickName}
                        />
                        <div className="user-status is-online" />
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* Add Conversation */}
              <div className="footer-item">
                <div
                  className="add-button modal-trigger"
                  onClick={() => props.history.push("/search")}
                >
                  <Search />
                </div>
              </div>
            </div>
            {/* Chat body */}
            <div
              id="chat-body"
              className={`chat-body ${sidebar ? "is-opened" : ""}`}
            >
              {/* Conversation with Dan */}
              {isLoading ? (
                <div
                  id="dan-conversation"
                  className="chat-body-inner has-slimscroll"
                >
                  {data}
                </div>
              ) : (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <CircularProgress size="30px" style={{ color: "#ca1444" }} />
                </div>
              )}
              {activeUser !== undefined ? (
                <input
                  className="input is-fade chat-input"
                  placeholder="Enter Message...."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) =>
                    e.key === "Enter" ? publishSampleMessage() : null
                  }
                />
              ) : (
                <React.Fragment>
                  <h4 style={{ textAlign: "center" }}>
                    Please select user from the left pannel to start chat. Or
                    click on search icon to seatch creators.
                  </h4>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.StreamApp.user,
    token: state.StreamApp.token,
    sidebar: state.StreamApp.sidebar,
  };
};
export default connect(mapStateToProps)(Chat);
