import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import { Heart, ThumbsUp, Link2, DollarSign, X } from "react-feather";
import axios from "axios";
import toast from "react-hot-toast";
import PubNub from "pubnub";
import AgoraRTC from "agora-rtc-sdk";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Footer from "./Footer";

const Tip = yup.object().shape({
  tip: yup.number().required(),
});
var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  params: {},
};
var option = {
  appID: "6dcf0eff9b4a4bb09a963c2efcb55311",
  channel: "",
  uid: null,
};
class WatchStreams extends React.Component {
  initClient = () => {
    rtc.client.init(
      option.appID,
      function () {
        // console.log("init success");
      },
      (err) => {
        // console.error(err);
      }
    );
    this.subscribeToClient();
  };

  state = {
    post: {},
    action: "chat",
    messages: [],
    open: false,
    message: "",
    liked: false,
    leaderBoard: [],
    pubnub: new PubNub({
      publishKey: "pub-c-13f5ff65-2098-430e-abbb-491891f50057",
      subscribeKey: "sub-c-244b83d6-4171-11eb-ae10-b69578166507",
      uuid: this.props.user?.id,
    }),
    remoteStreams: [],
    tipAmount: undefined,
    tip: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.getPost();
    }, 1000);

    this.state.pubnub.addListener({
      status: (statusEvent) => {
        if (statusEvent.category === "PNConnectedCategory") {
          this.publishSampleMessage();
        }
      },
      message: (msg) => {
        var newelement = {};
        newelement = {
          title: msg.message.title,
          image: msg.message.image,
          description: msg.message.description,
          Time: msg.message.Time,
          amount: msg.message.amount,
        };

        this.setState({
          messages: [...this.state.messages, newelement],
        });
        const data = this.state.messages.filter((filter) => {
          return filter.amount;
        });
        const res = Array.from(
          data.reduce((a, { title, amount }) => {
            return a.set(title, (a.get(title) || 0) + Number(amount));
          }, new Map())
        ).map(([title, amount]) => ({ title, amount }));
        this.setState({ leaderBoard: res });
      },
      presence: function (presenceEvent) {
        console.log("presence event: " + JSON.stringify(presenceEvent));
      },
    });
  }

  getPost = async () => {
    var config = {
      method: "get",
      url: `https://admin-staging.tribeofbabes.com/api/v1/user/posts/${this.props.match?.params?.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
    };
    const response = await axios(config);
    this.setState({ post: response.data.response.data });
    this.streamHandler(response.data.response.data);
    this.state.pubnub.subscribe({
      channels: [`Live${response.data.response.data.username}`],
    });
  };

  likeHandler = async () => {
    try {
      var data = new FormData();
      data.append("postId", this.state.post?.id);
      data.append("type", "post");

      var config = {
        method: "post",
        url: "https://admin-staging.tribeofbabes.com/api/v1/user/likes/posts",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
        data: data,
      };

      const response = await axios(config);
      toast.success(response.data.response.message);
      this.setState({ liked: true });
    } catch (error) {
      toast.error(error.response.data.response.message);
    }
  };

  subToChannel = () => {
    this.state.subscribe({
      channels: [`${this.state.post?.username}`],
    });
  };

  subscribeToClient = () => {
    let me = this;
    rtc.client.on("stream-added", function (evt) {
      var remoteStream = evt.stream;
      var id = remoteStream.getId();
      // console.log("New stream added: " + remoteStream.getId());
      me.setState(
        {
          remoteStreams: {
            ...me.state.remoteStream,
            [remoteStream.getId()]: remoteStream,
          },
        },
        () => {
          // Subscribe after new remoteStreams state set to make sure
          // new stream dom el has been rendered for agora.io sdk to pick up
          rtc.client.subscribe(remoteStream, function (err) {
            // console.log("Subscribe stream failed", err);
          });
        }
      );
      console.log("stream-added remote-uid: ", id);
    });
    rtc.client.on("stream-subscribed", (evt) => {
      let remoteStream = evt.stream;
      remoteStream.play("agora_remote");
    });

    rtc.client.on("stream-removed", function (evt) {
      let me = this;
      let stream = evt.stream;
      if (stream) {
        let streamId = stream.getId();
        let { remoteStreams } = me.state;

        stream.stop();
        delete remoteStreams[streamId];

        me.setState({ remoteStreams });

        // console.log("Remote stream is removed " + stream.getId());
      }
    });
    rtc.client.on("peer-leave", function (evt) {
      let me = this;
      let stream = evt.stream;
      if (stream) {
        let streamId = stream.getId();
        let { remoteStreams } = me;

        stream.stop();
        delete remoteStreams[streamId];

        me.setState({ remoteStreams });

        // console.log(evt.uid + " leaved from this channel");
      }
    });
  };

  removed = () => {
    // let me = this;
    rtc.client.on("peer-leave", function (evt) {
      let me = this;
      let stream = evt.stream;
      if (stream) {
        let streamId = stream.getId();
        let { remoteStreams } = me.state;

        stream.stop();
        delete remoteStreams[streamId];

        me.setState({ remoteStreams });

        // console.log(evt.uid + " leaved from this channel");
      }
    });
  };

  Stop = () => {
    let me = this;
    rtc.client.leave(
      function () {
        // Stop playing the local stream
        rtc.localStream.stop();
        // Close the local stream
        rtc.localStream.close();
        // Stop playing the remote streams and remove the views
        while (me.state.remoteStreams.length > 0) {
          var stream = me.state.remoteStreams.shift();
          var id = stream.getId();
          stream.stop();
        }
        // console.log("client leaves channel success");
      },
      function (err) {
        // console.log("channel leave failed");
        // console.error(err);
      }
    );
  };

  streamHandler = (data) => {
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
    this.initClient();
    rtc.client.setClientRole("audience");
    rtc.client.join(
      data.liveStreamerToken ? data.liveStreamerToken : null,
      data.username,
      option.uid ? +option.uid : null,
      (uid) => {
        this.setState({
          channelName: data.username,
          channelID: uid,
        });
        rtc.params.uid = uid;
      }
    );
  };

  publishSampleMessage = () => {
    if (this.state.message) {
      var publishPayload = {
        channel: `Live${this.state.post.username}`,
        message: {
          image: this.props.user.image,
          title: this.props.user.userName,
          description: this.state.message,
          Time: moment().format("h:mm"),
        },
      };
      this.state.pubnub
        .publish(publishPayload)
        .then((response) => console.log(response));
      this.setState({ message: "" });
    }
  };

  tipHandler = () => {
    var data = new FormData();
    data.append("postId", this.state.post.id);
    data.append("amount", this.state.tipAmount);
    var config = {
      method: "post",
      url: "https://admin-staging.tribeofbabes.com/api/v1/user/tip/post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.success(response.data.response.message);
        var publishPayload = {
          channel: `Live${this.state.post.username}`,
          message: {
            title: this.props.user.userName,
            image: this.props.user.image,
            description: `${this.props.user?.nickName} Tipped $${this.state.tipAmount}`,
            amount: this.state.tipAmount,
            Time: moment().format("h:mm"),
          },
        };
        this.state.pubnub.publish(publishPayload).then(() => {
          this.setState({ tip: false, tipAmount: "", open: false });
        });
      })
      .catch((error) => {
        toast.success(error.response.data.response.message);
      });
  };

  leaveHandler = () => {
    this.Stop();
    this.props.history.push("/home");
  };
  render() {
    return (
      <>
        <Sidebar />
        <div
          className={`view-wrapper is-sidebar-v1 ${
            this.props.sidebar ? "is-fold" : ""
          }`}
        >
          <Header navigate={this.props.history} />
          <div className="container sidebar-boxed">
            <div className="videos-modal">
              <div className="card-body">
                <div className="inner">
                  <div className="left-section">
                    <div className="video-wrapper">
                      <div className="video-wrap">
                        {Object.keys(this.state.remoteStreams).map((key) => {
                          let stream = this.state.remoteStreams[key];
                          let streamId = stream.getId();
                          return (
                            <div
                              key={streamId}
                              id="agora_remote"
                              className="start"
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="right-section">
                    <div className="header">
                      <img src={this.state.post?.user_profile} />
                      <div className="user-meta">
                        <span>
                          {this.state.post?.nickName} <small>is live</small>
                        </span>
                        <span>
                          <small>right now</small>
                        </span>
                      </div>
                      <button
                        type="button"
                        className="button is-solid primary-button raised is-rounded"
                        onClick={() => this.leaveHandler()}
                      >
                        Leave
                      </button>
                    </div>
                    <div className="inner-content">
                      <div className="live-stats">
                        <div className="social-count">
                          <div className="likes-count">
                            <Heart />
                            <span>{this.state.post?.likesCount}</span>
                          </div>
                        </div>
                      </div>
                      <div className="actions">
                        <div
                          className="action"
                          onClick={() => this.likeHandler()}
                        >
                          <ThumbsUp
                            style={{
                              stroke: this.state.liked ? "#ca1444" : "",
                            }}
                          />
                          <span>Like</span>
                        </div>
                        <div
                          className="action"
                          onClick={() => this.setState({ open: true })}
                        >
                          <DollarSign />
                          <span>Tip</span>
                        </div>
                        <div className="action">
                          <CopyToClipboard
                            text={`https://community.tribeofbabes.com/post/${this.state.post.urlCode}`}
                            onCopy={() => toast.success("Link Copied")}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Link2 style={{ margin: "5px" }} /> Copy Link
                            </span>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                    <div className="tabs-wrapper">
                      <div className="tabs is-fullwidth">
                        <ul>
                          <li
                            className={
                              this.state.action === "chat" ? "is-active" : ""
                            }
                            onClick={() => this.setState({ action: "chat" })}
                          >
                            <a>Chat</a>
                          </li>
                          <li
                            className={
                              this.state.action === "leaderboard"
                                ? "is-active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ action: "leaderboard" })
                            }
                          >
                            <a>Leaderboard</a>
                          </li>
                        </ul>
                      </div>
                      {this.state.action === "chat" ? (
                        <div className="tab-content has-slimscroll">
                          {this.state.messages
                            .filter((filter) => filter.Tip === undefined)
                            .map((i, k) => {
                              return (
                                <div className="media is-comment" key={k}>
                                  <figure className="media-left">
                                    <p className="image is-32x32">
                                      <img src={i.image} />
                                    </p>
                                  </figure>
                                  <div className="media-content">
                                    <div className="username">{i.title}</div>
                                    <p>{i.description}</p>
                                    <div className="comment-actions">
                                      <span style={{ margin: "0px" }}>
                                        {i.Time}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        <div className="tab-content has-slimscroll">
                          {this.state.leaderBoard.map((i, k) => {
                            console.log(i);
                            return (
                              <div className="media is-comment" key={k}>
                                <div className="media-content">
                                  <div className="username">{i.title}</div>
                                  <p>Tokens {i.amount}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="comment-controls">
                      <div className="controls-inner">
                        <img src={this.props.user?.image} />
                        <div className="control">
                          <input
                            className="textarea comment-textarea is-rounded"
                            rows={1}
                            placeholder="write your message..."
                            defaultValue={this.state.message}
                            onKeyUp={(e) =>
                              e.keyCode === 13 && this.publishSampleMessage()
                            }
                            onChange={(e) =>
                              this.setState({
                                message: e.target.value,
                              })
                            }
                            value={this.state.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div
          className={`modal is-xsmall has-light-bg share-modal ${
            this.state.open ? "is-active" : ""
          }`}
        >
          <div className="modal-background">
            <div className="modal-content tip-modal">
              <div className="card">
                <div className="card-heading">
                  <h3>Tip</h3>
                  <div className="close-wrap">
                    <span
                      className="close-modal"
                      onClick={() => {
                        this.setState({
                          tip: false,
                          tipAmount: undefined,
                          open: false,
                        });
                      }}
                    >
                      <X />
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <React.Fragment>
                    {!this.state.tip ? (
                      <Formik
                        initialValues={{ tip: undefined }}
                        validationSchema={Tip}
                        onSubmit={(values, { resetForm }) => {
                          resetForm();
                          this.setState({ tip: true, tipAmount: values.tip });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                        }) => (
                          <Form>
                            <div className="field">
                              <div className="control">
                                <input
                                  className="input"
                                  type="number"
                                  placeholder="10"
                                  name="tip"
                                  id="tip"
                                  value={values.tip}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.tip && touched.tip && errors.tip ? (
                                  <div className="dangerText">{errors.tip}</div>
                                ) : null}
                              </div>
                            </div>
                            <button
                              className="button is-solid primary-button raised is-rounded is-fullwidth"
                              disabled={this.state.loading}
                              type="submit"
                            >
                              {this.state.loading ? (
                                <CircularProgress
                                  size="1rem"
                                  style={{ color: "#fff" }}
                                />
                              ) : (
                                "Confirm"
                              )}
                            </button>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <h5 style={{ marginBottom: "10px" }}>
                          Are You Sure To Tip {`${this.state.tipAmount}`} Token
                        </h5>
                        <button
                          className="button is-solid primary-button raised is-rounded"
                          disabled={this.state.loading}
                          style={{ marginRight: "10px" }}
                          onClick={() => this.tipHandler()}
                        >
                          {this.state.loading ? (
                            <CircularProgress
                              size="1rem"
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            "Confirm"
                          )}
                        </button>
                        <button
                          className="button is-solid primary-button raised is-rounded"
                          onClick={() => {
                            this.setState({
                              tip: false,
                              tipAmount: "",
                              open: false,
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    sidebar: state.StreamApp.sidebar,
    token: state.StreamApp.token,
    user: state.StreamApp.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sidebarHandler: (value) => dispatch({ type: "SIDEBAR", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WatchStreams);
