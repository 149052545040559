import React from "react";
import cx from "classnames";

const BigInput = ({ className, error, description, ...props }) => {
  return (
    <div className="tb-big-input">
      <input className={cx("tb-big-input__input", className)} {...props} />
      {description && (
        <div
          className={cx("tb-big-input__description", {
            "tb-big-input__description--error": error,
          })}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default BigInput;
