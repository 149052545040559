import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Elements/Button";

const TopBanner = () => (
  <section className="tb-landing-top-banner">
    <div className="container tb-landing-top-banner__wrapper">
      <div className="tb-landing-top-banner__left">
        <div className="tb-landing-top-banner__title">
          Create your
          <br />
          stage & own it
        </div>
        <div className="tb-landing-top-banner__subtitle">
          The creator driven adult site that helps content creators earn more
          and makes sure you stay in control.
        </div>
        <div className="tb-landing-top-banner__buttons">
          <Link to="/login">
            <Button color="dark">Join the Tribe</Button>
          </Link>
        </div>
      </div>
      <div className="tb-landing-top-banner__right" />
    </div>
  </section>
);

export default TopBanner;
